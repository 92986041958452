export enum PointType {
  PT01 = '[적립] 치료후기',
  PT02 = '[적립] 재방문 치료후기',
}

export enum PointAmount {
  PT01 = 30,
  PT02 = 10,
}

export type PointTypeStrings = keyof typeof PointType;
export type PointAmountStrings = keyof typeof PointAmount;

export interface IReqUser {
  id: string;
}

interface UserPoint {
  id: string;
  pointAmount: number | string;
  updatedAt: Date | null;
}

export interface IUser {
  id: string;
  name: string;
  phonenum: string;
  email: string;
  point: UserPoint;
}

export interface IResUser {
  user: IUser;
}

export enum RewardType {
  REWARD_SIGNUP = '신규가입',
  REWARD_SIGNUP_REFERRAL_FROM = '친구초대',
  REWARD_INVITE_RANK = '공유랭킹보상',
  REWARD_QUIZ = '오감퀴즈',
  REWARD_HOSPITAL_REVIEW = '치료후기',
  REWARD_REVISIT_REVIEW = '재방문 치료후기',
}

export type RewardTypeString = keyof typeof RewardType;

export interface RewardItem {
  id: string;
  itemCode: RewardTypeString;
  itemDescription?: string;
  itemName: string;
  itemPoint: number;
}

export interface IPointItems {
  userPointRewardItems: RewardItem[];
}
