import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Input, Select, Button, Modal, Switch, Form, Typography } from 'antd';
import InputFileMultiple from 'components/Input/InputFileMultiple';
import { useMutation } from '@apollo/client';

import { DeActivateUser, GetUsers } from 'graphql/user.query';
import useFilterModel from 'hooks/useFilterModel';
import useUser from 'hooks/userUser';
import usePostJujak from 'hooks/usePostJujak';

import { RootState } from 'modules/rootState';
import { actionCreateFakePost } from 'modules/post/post.actions';
import usePostCatManage from 'hooks/usePostCat';

export default function WritePostComponent() {
  const { TextArea } = Input;
  const { confirm } = Modal;
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { getPostCatList, list: catList } = usePostCatManage();

  useEffect(() => {
    getPostCatList({
      variables: {
        pagination: {
          perPage: 30,
          currentPage: 1,
        },
      },
    });
  }, [getPostCatList]);

  const [fakeUserIndex, setFUIndex] = useState<number>(1);
  const [postImages, setPostImages] = useState<any>([]);
  const [post, setPost] = useState<any>({
    title: '',
    content: '',
    categoryId: 0,
    userId: '',
    tags: '',
    images: [],
    isBest: false,
    isPickShow: false,
  });

  const { getUsers, list, pagination } = useUser();
  const { getFakeUser, createFakePost, createFakePostComment, createFakePostLike } = usePostJujak(
    () => {
      alert('완료');
      form.resetFields();
      setPostImages([]);
    }
  );

  const fakePost = useSelector((state: RootState) => state.fakePost);

  const InputFile = ({ state, setState, index }) => (
    <InputFileMultiple index={index} eventPosters={state} setEventPosters={setState} />
  );

  function changeDataByKey(key: any, value: any) {
    setPost({
      ...post,
      [key]: value,
    });
  }

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const [deActivateUser] = useMutation(DeActivateUser, {
    refetchQueries: [
      {
        query: GetUsers,
        variables: {
          pagination: {
            currentPage: pagination.currentPage,
          },
        },
      },
    ],
  });

  const onFinish = ({ tags, ...values }: any) => {
    console.log('Received values of form: ', values);

    const post = {
      ...values,
      ...(tags && {
        tags: tags
          .replace(/,*\s*$/, '')
          .split(',')
          .map((s: string) => s.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim, ''))
          .join(),
      }),
    };

    confirm({
      title: '게시글을 생성하겠습니까?',
      onOk() {
        dispatch(actionCreateFakePost({ callback: createFakePost, post, postImages }));
      },
    });
  };

  return (
    <div>
      <Title level={3}>커뮤니티 관리하기</Title>
      <Form
        onFinish={onFinish}
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        //  disabled={componentDisabled}
        style={{ maxWidth: 600 }}
        fields={[{ name: ['userId'], value: fakePost.fakeUser?.id }]}
      >
        <Form.Item label="유저ID" name="userId" rules={[{ required: true }]}>
          <Input placeholder="1" style={{ width: '40rem' }} />
        </Form.Item>

        <Form.Item label="제목" name="title" rules={[{ required: true }]}>
          <Input placeholder="태훈이의 금연 일지 1일차" style={{ width: '40rem' }} />
        </Form.Item>

        <Form.Item label="내용" name="content" rules={[{ required: true }]}>
          <TextArea
            placeholder="실패"
            autoSize={{ minRows: 3, maxRows: 5 }}
            style={{ width: '40rem' }}
          />
        </Form.Item>

        <Form.Item label="카테고리" name="categoryId" rules={[{ required: true }]}>
          <Select<string>
            // mode="multiple"
            placeholder="최소 1개 이상 선택, 카테고리명으로 검색"
            filterOption
            optionFilterProp="children"
            allowClear
            style={{ width: '40rem' }}
          >
            {catList
              .filter(({ isDelete, isVisible }) => !isDelete && isVisible)
              .map(({ title, id }, idx) => {
                return (
                  <Select.Option value={id} key={idx}>
                    {title} ({id})
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item label="첨부이미지">
          <InputWrapper>
            <InputFile index={0} state={postImages} setState={setPostImages} />
          </InputWrapper>
          <Warn>gif:5mb 그외 1mb</Warn>
        </Form.Item>

        <Form.Item label="태그" name="tags" tooltip="Comma (,) 로 구분">
          <Input
            placeholder="점화,점멸,총명,강타"
            onChange={({ target: { value } }) => changeDataByKey('tags', value.replace(/\s*$/, ''))}
            style={{ width: '40rem' }}
          />
        </Form.Item>
        {post.tags !== '' && (
          <PreviewTags>
            {post.tags
              .replace(/,*\s*$/, '')
              .split(',')
              .map((s: string) => s.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim, ''))
              .map((t: string, i: number) => (
                <div key={i}>{t}</div>
              ))}
          </PreviewTags>
        )}

        <Form.Item label="베스트 지정" name="isBest">
          <Switch checkedChildren="베스트" unCheckedChildren="미지정" />
        </Form.Item>

        <Form.Item label="픽상세 노출" name="isPickShow">
          <Switch checkedChildren="노출" unCheckedChildren="미노출" />
        </Form.Item>

        <Button type="primary" style={{ width: '20rem' }} htmlType="submit">
          {/* <Button type="primary" onClick={onSubmit} style={{ width: '20rem' }}> */}글 쓰기
        </Button>
      </Form>
    </div>
  );
}

const PreviewTags = styled.div`
  font-size: 1rem;
  margin-top: 1rem;
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column dense;
  justify-content: start;
  & > div {
    background-color: rgb(225, 234, 104);
    padding: 3px 5px;
    border-radius: 3px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Warn = styled.p`
  margin: 0;
  color: #ff8080;
`;

export const DiscountRate = styled.span`
  font-size: 2.4rem;
  line-height: 0.83;
  color: #dd4124;
  font-weight: 600;
`;

export const Price = styled.span<{ single?: boolean }>`
  font-size: 2.4rem;
  color: #dd4326;
  font-weight: 600;
  margin-left: ${({ single }) => !single && '0.6rem'};
`;

export const Origin = styled.span`
  font-size: 1.8rem;
  color: #b1b3b2;
  line-height: 1.11;
  margin-left: 0.6rem;
  text-decoration: line-through;
`;

export const CustomRow = styled.div`
  margin-bottom: 1rem;
`;

export const DnDWrapper = styled.div`
  padding: 1rem;
`;
