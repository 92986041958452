import { MutationFunctionOptions } from '@apollo/client';
import { createAction, ActionType } from 'typesafe-actions';
import { PostState, ReqCreateFakePost, ResCreateFakePost } from './post.models';

export const SET_FAKE_USER = 'SET_FAKE_USER';
export const actionSetFakeUser = createAction(SET_FAKE_USER)<PostState>();

export const SET_POST = 'SET_POST';
export const actionSetPost = createAction(SET_POST)<PostState>();

export const SET_POST_COMMENT = 'SET_POST_COMMENT';
export const actionSetPostComment = createAction(SET_POST_COMMENT)<PostState>();

export const REQUEST_CREATE_FAKE_POST = 'REQUEST_CREATE_FAKE_POST';
export const actionCreateFakePost = createAction(REQUEST_CREATE_FAKE_POST)<{
  callback: (
    options?: MutationFunctionOptions<ResCreateFakePost, ReqCreateFakePost> | undefined
  ) => void;
  post: any;
  postImages: any;
}>();

export const REQUEST_UPDATE_POST = 'REQUEST_UPDATE_POST';
export const actionUpdatePost = createAction(REQUEST_UPDATE_POST)<{
  callback: (options?: MutationFunctionOptions<any, any> | undefined) => void;
  post: any;
  postImages: any;
}>();

const actions = {
  actionSetFakeUser,
  actionSetPost,
  actionSetPostComment,
  actionCreateFakePost,
  actionUpdatePost,
};

export type PostActions = ActionType<typeof actions>;
