import React from 'react';
import styled from 'styled-components';

interface ILabelRow {
  children: React.ReactNode;
  label: string;
  isRequired?: boolean;
  icon?: React.ReactNode;
}

export default function LabelRow({ label, isRequired, children, icon }: ILabelRow) {
  return (
    <Row>
      <Left>
        {label}
        {icon}
        {isRequired && <Required>*</Required>}
      </Left>
      <Right>{children}</Right>
    </Row>
  );
}

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Required = styled.span`
  color: red;
  margin-right: 1rem;
  font-size: 2rem;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  width: 20rem;
  margin-right: 2rem;
  white-space: pre-line;
`;

const Right = styled.div`
  width: 100%;
`;
