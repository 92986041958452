import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Divider, Drawer, Empty, Input, Select } from 'antd';

import useInputPoint from 'hooks/useInputPoint';
import { RewardType } from './InputPointHistory.models';

const { Search } = Input;
const { Option } = Select;

interface IInputPointHistory {
  showInput: boolean;
  setShowInput: Dispatch<SetStateAction<boolean>>;
}

export default function InputPointHistory({ showInput, setShowInput }: IInputPointHistory) {
  const [inputPointHistoryState, setInputPointHistoryState] = useState<{
    isDisabled: boolean;
    selectedPoint: string | number;
  }>({
    isDisabled: false,
    selectedPoint: '',
  });
  const {
    getUser,
    resetState,
    handleUserId,
    onChangePointItem,
    createUserPointHistory,
    data,
    loading,
    pointHistory,
  } = useInputPoint();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleUserId(e.target.value);
    setInputPointHistoryState({ isDisabled: e.target.value === '', selectedPoint: '' });
  };

  const onGetUser = (id: string) => {
    getUser({ variables: { id } });
    setInputPointHistoryState({ isDisabled: false, selectedPoint: '' });
  };

  const onSubmit = () => {
    const [pointItem] = pointHistory.pointItems.filter((item) => item.selected);

    const payload = {
      userId: pointHistory.id,
      // FIXME: 지금버전은 적립만 가능
      eventType: 'reward' as const,
      itemId: pointItem.id,
    };

    console.log(payload);
    createUserPointHistory({
      variables: {
        input: payload,
      },
    });

    onClose();
  };

  const onClose = () => {
    setShowInput(false);
    setInputPointHistoryState({ isDisabled: true, selectedPoint: '' });
    resetState('unmount');
  };

  const { isDisabled, selectedPoint } = inputPointHistoryState;

  return (
    <Drawer
      title="포인트 내역 추가"
      placement="right"
      width={'40%'}
      onClose={onClose}
      visible={showInput}
    >
      <span>사용자 ID</span>
      <Search
        loading={loading}
        enterButton
        onChange={onChange}
        onKeyUp={(e) => e.key === 'Enter' && onGetUser(e.currentTarget.value)}
        onSearch={(id) => onGetUser(id)}
        min={1}
        type="number"
        value={pointHistory.id}
      />
      {pointHistory.isEmpty ? (
        <Empty style={{ margin: '2rem 0' }} />
      ) : (
        <div style={{ display: 'flex', margin: '1rem 0' }}>
          <div style={{ flex: 1, marginRight: '1rem' }}>
            <span>이름</span>
            <Input disabled value={pointHistory.name} />
          </div>
          <div style={{ flex: 1 }}>
            <span>포인트</span>
            <Input disabled value={pointHistory.point ? pointHistory.point.pointAmount : 0} />
          </div>
        </div>
      )}
      {!pointHistory.isEmpty && (
        <>
          <Divider plain>적립 / 차감</Divider>
          <div style={{ display: 'flex', marginBottom: '1rem' }}>
            <div style={{ flex: 1, marginRight: '1rem' }}>
              <Select
                disabled={pointHistory.name === ''}
                onChange={(value) => {
                  const [pointItem] = pointHistory.pointItems.filter(
                    (item) => item.itemCode === value
                  );

                  setInputPointHistoryState({
                    selectedPoint: pointItem.itemPoint,
                    isDisabled: false,
                  });

                  onChangePointItem(pointItem.id);
                }}
                style={{ width: '100%' }}
              >
                {Object.entries(RewardType).map(([key, value]) => (
                  <Option value={key} key={key}>
                    {value}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ flex: 1 }}>
              <Input disabled value={selectedPoint} />
            </div>
          </div>
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={!data || isDisabled || !Boolean(selectedPoint)}
          >
            적용
          </Button>
        </>
      )}
    </Drawer>
  );
}
