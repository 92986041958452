import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { SET_HOSPITAL_INFO, SET_HOSPITAL_BY_KEY, RESET_HOSPITAL_INFO } from './hospital.actions';
import { HospitalPayload } from './hospital.models';

function getRandomIntInclusive(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //최댓값도 포함, 최솟값도 포함
}

const initialState = {
  name: '',
  gunguId: 10,
  hiraCode: '',
  tel: '',
  email: '',
  categoryId: 0,
  isUseCrmService: false,
  meta: {
    cctv: {
      enable: 0,
      images: [
        {
          url: '',
          describe: '',
        },
      ],
    },
    preview: {
      enable: 0,
      youtube: '',
    },
    certi: {
      enable: 0,
      images: [
        {
          url: '',
          describe: '',
        },
      ],
    },
    hours: {
      enable: 0,
      text: '',
    },
    subway: {
      enable: 0,
      line: 0,
      text: '',
    },
  },
};

const hospitalReducer = createReducer<HospitalPayload, RootAction>(initialState, {
  [SET_HOSPITAL_INFO]: (state) => ({
    ...state,
  }),
  [RESET_HOSPITAL_INFO]: (state) => ({
    ...initialState,
  }),
  [SET_HOSPITAL_BY_KEY]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }),
});

export default hospitalReducer;
