import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import { routes, limitAccounts } from 'pages/routes';
import AppLayout from 'components/AppLayout';
import AuthHelper from 'utils/AuthHelper';
import { notification } from 'antd';

interface IPrivateRoute extends RouteProps {
  children: React.ReactNode;
}

const auth = false;
function PrivateRoute({ children, ...rest }: IPrivateRoute) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  if (!AuthHelper.isSignedIn() || AuthHelper.isExpired()) {
    notification.error({
      message: '👀',
      description: '미네랄이 부족합니다.',
      placement: 'bottomRight',
      duration: 5,
    });
    return <Redirect to="/login" />;
  }

  const { email } = AuthHelper.getUser();
  const [account] = email.split('@odoctor.co.kr');

  return (
    <AppLayout>
      <Switch>
        {limitAccounts.includes(account)
          ? routes.getLimitAccountRoutes().map(({ path, component, isPrivate }, idx) => {
              const Component = component as any;

              return (
                <Route path={path} key={idx} exact>
                  <Component />
                </Route>
              );
            })
          : routes.getRoutes().map(({ path, component, isPrivate }, idx) => {
              const Component = component as any;

              return isPrivate ? (
                <PrivateRoute path={path} key={idx} exact>
                  <Component />
                </PrivateRoute>
              ) : (
                <Route path={path} key={idx} exact>
                  <Component />
                </Route>
              );
            })}
      </Switch>
    </AppLayout>
  );
}

export default App;
