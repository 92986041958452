import gql from 'graphql-tag';

export const GetUsers = gql`
  query GetUsers($pagination: PaginationInput, $filter: UserListFilter) {
    users(pagination: $pagination, filter: $filter) {
      data {
        id
        name
        email
        isActive
        phonenum
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const DeActivateUser = gql`
  mutation DeActivateUser($id: ID!) {
    deactivateUser(id: $id) {
      ok
    }
  }
`;
