import { createAction, ActionType } from 'typesafe-actions';

interface IShowMemoModal {
  visible: boolean;
  memo?: string;
}

export const SHOW_MEMO_MODAL = 'TOGGLE_MEMO_MODAL';
export const showMemoModal = createAction(SHOW_MEMO_MODAL)<IShowMemoModal>();

const actions = { showMemoModal };

export type UIActions = ActionType<typeof actions>;
