import Axios, { AxiosResponse } from 'axios';
import { takeLatest, all, fork, select } from 'redux-saga/effects';
import { print } from 'graphql';

import { RootState } from 'modules/rootState';
import { IEventState, IResGetEvents, IResSignedUrl } from './event.models';
import {
  actionCreateEvent,
  CREATE_EVENT,
  actionGetEvents,
  REQUEST_UPDATE_EVENT,
  actionRequestUpdateEvent,
} from './event.actions';
import { GetEvents } from 'graphql/event.query';
import { getSignedUrl, removeKey, replaceEndPoint } from 'utils/createEventHelper';

const ODOCTOR_APP_URL = process.env.REACT_APP_ODOCTOR_APP_URL || '';

function* workerCreateEvent(action: ReturnType<typeof actionCreateEvent>) {
  const event: IEventState = yield select((state: RootState) => state.event);

  try {
    const { thumbnailImage, eventPosters, callback } = action.payload;
    const files = [thumbnailImage, ...eventPosters.map((eventPoster) => eventPoster.file)];
    const signedUrls = files.map((file, idx) => getSignedUrl(file, idx === 0));
    const response: AxiosResponse<{ data: IResSignedUrl }>[] = yield Promise.all(signedUrls);
    const s3Urls = response.map((res, idx) => {
      Axios.put(res.data.data.signS3.signedRequest, files[idx]);
      return res.data.data.signS3.url;
    });

    const removedObj = removeKey(event);

    yield callback({
      variables: {
        input: Object.assign({}, removedObj, {
          startDate: event.range[0],
          endDate: event.range[1],
          thumbnailUrl: replaceEndPoint(s3Urls[0]),
          contentImgUrls: s3Urls.slice(1).map((url) => replaceEndPoint(url)),
        }),
      },
    });
  } catch (error) {
    console.error('workerCreateEvent', error);
  }
}

function* workerRequestUpdateEvent(action: ReturnType<typeof actionRequestUpdateEvent>) {
  try {
    const { callback, event, eventPosters, setShowDetail, eventThumbnail } = action.payload;
    const files = [eventThumbnail, ...eventPosters]
      .filter((eventPoster) => eventPoster.file)
      .filter((n) => n)
      .map((eventPoster) => eventPoster.file);

    // signedUrl 에서 이미지 저장 폴더를 변경하기 위해 사용ß
    const signedUrls = files.map((file, idx) =>
      getSignedUrl(file, eventThumbnail.file ? idx === 0 : false)
    );
    const response: AxiosResponse<{ data: IResSignedUrl }>[] = yield Promise.all(signedUrls);
    const s3Urls = response.map((res, idx) => {
      Axios.put(res.data.data.signS3.signedRequest, files[idx]);
      return res.data.data.signS3.url;
    });

    const {
      id,
      name,
      therapyName,
      visible,
      priceDescription,
      originalPrice,
      discountRate,
      discountedPrice,
      chargePrice,
      hospitalBranchName,
      isDelete,
      useBlock,
      buttonType,
      description,
      description2,
      isEnableKakao,
      crmViewMode,
      eventTags,
    } = event;

    let idx = 0;
    let thumbnailS3Url: any = null;
    if (eventThumbnail.file) thumbnailS3Url = s3Urls.shift();
    const contentImgUrls = eventPosters.reduce<string[]>((acc, eventPoster) => {
      // 새로 추가한 이미지의 갯수는 s3Urls의 갯수와 동일하다
      if (eventPoster.file) {
        acc.push(s3Urls[idx]);
        idx++;
      } else {
        acc.push(eventPoster.preview as string);
      }

      return acc;
    }, []);

    const input = Object.assign(
      {
        id,
        name,
        therapyName,
        visible,
        chargePrice,
        hospitalBranchName,
        contentImgUrls: contentImgUrls.map((url) => replaceEndPoint(url)),
        ...(thumbnailS3Url && { thumbnailUrl: thumbnailS3Url }),
        isDelete,
        useBlock,
        buttonType,
        description,
        description2,
        isEnableKakao,
        crmViewMode,
        eventTags,
      },
      priceDescription && { priceDescription },
      { originalPrice: originalPrice === 0 ? null : originalPrice },
      { discountRate: discountRate === 0 ? null : discountRate },
      { discountedPrice: discountedPrice === 0 ? null : discountedPrice }
    );

    yield callback({ variables: { input } });
    yield setShowDetail(false);
  } catch (error) {
    console.error('workerRequestUpdateEvent', error);
  }
}

function* workerGetEvents() {
  const response: AxiosResponse<IResGetEvents> = yield Axios.post(ODOCTOR_APP_URL, {
    query: print(GetEvents),
    variables: {
      pagination: {
        perPage: 1000,
      },
    },
  });
}

function* watchCreateEvent() {
  yield takeLatest(CREATE_EVENT, workerCreateEvent);
}

function* watchRequestUpdateEvent() {
  yield takeLatest(REQUEST_UPDATE_EVENT, workerRequestUpdateEvent);
}

function* watchGetEvents() {
  yield takeLatest(actionGetEvents.request, workerGetEvents);
}

export default function* eventSaga() {
  yield all([fork(watchCreateEvent), fork(watchRequestUpdateEvent), fork(watchGetEvents)]);
}
