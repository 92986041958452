import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { SHOW_MEMO_MODAL } from './ui.actions';
import { IUIState } from './ui.models';

const initialState = {
  memoModal: {
    visible: false,
    memo: '',
  },
};

const uiReducer = createReducer<IUIState, RootAction>(initialState, {
  [SHOW_MEMO_MODAL]: (state, action) => ({
    ...state,
    memoModal: {
      visible: action.payload.visible,
      memo: action.payload.memo,
    },
  }),
});

export default uiReducer;
