import Axios, { AxiosResponse } from 'axios';
import { takeLatest, all, fork, select } from 'redux-saga/effects';
import { print } from 'graphql';

import { RootState } from 'modules/rootState';
import { HospitalState, IResGetEvents, IResSignedUrl } from './hospital.models';
import {
  actionCreateHospital,
  CREATE_HOSPITAL,
  actionGetEvents,
  REQUEST_UPDATE_HOSPITAL,
  actionRequestUpdateHospital,
  REQUEST_UPDATE_HOSPITAL_META,
  actionUpdateHospitalMeta,
} from './hospital.actions';
import { GetEvents } from 'graphql/event.query';
import { getSignedUrl, removeKey, replaceEndPoint } from 'utils/createEventHelper';

const ODOCTOR_APP_URL = process.env.REACT_APP_ODOCTOR_APP_URL || '';

function* workerCreateHospital(action: ReturnType<typeof actionCreateHospital>) {
  const hospital: HospitalState = yield select((state: RootState) => state.hospital);

  try {
    const { callback } = action.payload;

    yield callback({
      variables: {
        input: hospital,
      },
    });
  } catch (error) {
    console.error('workerCreateEvent', error);
  }
}

function* workerRequestUpdateHospital(action: ReturnType<typeof actionRequestUpdateHospital>) {
  try {
    const { callback, hospital, setShowDetail } = action.payload;

    const { id, name, hiraCode, location, category, tel, isUseCrmService } = hospital;

    const input = Object.assign({
      id,
      name,
      hiraCode,
      tel,
      gunguId: location.id,
      hospitalCategoryId: category.id,
      isUseCrmService,
    });

    yield callback({ variables: { input } });
    yield setShowDetail(false);
  } catch (error) {
    console.error('workerRequestUpdateEvent', error);
  }
}

function* workerRequestUpdateHospitalMeta(action: ReturnType<typeof actionUpdateHospitalMeta>) {
  const hospital: HospitalState = yield select((state: RootState) => state.hospital);
  const meta = hospital.meta;

  try {
    const { callback, cctvImage, certiImage, hospitalId } = action.payload;

    const files = [...cctvImage, ...certiImage].map((f) => f.file);
    const signedUrls = files.map((file, idx) =>
      getSignedUrl(file, idx === 0, `hospital/${hospitalId}`)
    );
    const response: AxiosResponse<{ data: IResSignedUrl }>[] = yield Promise.all(signedUrls);
    const s3Urls = response.map((res, idx) => {
      Axios.put(res.data.data.signS3.signedRequest, files[idx]);
      return res.data.data.signS3.url;
    });

    s3Urls.slice(0, cctvImage.length).forEach((url: string, i: number) => {
      meta.cctv.images[i].url = url;
    });

    s3Urls
      .slice(cctvImage.length, cctvImage.length + certiImage.length)
      .forEach((url: string, i: number) => {
        meta.certi.images[i].url = url;
      });

    yield callback({
      variables: {
        input: {
          id: hospitalId,
          meta,
        },
      },
    });

    // yield callback({
    //   variables: {
    //     input: Object.assign({}, removedObj, {
    //       startDate: event.range[0],
    //       endDate: event.range[1],
    //       thumbnailUrl: replaceEndPoint(s3Urls[0]),
    //       contentImgUrls: s3Urls.slice(1).map((url) => replaceEndPoint(url)),
    //     }),
    //   },
    // });

    // const { id, name, hiraCode, location, category, tel } = hospital;

    // const input = Object.assign({
    //   id,
    //   name,
    //   hiraCode,
    //   tel,
    //   gunguId: location.id,
    //   hospitalCategoryId: category.id,
    // });

    // yield callback({ variables: { input } });
    // yield setShowDetail(false);
  } catch (error) {
    console.error('workerRequestUpdateEvent', error);
  }
}

// function* workerGetEvents() {
//   const response: AxiosResponse<IResGetEvents> = yield Axios.post(ODOCTOR_APP_URL, {
//     query: print(GetEvents),
//     variables: {
//       pagination: {
//         perPage: 1000,
//       },
//     },
//   });

//   console.log(response.data.data.events.data);
// }

function* watchCreateHospital() {
  yield takeLatest(CREATE_HOSPITAL, workerCreateHospital);
}

function* watchRequestUpdateHospital() {
  yield takeLatest(REQUEST_UPDATE_HOSPITAL, workerRequestUpdateHospital);
}

function* watchRequestUpdateHospitalMeta() {
  yield takeLatest(REQUEST_UPDATE_HOSPITAL_META, workerRequestUpdateHospitalMeta);
}

// function* watchGetEvents() {
//   yield takeLatest(actionGetEvents.request, workerGetEvents);
// }

export default function* hospitalSaga() {
  yield all([
    fork(watchCreateHospital),
    fork(watchRequestUpdateHospital),
    fork(watchRequestUpdateHospitalMeta) /* fork(watchGetEvents) */,
  ]);
}
