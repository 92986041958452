type ValueOf<T> = T[keyof T];

export enum Status {
  A01 = '상태 (전체)',

  N01 = '신규',

  C01 = '재연락필요',
  C02 = '부재중',

  V01 = '내원예정',
  V03 = '내원안함',

  T02 = '치료완료',
  T01 = '치료안함',

  F01 = '취소',
  F02 = '중복',
  F03 = '결번',
}

export enum Path {
  All = '경로 (전체)',
  Special = '스페셜픽',
  Weekly = '위클리픽',
}

export enum Refund {
  REFUND_DUPLICATION = '환불-중복',
  REFUND_NO_ADULT = '환불-미성년자',
  REFUND_NO_PHONE = '환불-결번',
  IN_ETC = '환불 - 기타',
}

export enum UserAgeGroup {
  underTen = '미성년자',
  teenager = '10대',
  twenties = '20대',
  thirties = '30대',
  forties = '40대',
  fifties = '50대',
  overSixties = '60대 이상',
}

export enum Transaction {
  IN_MANAGER = '충전 - 관리자 조정',
  IN_NORMAL = '충전 - 정상충전',
  IN_SERVICE = '충전 - 서비스 충전',
  OUT_MANAGER = '차감 - 관리자 조정',
  REFUND_DUPLICATION = '환불 - 중복',
  REFUND_NO_ADULT = '환불 - 미성년자',
  REFUND_NO_PHONE = '환불 - 결번',
  IN_ETC = '환불 - 기타',
}

export enum TransactionRefund {
  IN_ETC = '환불 - 기타',
  REFUND_DUPLICATION = '환불 - 중복',
  REFUND_NO_ADULT = '환불 - 미성년자',
  REFUND_NO_PHONE = '환불 - 결번',
}

export enum ChargeAction {
  All = '전체',
  in = '충전',
  out = '차감',
}

export enum EventType {
  All = '전체',
  reward = '적립',
  use = '소진',
}

export const DeliveryStatus = {
  0: '전체',
  1: '집하요청',
  2: '집하',
  3: '배송중',
  4: '배달완료',
} as const;

export const ProductType = {
  0: '결제항목 (전체)',
  1: '진료비',
  2: '약값+조제료',
  3: '이용료',
} as const;

export const PaidStatus = {
  0: '미결제',
  1: '결제완료',
  2: '결제여부 (전체)',
};

export type RefundStrings = keyof typeof Refund;
export type StatusStrings = keyof typeof Status;
export type PathStrings = keyof typeof Path;
export type UserAgeGroupStrings = keyof typeof UserAgeGroup;
export type TransactionStrings = keyof typeof Transaction;
export type TransactionRefundStrings = keyof typeof TransactionRefund;
export type ChargeActionStrings = keyof typeof ChargeAction;
export type EventTypeStrings = keyof typeof EventType;

export type UserPointHistoryType = 'reward' | 'use';
