import React from 'react';
import { Input, Button } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'modules/rootState';
import { actionSetUserFilter } from 'modules/filter/filter.actions';
import useUser from 'hooks/userUser';
import useFilterModel from 'hooks/useFilterModel';

const { Search } = Input;

export default function UserFilter() {
  const { user } = useSelector((state: RootState) => state.filter);
  const { userFilterModel } = useFilterModel();
  const { getUsers } = useUser();
  const dispatch = useDispatch();

  return (
    <Row>
      <Search
        placeholder="유저이름"
        allowClear
        value={user.name}
        onChange={(e) => dispatch(actionSetUserFilter({ key: 'name', value: e.target.value }))}
        style={{ width: '25rem', marginRight: '1rem' }}
      />
      <Wrap>
        <Input
          value={user.id}
          onChange={(e) => dispatch(actionSetUserFilter({ key: 'id', value: e.target.value }))}
          placeholder="아이디"
        />
      </Wrap>
      <Wrap>
        <Input
          value={user.email}
          onChange={(e) => dispatch(actionSetUserFilter({ key: 'email', value: e.target.value }))}
          placeholder="이메일"
        />
      </Wrap>
      <Wrap>
        <Input
          value={user.phonenum}
          onChange={(e) =>
            dispatch(actionSetUserFilter({ key: 'phonenum', value: e.target.value }))
          }
          placeholder="핸드폰번호"
        />
      </Wrap>
      <Button onClick={() => getUsers({ variables: { filter: userFilterModel } })}>검색</Button>
    </Row>
  );
}

const Row = styled.div`
  display: flex;
`;

const Wrap = styled.div`
  width: 25rem;
  margin-right: 1rem;
`;
