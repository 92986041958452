import { useMutation, useQuery } from '@apollo/client';

import { GetBlackList } from 'graphql/blacklist.query';
import { IReqUpdateEvent, IResUpdateEvent } from 'modules/event/event.models';

export type IEvnetBlackList = {
  id: string;
  phone: string;
  name: string;
  hospital_id: string;
  memo: string;
  kickban: boolean;
  is_delete: boolean;
};

interface IRes {
  eventBlackList: {
    data: IEvnetBlackList[];
  };
}

export default function useBlackList() {
  const pagination = { perPage: 9999 };

  const { data } = useQuery<IRes>(GetBlackList, { variables: { pagination } });

  // const [updateEvent] = useMutation<IResUpdateEvent, IReqUpdateEvent>(UpdateEvent, {
  //   refetchQueries: [{ query: GetEventsAll, variables: { pagination } }],
  // });

  if (!data) {
    return {
      eventBlackList: [],
      // updateEvent,
    };
  }

  return { eventBlackList: data.eventBlackList.data /*updateEvent*/ };
}
