import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { notification } from 'antd';

import { CreateEvent, CreateSignedUrl, GetEventCategories } from 'graphql/event.query';
import {
  GetFakeUser,
  CreateFakePost,
  CreateFakePostComment,
  CreateFakePostLike,
} from 'graphql/post.jujak.query';

import {
  ReqGetFakeUser,
  ResGetFakeUser,
  ReqCreateFakePost,
  ResCreateFakePost,
  ReqCreateFakePostComment,
  ResCreateFakePostComment,
  ReqCreateFakePostLike,
  ResCreateFakePostLike,
} from 'modules/post/post.models';

import { actionSetFakeUser, actionSetPost, actionSetPostComment } from 'modules/post/post.actions';
import {
  ICategories,
  IReqCreateEvent,
  IReqSignedUrl,
  IResCreateEvent,
  IResSignedUrl,
} from 'modules/event/event.models';
import { resetEvent } from 'modules/event/event.actions';

export default function usePostJujak(onCompleted: () => void) {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<ICategories[]>([]);

  // #1 주작용 가짜 유저 확인 (index로 확인함)
  const [getFakeUser] = useLazyQuery<ResGetFakeUser, ReqGetFakeUser>(GetFakeUser, {
    onCompleted: (res) => {
      dispatch(actionSetFakeUser({ fakeUser: { ...res.fakeUser[0] } }));
    },
  });

  // #2 글쓰기 주작
  const [createFakePost, { loading }] = useMutation<ResCreateFakePost, ReqCreateFakePost>(
    CreateFakePost,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
        onCompleted();
      },
      onError: (error) => {
        // dispatch(resetEvent());
        // resetAfterCreateEvent();

        notification.error({
          message: '망함',
          description: error.message,
          placement: 'bottomRight',
          duration: 5,
        });
      },
    }
  );

  // #3 댓글 주작
  const [createFakePostComment] = useMutation<ResCreateFakePostComment, ReqCreateFakePostComment>(
    CreateFakePostComment,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
        onCompleted();
        // dispatch(resetEvent());
        // resetAfterCreateEvent();
      },
      onError: (error) => {
        dispatch(resetEvent());
        // resetAfterCreateEvent();

        notification.error({
          message: '망함',
          description: error.message,
          placement: 'bottomRight',
          duration: 5,
        });
      },
    }
  );

  // #4 좋아요 주작
  const [createFakePostLike] = useMutation<ResCreateFakePostLike, ReqCreateFakePostLike>(
    CreateFakePostLike,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
        onCompleted();
        // dispatch(resetEvent());
        // resetAfterCreateEvent();
      },
      onError: (error) => {
        dispatch(resetEvent());
        // resetAfterCreateEvent();

        notification.error({
          message: '망함',
          description: error.message,
          placement: 'bottomRight',
          duration: 5,
        });
      },
    }
  );

  return { getFakeUser, createFakePost, createFakePostComment, createFakePostLike };
}
