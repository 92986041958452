import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, Select, InputNumber, Input, Switch, Button, Modal, message } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import styled from 'styled-components';

import useUpdateHospitalMeata from 'hooks/useUpdateHospitalMeta';

import LabelRow from 'components/CreateEvent/LabelRow';
import { DatePicker } from 'components/CustomPicker';
import { RootState } from 'modules/rootState';
import { IEventState } from 'modules/event/event.models';
import { actionCreateEvent, setEventByKey } from 'modules/event/event.actions';
import InputFileSingle from 'components/Input/InputFileSingle';
import InputFileMultiple from 'components/Input/InputFileMultiple';
import { hospitalCreateValidation } from 'utils/hospitalValidation';

import { HospitalState } from 'modules/hospital/hospital.models';
import {
  actionCreateHospital,
  setHospitalByKey,
  actionUpdateHospitalMeta,
  resetHospitalInfo,
} from 'modules/hospital/hospital.actions';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Option } = Select;

export default function CreateHospital() {
  const dispatch = useDispatch();
  const hospital = useSelector((state: RootState) => state.hospital);
  const { updateHospital, loading } = useUpdateHospitalMeata(() => {
    // setThumbnailImage(undefined);
    // setEventPosters([]);
    console.log('updated');
    setCertiImage([]);
    setCctvImage([]);
    dispatch(resetHospitalInfo());
    alert('done');
  });
  const [thumbnailImage, setThumbnailImage] = useState<File>();

  const [certiImage, setCertiImage] = useState<any[]>([]);
  const [cctvImage, setCctvImage] = useState<any[]>([]);
  const [hospitalId, setHospitalId] = useState<number>(0);

  const InputFile = ({ state, setState, index }) => (
    <InputFileMultiple index={index} eventPosters={state} setEventPosters={setState} />
  );

  function changeDataByKey(key: keyof HospitalState, value: any) {
    dispatch(setHospitalByKey({ key, value }));
  }

  function changeMetaImages(file: any) {
    setCctvImage(file);

    // dispatch(setHospitalByKey({ key, value }));
  }

  function onSubmit() {
    // if (hospitalCreateValidation({ ...hospital })) {
    //   message.error('필수입력을 확인해주세요');
    //   return;
    // }

    confirm({
      title: '픽 하이라이트를 생성하겠습니까?',
      onOk() {
        dispatch(
          actionUpdateHospitalMeta({ callback: updateHospital, cctvImage, certiImage, hospitalId })
        );
      },
    });
  }

  return (
    <div style={{ minWidth: '1200px', padding: '1.6rem', margin: '0 1.6rem', background: '#fff' }}>
      <PageHeader title="스토리 데이터 생성/수정" subTitle="alpha version" />

      <LabelRow label="병원 아이디">
        <div>
          <InputNumber min={1} max={9999} onChange={(value) => setHospitalId(value || 0)} />
        </div>
      </LabelRow>
      {/* cctv */}
      <LabelRow label="Meta:CCTV">
        <Switch
          checkedChildren="노출"
          unCheckedChildren="비노출"
          defaultChecked
          checked={!!hospital.meta.cctv.enable || false}
          onChange={(checked) => {
            const data = {
              ...hospital.meta,
              cctv: {
                ...hospital.meta.cctv,
                enable: checked,
              },
            };
            changeDataByKey('meta', data);
          }}
        />
        <InputWrapper>
          <InputFile index={1} state={cctvImage} setState={setCctvImage} />
        </InputWrapper>
        <Warn>
          이미지 480 * 300 (조나단 규격), 확장자 webp, 70% 손실로 저장해서 최대한 용량 작게
        </Warn>
        {cctvImage.map((_, n) => {
          return (
            <div key={n}>
              <Input
                name="describe"
                placeholder={`${n + 1}번 이미지 설명`}
                onChange={({ target: { value } }) => {
                  const tmp = hospital.meta.cctv.images;
                  tmp[n] = {
                    url: '',
                    describe: value,
                  };
                  const data = {
                    ...hospital.meta,
                    cctv: {
                      ...hospital.meta.cctv,
                      images: tmp,
                    },
                  };
                  changeDataByKey('meta', data);
                }}
                style={{ width: '40rem' }}
              />
            </div>
          );
        })}
      </LabelRow>

      <LabelRow label="Meta:자격증">
        <Switch
          checkedChildren="노출"
          unCheckedChildren="비노출"
          defaultChecked
          checked={!!hospital.meta.certi.enable || false}
          onChange={(checked) => {
            const data = {
              ...hospital.meta,
              certi: {
                ...hospital.meta.certi,
                enable: checked,
              },
            };
            changeDataByKey('meta', data);
          }}
        />
        <InputWrapper>
          {/* <InputFileMultiple eventPosters={certiImage} setEventPosters={setCertiImage} /> */}
          <InputFile index={2} state={certiImage} setState={setCertiImage} />
        </InputWrapper>
        <Warn>
          이미지 480 * 680(최대, 조나단 규격), 확장자 webp, 70% 손실로 저장해서 최대한 용량 작게
        </Warn>
        {certiImage.map((_, n) => {
          return (
            <div key={n}>
              <Input
                name="describe"
                placeholder={`${n + 1}번 이미지 설명`}
                onChange={({ target: { value } }) => {
                  const tmp = hospital.meta.certi.images;
                  tmp[n] = {
                    url: '',
                    describe: value,
                  };
                  const data = {
                    ...hospital.meta,
                    certi: {
                      ...hospital.meta.certi,
                      images: tmp,
                    },
                  };
                  changeDataByKey('meta', data);
                }}
                style={{ width: '40rem' }}
              />
            </div>
          );
        })}
      </LabelRow>

      <LabelRow label="Meta:병원 미리보기">
        <Switch
          checkedChildren="노출"
          unCheckedChildren="비노출"
          defaultChecked
          checked={!!hospital.meta.preview.enable || false}
          onChange={(checked) => {
            const data = {
              ...hospital.meta,
              preview: {
                ...hospital.meta.preview,
                enable: checked,
              },
            };
            changeDataByKey('meta', data);
          }}
        />
        <div>
          <Input
            placeholder="오닥터치과의원"
            onChange={({ target: { value } }) => {
              const data = {
                ...hospital.meta,
                preview: {
                  ...hospital.meta.preview,
                  youtube: value,
                },
              };
              changeDataByKey('meta', data);
            }}
            style={{ width: '40rem' }}
          />
        </div>

        <Warn>여러개는 ',' 로 구분해주세요</Warn>
      </LabelRow>

      <LabelRow label="Meta:병원 운영시간">
        <Switch
          checkedChildren="노출"
          unCheckedChildren="비노출"
          defaultChecked
          checked={!!hospital.meta.hours.enable || false}
          onChange={(checked) => {
            const data = {
              ...hospital.meta,
              hours: {
                ...hospital.meta.hours,
                enable: checked,
              },
            };
            changeDataByKey('meta', data);
          }}
        />
        <div>
          <TextArea
            placeholder="우녕시간"
            autoSize={{ minRows: 1, maxRows: 10 }}
            // value={post.content}
            onChange={({ target: { value } }) => {
              const data = {
                ...hospital.meta,
                hours: {
                  ...hospital.meta.hours,
                  text: value,
                },
              };
              changeDataByKey('meta', data);
            }}
            style={{ width: '40rem' }}
          />
        </div>

        <Warn>여러개는 ',' 로 구분해주세요</Warn>
      </LabelRow>

      <LabelRow label="Meta:인근 지하철">
        <Switch
          checkedChildren="노출"
          unCheckedChildren="비노출"
          defaultChecked
          checked={!!hospital.meta.subway.enable || false}
          onChange={(checked) => {
            const data = {
              ...hospital.meta,
              subway: {
                ...hospital.meta.subway,
                enable: checked,
              },
            };
            changeDataByKey('meta', data);
          }}
        />
        <div>
          <InputNumber
            min={1}
            max={10}
            onChange={(value) => {
              const data = {
                ...hospital.meta,
                subway: {
                  ...hospital.meta.subway,
                  line: value,
                },
              };
              changeDataByKey('meta', data);
            }}
          />
        </div>
        <div>
          <TextArea
            placeholder="설명"
            autoSize={{ minRows: 1, maxRows: 10 }}
            // value={post.content}
            onChange={({ target: { value } }) => {
              const data = {
                ...hospital.meta,
                subway: {
                  ...hospital.meta.subway,
                  text: value,
                },
              };
              changeDataByKey('meta', data);
            }}
            style={{ width: '40rem' }}
          />
        </div>

        <Warn>여러개는 ',' 로 구분해주세요</Warn>
      </LabelRow>

      <LabelRow label="Meta:주작용 평점">
        <div>
          <Input
            placeholder="2, 4.8, 11"
            onChange={({ target: { value } }) => {
              const data = {
                ...hospital.meta,
                grade: value,
              };
              changeDataByKey('meta', data);
            }}
            style={{ width: '40rem' }}
          />
        </div>

        <Warn>뱃지(1|2|4), 평점, 카운트를 ',' 로 구분</Warn>
      </LabelRow>

      <Button type="primary" onClick={onSubmit} style={{ width: '20rem' }} disabled={loading}>
        확인
      </Button>
    </div>
  );
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Warn = styled.p`
  margin: 0;
  color: #ff8080;
`;
