import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ApolloProvider } from '@apollo/client';

import { configureStore, history } from './stores';
import App from 'App';
import Login from 'pages/Login';
import apolloClient from 'apolloClient';

const store = configureStore();

function Root() {
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">
              <App />
            </Route>
          </Switch>
        </ConnectedRouter>
      </ApolloProvider>
    </Provider>
  );
}

export default Root;
