import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import InputChargeHistory from 'components/Input/InputChargeHistory';
import useChargeHistory from 'hooks/useChargeHistory';
import useFilterModel from 'hooks/useFilterModel';
import ChargeFilter from 'components/Filter/ChargeFilter';
import { HospitalPointTransactions } from 'odoc-interface-module/odoc-manager-front/hospitalPointTransactions';
import { ChargeAction } from 'odoc-interface-module/common/status';

export default function Charge() {
  const [showInput, setShowInput] = useState(false);

  const { getChargeHistory, lists, pagination, loading } = useChargeHistory();
  const { chargeFilterModel } = useFilterModel();

  useEffect(() => {
    getChargeHistory();
  }, [getChargeHistory]);

  const columns: ColumnsType<HospitalPointTransactions.HospitalPointTransaction> = [
    {
      title: '병원',
      dataIndex: 'hospital',
      key: 'hospital',
      render: (hospital, record) => (
        <>
          <div>{hospital.name}</div>
          <Tag>병원ID: {record.hospital.id}</Tag>
        </>
      ),
    },
    {
      title: '구분',
      dataIndex: 'action',
      key: 'action',
      render: (action) => (
        <Tag color={action === 'in' ? 'green' : 'volcano'}>{ChargeAction[action]}</Tag>
      ),
    },
    {
      title: '내역',
      dataIndex: 'description',
      key: 'description',
      render: (description, record) => (
        <>
          <div>{description}</div>
          {record.reservation && <Tag>예약ID: {record.reservation.id}</Tag>}
        </>
      ),
    },
    {
      title: '변동액',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <span>{amount.toLocaleString('ko', { maximumFractionDigits: 1 })}</span>,
    },
    {
      title: '잔액',
      dataIndex: 'balance',
      key: 'balance',
      render: (balance) => (
        <span>{balance.toLocaleString('ko', { maximumFractionDigits: 1 })}</span>
      ),
    },
    {
      title: '일자',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => <span>{dayjs(timestamp).format('YYYY.MM.DD HH:mm')}</span>,
    },
  ];

  const pagingData = {
    current: pagination.currentPage,
    total: pagination.total,
  };

  return (
    <div style={{ minWidth: '1200px', padding: '0 1.6rem' }}>
      <ChargeFilter getChargeHistory={getChargeHistory} setShowInput={setShowInput} />
      <Table<HospitalPointTransactions.HospitalPointTransaction>
        dataSource={lists}
        columns={columns}
        rowKey={(record) => record.id}
        pagination={pagingData}
        loading={loading}
        onChange={(paging) =>
          getChargeHistory({
            variables: {
              filter: chargeFilterModel,
              pagination: {
                perPage: paging.pageSize,
                currentPage: paging.current,
              },
            },
          })
        }
      />
      <InputChargeHistory showInput={showInput} setShowInput={setShowInput} />
    </div>
  );
}
