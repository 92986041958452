import { notification } from 'antd';
import XLSX from 'xlsx';
import dayjs from 'dayjs';
import { get } from 'lodash';

import { Status } from 'odoc-interface-module/common/status';
import { EventReservations } from 'odoc-interface-module/odoc-manager-front/eventReservations';

const columns = {
  TREATMENT_COLUMN: [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '신청일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => dateFormat(createdAt),
    },
    {
      title: '진료분야',
      dataIndex: 'departmentName',
      key: 'departmentName',
    },
    {
      title: '사용자',
      dataIndex: 'user',
      key: 'user',
      render: (user) => `${user.name} ID: ${user.id}`,
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return status;
      },
    },
    {
      title: '병원',
      dataIndex: ['hospitalCare', 'hospitalName'],
      key: 'hospitalName',
    },
    {
      title: '진료비',
      dataIndex: ['hospitalCare', 'amount'],
      key: 'hospitalAmount',
    },
    {
      title: '처방전',
      dataIndex: ['prescription', 'expirationDate'],
      key: 'expirationDate',
      render: (expirationDate, row) => {
        let text = '';
        if (expirationDate) {
          const diff = dayjs().diff(expirationDate, 'day');
          if (diff > 7) text = '만료';
        }

        return text;
      },
    },
    {
      title: '약꾹',
      dataIndex: ['"pharmacyCare": ', '"pharmacyName": '],
      key: 'hospitalName',
    },
    {
      title: '약제비',
      dataIndex: ['pharmacyCare', 'amount'],
      key: 'hospitalAmount',
    },
  ],
  PAYMENT_COLUMN: [
    {
      title: '진료ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '발생일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => dateFormat(createdAt),
    },
    {
      title: '업체',
      dataIndex: 'client',
      key: 'client',
      render: (client) => `${client.name} (${client.id})`,
    },
    {
      title: '결제항목',
      dataIndex: ['product', 'name'],
      key: 'productName',
    },
    {
      title: '결제금액',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: '결제여부',
      dataIndex: 'paid',
      key: 'paid',
      render: (v) => (v ? '완료' : '미결제'),
    },
    {
      title: '결제일',
      dataIndex: 'payDate',
      key: 'payDate',
      render: (payDate) => dateFormat(payDate),
    },
    {
      title: '사용자',
      dataIndex: 'user',
      key: 'user',
      render: (user) => `${user.name} (${birthFormat(user.birthDate)})`,
    },
  ],
};

const column = [
  '신청일',
  '병원',
  '유저',
  '이벤트명',
  '가격(할인)',
  '과금액',
  '상태',
  '메모',
  '예약일정',
];

const dateFormat = (date: string | null) => date && dayjs(date).format('YYYY.MM.DD HH:mm');
const birthFormat = (date: string) => dayjs(date).format('YYYY.MM.DD');

export function exportToExcel(
  eventReservations: EventReservations.IEventReservationData[],
  filterModel: any
) {
  try {
    let userRow = '';
    const reservationData = eventReservations.reduce(
      (acc, value) => {
        userRow = `${value.username} `;
        value.userGender && (userRow += `${value.userGender === 'male' ? '남자 ' : '여자 '}`);
        value.userBirthYear && (userRow += `${dayjs().year() - value.userBirthYear + 1}세`);

        acc.push([
          dayjs(value.createdAt).format('YYYY-MM-DD HH:mm'),
          value.hospital.name,
          userRow,
          value.event.name,
          value.discountedPrice,
          value.chargePrice,
          Status[value.status],
          value.memo,
          value.reservationTime && dayjs(value.reservationTime).format('YYYY-MM-DD HH:mm'),
        ]);

        return acc;
      },
      [column] as any
    );

    const worksheet = XLSX.utils.aoa_to_sheet(reservationData);
    const new_workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS');
    XLSX.writeFile(new_workbook, handleFilename('예약관리', filterModel));
  } catch (error) {
    console.error('fail - export to excel', error);
    notification.error({
      message: '액셀 내보내기 실패',
      description: '문제가 지속되면 관리자에게 연락해주세요.',
      placement: 'bottomRight',
      duration: 5,
    });
  }
}

const converter = (value, column) => {
  return column.map((c) => {
    const v = get(value, c.dataIndex);
    return c.render ? c.render(v) : v;
  });
};

export function exportToExcelWithColumn({ type, data, filterQuery }) {
  const prefixName = { PAYMENT: '정산관리', TREATMENT: '진료관리' }[type];
  const clms = columns[`${type}_COLUMN`];

  const header = clms.map((c) => c.title);

  try {
    const writeData = data.items.reduce(
      (acc, value) => {
        return acc.push(converter(value, clms)) && acc;
      },
      [header] as any
    );

    const worksheet = XLSX.utils.aoa_to_sheet(writeData);
    const new_workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS');
    XLSX.writeFile(new_workbook, handleFilename(prefixName, filterQuery));
  } catch (error) {
    console.error('fail - export to excel', error);
    notification.error({
      message: '액셀 내보내기 실패',
      description: '문제가 지속되면 관리자에게 연락해주세요.',
      placement: 'bottomRight',
      duration: 5,
    });
  }
}

function handleFilename(prefix: string | null = '황천의 파편', model: any) {
  if (model.date) {
    const { startDate, endDate } = model.date;
    return `[${prefix}] ${startDate} ~ ${endDate} ${new Date().getTime()}.xlsx`;
  }

  return `[${prefix}] 전체날짜 ${new Date().getTime()}.xlsx`;
}
