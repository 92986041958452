import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import { RootState } from 'modules/rootState';
import { actionSetUsers } from 'modules/user/user.actions';
import { GetUsers } from 'graphql/user.query';
import { User, Pagination } from 'modules/user/user.models';

interface IResGetUsers {
  users: {
    data: User[];
    pagination: Pagination;
  };
}

export default function useUser() {
  const dispatch = useDispatch();
  const { list, pagination } = useSelector((state: RootState) => state.user);

  const [getUsers] = useLazyQuery<IResGetUsers>(GetUsers, {
    onCompleted: (res) =>
      dispatch(actionSetUsers({ list: res.users.data, pagination: res.users.pagination })),
  });

  return { getUsers, list, pagination };
}
