import { createAction, ActionType } from 'typesafe-actions';
import { MutationFunctionOptions } from '@apollo/client';

import { PostCatListState } from './managePostCat.models';

export const SET_POST_CAT_LIST = 'SET_POST_CAT_LIST';
export const actionSetPostCatList = createAction(SET_POST_CAT_LIST)<PostCatListState>();

export const CREATE_POST_CAT_LIST = 'SET_POST_CAT_LIST';
export const actionCreatePostCatList = createAction(CREATE_POST_CAT_LIST)<{
  callback: (options?: MutationFunctionOptions<any, any> | undefined) => void;
  list: any;
  pagination?: any;
}>();

export const UPDATE_POST_CAT_LIST = 'UPDATE_POST_CAT_LIST';
export const actionUpdatePostCatList = createAction(UPDATE_POST_CAT_LIST)<{
  callback: (options?: MutationFunctionOptions<any, any> | undefined) => void;
  list: any;
  pagination?: any;
}>();

const actions = {
  actionSetPostCatList,
  actionCreatePostCatList,
  actionUpdatePostCatList,
};

export type ManagePostCatAction = ActionType<typeof actions>;
