import { useSelector } from 'react-redux';

import { RootState } from 'modules/rootState';
import { TransactionRefund } from 'odoc-interface-module/common/status';

export default function useChargeInputModel() {
  const { transactionType, charge, reservationId, hospitalId } = useSelector(
    (state: RootState) => state.chargeHistory
  );

  const [transaction] = transactionType.filter((transaction) => transaction.selected);

  let model: any = { hospitalId };
  if (transaction) {
    model = Object.assign(model, { hospitalPointTypeId: transaction.id });
    if (TransactionRefund[transaction.id]) {
      return Object.assign(model, { reservationId });
    } else {
      return Object.assign(model, { amount: Number(charge) });
    }
  } else {
    return model;
  }
}
