import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { SET_AUTH, SET_LOGOUT } from './auth.actions';
import { IAuthState } from './auth.models';

const initialState = {
  email: '',
  imageUrl: '',
  expires_at: 0,
  expires_in: 0,
  id_token: '',
};

const authReducer = createReducer<IAuthState, RootAction>(initialState, {
  [SET_AUTH]: (state, action) => ({
    email: action.payload.email,
    imageUrl: action.payload.imageUrl,
    expires_at: action.payload.expires_at,
    expires_in: action.payload.expires_in,
    id_token: action.payload.id_token,
  }),

  [SET_LOGOUT]: () => initialState,
});

export default authReducer;
