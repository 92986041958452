import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QueryLazyOptions } from '@apollo/client';
import { Select, Input, Button } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ko_KR';

import { DatePicker } from 'components/CustomPicker';
import useFilterModel from 'hooks/useFilterModel';
import { setPointFilter, resetPointFilter } from 'modules/filter/filter.actions';
import { RootState } from 'modules/rootState';
import { UserPointHistory } from 'odoc-interface-module/odoc-manager-front/userPointHistory';
import { EventType } from 'odoc-interface-module/common/status';

const { Option } = Select;

interface IPointFilter {
  getUserPointHistory: (options?: QueryLazyOptions<UserPointHistory.Request> | undefined) => void;
  setShowInput: Dispatch<SetStateAction<boolean>>;
}

export default function PointFilter({ getUserPointHistory, setShowInput }: IPointFilter) {
  const dispatch = useDispatch();
  const { eventType, userId, itemName, range } = useSelector(
    (state: RootState) => state.filter.point
  );
  const { pointFilterModel } = useFilterModel();

  const onSubmit = () => {
    getUserPointHistory({ variables: { filter: pointFilterModel } });
  };

  return (
    <div style={{ display: 'flex', margin: '1rem 0 2rem 0' }}>
      <Select
        value={eventType}
        onChange={(value) => dispatch(setPointFilter({ key: 'eventType', value }))}
        showArrow={false}
        listHeight={369}
        style={{ width: 120, marginRight: '1rem' }}
      >
        {Object.entries(EventType).map(([key, value]) => (
          <Option value={key} key={key}>
            {value}
          </Option>
        ))}
      </Select>
      <DatePicker.RangePicker
        locale={locale}
        value={range}
        onChange={(value) => {
          const calEndDay = value?.map((v, idx) => {
            if (idx === 1) {
              return v?.endOf('day');
            }

            return v?.startOf('day');
          });

          dispatch(setPointFilter({ key: 'range', value: calEndDay || [null, null] }));
        }}
        ranges={{
          오늘: [dayjs(), dayjs()],
          이번주: [dayjs().startOf('week'), dayjs().endOf('week')],
          이번달: [dayjs().startOf('month'), dayjs().endOf('month')],
          '3개월': [dayjs().subtract(3, 'month'), dayjs()],
          '6개월': [dayjs().subtract(6, 'month'), dayjs()],
          '1년': [dayjs().subtract(12, 'month'), dayjs()],
        }}
      />
      <Input
        placeholder="유저 아이디"
        type="number"
        value={userId}
        onChange={(e) => dispatch(setPointFilter({ key: 'userId', value: e.target.value }))}
        onKeyUp={(e) => e.key === 'Enter' && onSubmit()}
        style={{ width: 200, margin: '0 1rem' }}
      />
      <Input
        placeholder="내역"
        value={itemName}
        onChange={(e) => dispatch(setPointFilter({ key: 'itemName', value: e.target.value }))}
        onKeyUp={(e) => e.key === 'Enter' && onSubmit()}
        style={{ width: 200 }}
      />
      <Button
        style={{ margin: '0 0.5rem 0 2rem' }}
        onClick={() => {
          dispatch(resetPointFilter());
          getUserPointHistory();
        }}
      >
        검색 초기화
      </Button>
      <Button type="primary" onClick={onSubmit}>
        검색
      </Button>
      <Button type="primary" onClick={() => setShowInput(true)} style={{ marginLeft: '0.5rem' }}>
        포인트 내역 추가
      </Button>
    </div>
  );
}
