import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { IPointHistoryState } from './pointHistory.models';
import {
  RESET_POINT_HISTORY,
  SELECTED_POINT_ITEM,
  SET_POINT_BY_KEY,
  SET_POINT_HISTORY,
  SET_POINT_HISTORY_USER,
  SET_POINT_ITEMS,
} from './pointHistory.actions';

const initialState = {
  id: '',
  name: '',
  phonenum: '',
  email: '',
  point: {
    id: '',
    pointAmount: '',
    updatedAt: null,
  },
  isEmpty: true,
  pointItems: [],
  responsePointHistory: {
    lists: [],
    pagination: {
      currentPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
    },
  },
};

const pointHistoryReducer = createReducer<IPointHistoryState, RootAction>(initialState, {
  [SET_POINT_HISTORY_USER]: (state, action) => ({
    ...state,
    isEmpty: false,
    ...action.payload.user,
  }),

  [SET_POINT_BY_KEY]: (state, action) => ({ ...state, [action.payload.key]: action.payload.value }),

  [SET_POINT_ITEMS]: (state, action) => ({
    ...state,
    pointItems: action.payload.userPointRewardItems.map((item) => ({ ...item, selected: false })),
  }),

  [SELECTED_POINT_ITEM]: (state, action) => ({
    ...state,
    pointItems: state.pointItems.map((item) => {
      if (item.id === action.payload) {
        return { ...item, selected: true };
      }

      return {
        ...item,
        selected: false,
      };
    }),
  }),

  [SET_POINT_HISTORY]: (state, action) => ({
    ...state,
    responsePointHistory: {
      lists: action.payload.data,
      pagination: action.payload.pagination,
    },
  }),

  [RESET_POINT_HISTORY]: (state) => ({
    ...initialState,
    pointItems: state.pointItems.map((item) => ({ ...item, selected: false })),
    responsePointHistory: state.responsePointHistory,
  }),
});

export default pointHistoryReducer;
