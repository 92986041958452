import { createAction, ActionType } from 'typesafe-actions';

import { HospitalPointTransactions } from 'odoc-interface-module/odoc-manager-front/hospitalPointTransactions';
import { TransactionStrings } from 'odoc-interface-module/common/status';

interface Payload {
  key: string;
  value: any;
}

interface IHospital {
  hospitalName: string;
  balance: number;
}

export const SET_CHARGE_HISTORY = 'SET_CHARGE_HISTORY';
export const setChargeHistory =
  createAction(SET_CHARGE_HISTORY)<HospitalPointTransactions.Response>();

export const SET_CHARGE_BY_KEY = 'SET_CHARGE_BY_KEY';
export const setChargeByKey = createAction(SET_CHARGE_BY_KEY)<Payload>();

export const SET_HOSPITAL = 'SET_HOSPITAL';
export const setHospital = createAction(SET_HOSPITAL)<IHospital>();

export const RESET_CHARGE_INPUT = 'RESET_CHARGE_INPUT';
export const resetChargeInput = createAction(RESET_CHARGE_INPUT)();

export const SELECT_TRANSACTION_TYPE = 'SELECT_TRANSACTION_TYPE';
export const selectTransactionType = createAction(SELECT_TRANSACTION_TYPE)<TransactionStrings>();

const actions = {
  setChargeHistory,
  setChargeByKey,
  resetChargeInput,
  setHospital,
  selectTransactionType,
};

export type ChargeHistoryActions = ActionType<typeof actions>;
