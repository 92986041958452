import React, { useState } from 'react';
import styled from 'styled-components';
import LabelRow from 'components/CreateEvent/LabelRow';
import { Input, InputNumber, PageHeader, Button, Tag } from 'antd';

import usePostJujak from 'hooks/usePostJujak';

export default function UserList() {
  const [like, setLike] = useState<any>({
    postIds: [],
    // userId: '',
    count: Math.floor(Math.random() * 10) + 1,
  });

  const { createFakePostLike } = usePostJujak(() => {
    alert('완료');
    setLike({
      ...like,
      postIds: [],
    });
  });

  function changeDataByKey(key: any, value: any) {
    setLike({
      ...like,
      [key]: value,
    });
  }

  function onSubmit() {
    createFakePostLike({
      variables: {
        input: {
          ...like,
          postIds: like.postIds
            .trim()
            .replace(/ |,\s*$/g, '')
            .split(',')
            .map((v) => v * 1),
        },
      },
    });

    return;
    // if (eventValidation({ ...event, eventPosters }) || !thumbnailImage) {
    //   message.error('필수입력을 확인해주세요');
    //   return;
    // }

    // confirm({
    //   title: '이벤트를 생성하겠습니까?',
    //   onOk() {
    //     dispatch(actionCreateEvent({ callback: createEvent, eventPosters, thumbnailImage }));
    //   },
    // });
  }

  return (
    <Container>
      <div>
        <PageHeader title="좋아요 관리하기" subTitle="alpha version" />
        <div>
          * 입력한 본문ID에 해당하는 모든 글의 '좋아요' 를 '입력한 갯수' 만큼 일괄로 올림
          <br />
          * 유저ID는 가짜 유저들중 선택해서 입력
          <br />* 좋아요 갯수는 1~10 사이의 정수로 자동 입력됨 (수정 가능)
        </div>
        <LabelRow label="본문id" isRequired>
          <Input
            value={like.postIds}
            placeholder="',' 로 구분해주세요. ex) 1,2,3,4"
            onChange={({ target: { value } }) => changeDataByKey('postIds', value)}
            style={{ width: '40rem' }}
          />
        </LabelRow>
        <LabelRow label="좋아요 갯수" isRequired>
          <InputNumber
            min={1}
            max={999}
            value={like.count}
            onChange={(value) => changeDataByKey('count', value)}
            style={{ width: '40rem' }}
          />
        </LabelRow>

        <Button type="primary" onClick={onSubmit} style={{ width: '20rem' }}>
          좋아요 올리기
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div`
  min-width: 1200px;
  padding: 1.6rem;
  margin: 0 1.6rem;
  background: #fff;
`;

export const DiscountRate = styled.span`
  font-size: 2.4rem;
  line-height: 0.83;
  color: #dd4124;
  font-weight: 600;
`;

export const Price = styled.span<{ single?: boolean }>`
  font-size: 2.4rem;
  color: #dd4326;
  font-weight: 600;
  margin-left: ${({ single }) => !single && '0.6rem'};
`;

export const Origin = styled.span`
  font-size: 1.8rem;
  color: #b1b3b2;
  line-height: 1.11;
  margin-left: 0.6rem;
  text-decoration: line-through;
`;

export const CustomRow = styled.div`
  margin-bottom: 1rem;
`;

export const DnDWrapper = styled.div`
  padding: 1rem;
`;
