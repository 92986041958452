import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Layout, Menu, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleLogout } from 'react-google-login';

import { routes, limitAccountRouteData, limitAccounts, routeData } from 'pages/routes';
import AuthHelper from 'utils/AuthHelper';
import { setAuth, setLogout } from 'modules/auth/auth.actions';
import { RootState } from 'modules/rootState';
import Logo from '../../assets/images/logo.png';

const { Header, Sider } = Layout;
const { SubMenu } = Menu;

interface IAppLayout extends RouteComponentProps {
  children: React.ReactNode;
}

function AppLayout({ children, location }: IAppLayout) {
  const [fold, setFold] = useState(false);
  const dispatch = useDispatch();
  const { email, imageUrl } = useSelector((state: RootState) => state.auth);
  const [account] = email.split('@odoctor.co.kr');

  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_KEY as string,
    onLogoutSuccess: () => console.log('onLogoutSuccess'),
  });

  useEffect(() => {
    const { email, imageUrl, expires_at, expires_in, id_token } = AuthHelper.getUser();
    if (email !== '') {
      dispatch(setAuth({ email, imageUrl, expires_at, expires_in, id_token }));
    }
  }, [dispatch]);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <a target="_blank" rel="noopener noreferrer" href="https://partner.odoctor.co.kr/">
          파트너페이지 이동
        </a>
      </Menu.Item>
      <Menu.Item disabled key="2">
        <span>{process.env.REACT_APP_NODE_ENV}</span>
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          signOut();
          dispatch(setLogout());
        }}
      >
        <span>로그아웃</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        className="site-layout-background"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 3rem',
          background: '#fff',
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}
      >
        <span style={{ width: '10rem' }}>
          <img src={Logo} alt="오닥터 로고" style={{ width: '100%' }} />
        </span>
        <Dropdown overlay={menu}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <span style={{ width: '5rem', height: '5rem', display: 'flex' }}>
              <img src={imageUrl} style={{ width: '100%' }} alt="프로필 이미지" />
            </span>
            <span style={{ marginLeft: '1rem', fontSize: '1.8rem' }}>{email}</span>
          </div>
        </Dropdown>
      </Header>
      <Layout>
        <Sider collapsible collapsed={fold} onCollapse={setFold} theme="light">
          <Menu
            theme="light"
            defaultSelectedKeys={[location.pathname]}
            defaultOpenKeys={routes.getDefaultOpenKeys(location.pathname)}
            mode="inline"
          >
            {limitAccounts.includes(account)
              ? limitAccountRouteData.map((route) => {
                  if (route.component) {
                    return (
                      <Menu.Item
                        key={route.path}
                        style={route.path === '/' ? { fontSize: '4rem' } : {}}
                      >
                        <Link to={route.path}>{route.label}</Link>
                      </Menu.Item>
                    );
                  }

                  return (
                    <SubMenu key={route.label} title={route.label}>
                      {route.child.map((child) => (
                        <Menu.Item key={child.path}>
                          <Link to={child.path}>{child.label}</Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  );
                })
              : routeData.map((route) => {
                  if (route.component) {
                    return (
                      <Menu.Item
                        key={route.path}
                        style={route.path === '/' ? { fontSize: '4rem' } : {}}
                      >
                        <Link to={route.path}>{route.label}</Link>
                      </Menu.Item>
                    );
                  }

                  return (
                    <SubMenu key={route.label} title={route.label}>
                      {route.child.map((child) => (
                        <Menu.Item key={child.path}>
                          <Link to={child.path}>{child.label}</Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  );
                })}
          </Menu>
        </Sider>
        <Layout className="site-layout" style={{ overflow: 'auto', height: 'calc(100vh - 64px)' }}>
          <main style={{ margin: '1.6rem 0 ' }}>{children}</main>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default withRouter(AppLayout);
