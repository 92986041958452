import Axios, { AxiosResponse } from 'axios';
import { takeLatest, all, fork, select } from 'redux-saga/effects';
import { print } from 'graphql';

import { RootState } from 'modules/rootState';
import { PostCatListState } from './managePostCat.models';
import { actionCreatePostCatList, CREATE_POST_CAT_LIST } from './managePostCat.actions';
import { GetEvents } from 'graphql/event.query';
import { getSignedUrl, removeKey, replaceEndPoint } from 'utils/createEventHelper';

const ODOCTOR_APP_URL = process.env.REACT_APP_ODOCTOR_APP_URL || '';
const POST_IMAGE_UPLOAD_PATH = process.env.REACT_APP_POST_IMAGE_USER_FOLDER;

function* workerCreatePostCatList(action: ReturnType<typeof actionCreatePostCatList>) {
  // const blacklist: EventBlacklistState = yield select((state: RootState) => state.postCatList);

  try {
    const { callback, list } = action.payload;
    console.log(list);
    console.log(list);
    console.log(list);
    console.log(list);
    console.log(list);

    yield callback({
      variables: {
        input: list,
      },
    });
  } catch (error) {
    console.error('workerCreateBlacklist', error);
  }
}

// function* workerGetEvents() {
//   const response: AxiosResponse<IResGetEvents> = yield Axios.post(ODOCTOR_APP_URL, {
//     query: print(GetEvents),
//     variables: {
//       pagination: {
//         perPage: 1000,
//       },
//     },
//   });

//   console.log(response.data.data.events.data);
// }

// function* watchGetEvents() {
//   yield takeLatest(actionGetEvents.request, workerGetEvents);
// }

function* watchCreateBlacklist() {
  yield takeLatest(CREATE_POST_CAT_LIST, workerCreatePostCatList);
}

export default function* managePostCatSaga() {
  yield all([fork(watchCreateBlacklist) /* fork(watchGetEvents) */]);
}
