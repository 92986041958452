import React from 'react';
import dayjs from 'dayjs';
import { QueryLazyOptions } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';

import { DatePicker } from 'components/CustomPicker';
import { RootState } from 'modules/rootState';
import { setReservationFilter, resetReservationFilter } from 'modules/filter/filter.actions';
import useFilterModel from 'hooks/useFilterModel';
import { Path, Status } from 'odoc-interface-module/common/status';
import { EventReservations } from 'odoc-interface-module/odoc-manager-front/eventReservations';

const { Option } = Select;

interface IReservationFilter {
  getEventReservations: (options?: QueryLazyOptions<EventReservations.Request> | undefined) => void;
  getEventReservationsToExcel: (
    options?: QueryLazyOptions<EventReservations.Request> | undefined
  ) => void;
}

export default function ReservationFilter({
  getEventReservations,
  getEventReservationsToExcel,
}: IReservationFilter) {
  const dispatch = useDispatch();
  const { status, path, hospitalName, userName, userPhonenum, range } = useSelector(
    (state: RootState) => state.filter.reservation
  );
  const { reservationFilterModel } = useFilterModel();

  const onSubmit = () => {
    getEventReservations({ variables: { filter: reservationFilterModel } });
  };

  return (
    <div style={{ display: 'flex', margin: '1rem 0 2rem 0' }}>
      <Select
        value={status}
        onChange={(value) => dispatch(setReservationFilter({ key: 'status', value }))}
        showArrow={false}
        listHeight={369}
        style={{ width: 120, marginRight: '1rem' }}
      >
        {Object.entries(Status).map(([key, value]) => (
          <Option value={key} key={key}>
            {value}
          </Option>
        ))}
      </Select>
      <DatePicker.RangePicker
        locale={locale}
        value={range}
        onChange={(value) => {
          const calEndDay = value?.map((v, idx) => {
            if (idx === 1) {
              return v?.endOf('day');
            }

            return v?.startOf('day');
          });

          dispatch(setReservationFilter({ key: 'range', value: calEndDay || [null, null] }));
        }}
        ranges={{
          오늘: [dayjs(), dayjs()],
          이번주: [dayjs().startOf('week'), dayjs().endOf('week')],
          이번달: [dayjs().startOf('month'), dayjs().endOf('month')],
          '3개월': [dayjs().subtract(3, 'month'), dayjs()],
          '6개월': [dayjs().subtract(6, 'month'), dayjs()],
          '1년': [dayjs().subtract(12, 'month'), dayjs()],
        }}
      />
      <Input
        placeholder="병원이름"
        value={hospitalName}
        onChange={(e) =>
          dispatch(setReservationFilter({ key: 'hospitalName', value: e.target.value }))
        }
        onKeyUp={(e) => e.key === 'Enter' && onSubmit()}
        style={{ width: 200, margin: '0 1rem' }}
      />
      <Input
        placeholder="유저"
        value={userName + userPhonenum}
        onChange={(e) => {
          const regx = /^(0?1?[0|1|6|7|8|9])?-?([0-9]{3,4})-?([0-9]{0,4})?$/g;
          const isPhonenum = regx.test(e.target.value);
          const obj = {
            key: isPhonenum ? 'userPhonenum' : 'userName',
            value: e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim, ''),
          };

          dispatch(setReservationFilter(obj));
        }}
        onKeyUp={(e) => e.key === 'Enter' && onSubmit()}
        style={{ width: 200 }}
      />
      <Select
        value={path}
        onChange={(currentValue) =>
          dispatch(setReservationFilter({ key: 'path', value: currentValue }))
        }
        style={{ margin: '0 1rem' }}
      >
        {Object.entries(Path).map(([key, value]) => (
          <Option value={key} key={key}>
            {value}
          </Option>
        ))}
      </Select>
      <Button
        style={{ margin: '0 0.5rem 0 2rem' }}
        onClick={() => {
          dispatch(resetReservationFilter());
          getEventReservations();
        }}
      >
        검색 초기화
      </Button>
      <Button
        style={{ margin: '0 0.5rem 0 2rem' }}
        onClick={() => {
          getEventReservationsToExcel({
            variables: {
              filter: reservationFilterModel,
              pagination: {
                perPage: 100000,
                currentPage: 1,
              },
            },
          });
        }}
      >
        엑셀 내보내기
      </Button>
      <Button type="primary" onClick={onSubmit}>
        검색
      </Button>
    </div>
  );
}
