import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
  PageHeader,
  Select,
  Input,
  Button,
  Radio,
  Switch,
  Table,
  Tag,
  Transfer,
  Modal,
} from 'antd';
import XLSX from 'xlsx';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import difference from 'lodash/difference';
import type { TransferDirection, TransferItem, TransferProps } from 'antd/es/transfer';
import type { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import { UserAgeGroup } from 'odoc-interface-module/common/status';

import LabelRow from 'components/CreateEvent/LabelRow';
import useApplyEvent from 'hooks/useApplyEvent';

const { Option } = Select;

dayjs.extend(isSameOrAfter);
function ImportExcel({ onSuccess }) {
  const [rawData, setRawData] = useState<any>([]);
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));

  const parseDataByDate = useCallback(
    (data) => {
      return data.filter(({ date }) => dayjs(date).isSameOrAfter(startDate));
    },
    [startDate]
  );

  const reload = () => {
    const r = parseDataByDate(rawData);
    onSuccess(r);
  };

  useEffect(() => {
    if (rawData.length > 0) {
      const r = parseDataByDate(rawData);
      onSuccess(r);
    } else {
      // alert('불러올 데이터가 없..ㅠㅠ');
    }
  }, [onSuccess, parseDataByDate, rawData]);

  const onClickBtnRegisterDelivery = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileObj = event.target.files[0];
      // 이름 : username, userGender, userPhonenum, userAgeGroup, memo : 나이:61세/몸무게:65kg/키:162cm/감량희망:5kg

      try {
        if (!fileObj) return;

        const buffer = await fileObj.arrayBuffer();

        const workbook = XLSX.read(buffer, { type: 'buffer', bookVBA: true });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        const json = XLSX.utils.sheet_to_json<any>(worksheet, {
          header: [
            '1',
            '2',
            '3',
            'date',
            '4',
            '5',
            'username',
            'userPhonenum',
            'age',
            'weight',
            'height',
            'hope',
          ],
          raw: false,
        });

        const data = json?.slice(1).map((o, i) => {
          const { username, userPhonenum, age = '', weight = '', height = '', hope = '', date } = o;

          return {
            key: i + 1,
            username,
            userPhonenum,
            userAgeGroup: [
              'underTen',
              'teenager',
              'twenties',
              'thirties',
              'forties',
              'fifties',
              'overSixties',
            ][Math.floor(age.replace(/[^0-9.]/g, '') / 10)],
            date: dayjs(date).format(),
            userGender: 'male',
            memo:
              `나이:${age.replace(/[^0-9.]/g, '')}세` +
              `${weight && '/몸무게:' + weight.replace(/[^0-9.]/g, '') + '키로'}` +
              `${height && '/키:' + height.replace(/[^0-9.]/g, '') + 'cm'}` +
              `${hope && '/감량희망:' + hope.replace(/[^0-9.~-]/g, '') + 'kg'}`,
          };
        });
        // .filter(({ date }) => dayjs(date, 'YYYY-MM-DD HH:mm').format() > startDate);

        setRawData(data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <LabelRow label="기준 날짜">
        <Input
          placeholder={`default: ${dayjs().format('YYYY-MM-DD 00:00')}`}
          onChange={({ target: { value } }) => {
            setStartDate(dayjs(value).format('YYYY-MM-DD HH:mm'));
          }}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow label="엑셀 파일 불러오기">
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Button style={{ width: '8rem', padding: 0, position: 'relative' }}>
            <ButtonLabel>
              엑셀첨부
              <input
                type="file"
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={onClickBtnRegisterDelivery}
              />
            </ButtonLabel>
          </Button>
          <Button onClick={reload} style={{ width: '8rem', padding: 0, position: 'relative' }}>
            리로드
          </Button>
        </div>
      </LabelRow>
    </>
  );
}

const ButtonLabel = styled.label`
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    display: none;
  }
`;

//

interface RecordType {
  key: string;
  title?: string;
  description?: string;
  disabled: boolean;
  tag?: string;

  date: string;
  memo: string;
  userAgeGroup: string;
  userGender: string;
  userPhonenum: string;
  username: string;
}

interface DataType {
  key: string;
  date: string;
  memo: string;
  userAgeGroup: string;
  userGender: string;
  userPhonenum: string;
  username: string;
  disabled: boolean;

  title?: string;
  description?: string;
  tag?: string;
}

interface TableTransferProps extends TransferProps<TransferItem> {
  dataSource: DataType[];
  leftColumns: any;
  rightColumns: any;
}

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }: TableTransferProps) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;
      // const columns = leftColumns;

      const rowSelection: TableRowSelection<TransferItem> = {
        getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys as string[], selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key as string, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? 'none' : undefined }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key as string, !listSelectedKeys.includes(key as string));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const mockTags = ['cat', 'dog', 'bird'];

const mockData: RecordType[] = Array.from({ length: 20 }).map((_, i) => ({
  key: i.toString(),
  date: '2022-10-28 10:39:58',
  memo: '나이:54세/몸무게:67키로/키:156cm/감량희망:10kg',
  userAgeGroup: 'fifties',
  userGender: 'mail',
  userPhonenum: '01085491608',
  username: '이종현',
  disabled: false,

  // title: `content${i + 1}`,
  // description: `description of content${i + 1}`,
  // tag: mockTags[i % 3],
}));

const rightTableColumns: ColumnsType<DataType> = [
  {
    dataIndex: 'username',
    title: '이름',
  },
  {
    dataIndex: 'userPhonenum',
    title: '전화번호',
  },
  {
    dataIndex: 'memo',
    title: '메모',
  },
];

export default function MultiApply() {
  const { confirm } = Modal;
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [excelDate, setExcelData] = useState<DataType[]>([]);
  const [eventId, setEventId] = useState<string>('');
  const [isCreatedAt, setIsCreatedAt] = useState<boolean>(true);

  const { events, state, dispatch, bulkApplyEvent } = useApplyEvent();

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
  };

  const uploadBulk = async (data: DataType[]) => {
    // data
    bulkApplyEvent(data, eventId, isCreatedAt);
  };

  const GenderTagColor = {
    male: '#108ee9',
    female: '#f50',
  };

  const leftTableColumns: ColumnsType<DataType> = [
    {
      dataIndex: 'date',
      title: '신청일',
      render: (a) => dayjs(a).format('YY/MM/DD HH:mm'),
    },
    {
      dataIndex: 'username',
      title: '이름',
    },
    {
      dataIndex: 'userGender',
      title: '성별',
      render: (value, d) => (
        <Tag
          color={GenderTagColor[d.userGender]}
          onClick={() => {
            const newData: DataType[] = excelDate.map((o: DataType) => {
              if (d.key === o.key)
                return {
                  ...o,
                  userGender: o.userGender === 'male' ? 'female' : 'male',
                };

              return o;
            });
            setExcelData(newData);
          }}
        >
          {value.slice(0, 1)}
        </Tag>
      ),
    },
    {
      dataIndex: 'userPhonenum',
      title: '전화번호',
    },
    {
      dataIndex: 'userAgeGroup',
      title: '연령대',
      render: (a) => <Tag>{UserAgeGroup[a]}</Tag>,
    },
  ];

  return (
    <>
      <div>
        <p>사용법</p>
        <p>
          1: 이벤트 아이디를 먼저 지정
          <br />
          2: 엑셀을 불러올때 시작날짜 지정 (기본값: 오늘 0시)
          <br />
          2-1: 입력 날짜 형식(YYYY-MM-DD HH:mm): 2020-10-10, 2020=10-10 12:00, 2020/10/10 19 등등 3:
          <br />
          3: 왼쪽에 불러온 목록 중, 원하는 목록을 선택 후, 오른쪽으로 이동
          <br />
          4: 아래 신청 버튼 클릭!
          <br />
          5: 신청 후 alert창 확인! 예) 10개중 10개 성공!
          <br />* 조건 변겅시(날짜) [리로드] 버튼 사용
        </p>
      </div>
      <LabelRow label="이벤트 아이디" isRequired>
        <Select<string>
          showSearch
          optionFilterProp="children"
          filterOption
          placeholder="이벤트명으로 검색 가능합니다"
          onChange={(value: string) => setEventId(value)}
          style={{ width: '40rem' }}
        >
          {events.map(({ id, name }) => (
            <Option value={id} key={id}>
              {`(${id}) - ${name.replaceAll('$n', '')}`}
            </Option>
          ))}
        </Select>
      </LabelRow>
      <ImportExcel onSuccess={setExcelData} />
      <LabelRow label="엑셀 신청일 사용">
        <Switch
          checkedChildren="사용"
          unCheckedChildren="미사용"
          defaultChecked
          checked={isCreatedAt}
          onChange={(checked) => setIsCreatedAt(checked)}
        />
      </LabelRow>

      <TableTransfer
        dataSource={excelDate}
        targetKeys={targetKeys}
        onChange={onChange}
        filterOption={(inputValue, item) =>
          item.title!.indexOf(inputValue) !== -1 || item.tag.indexOf(inputValue) !== -1
        }
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
      />
      <div style={{ marginTop: '2rem' }}>
        <Button
          disabled={excelDate.length === 0}
          type="primary"
          style={{ width: '40rem' }}
          onClick={() => {
            confirm({
              title: `${targetKeys.length}개 신청 콜?`,
              onOk() {
                // console.info('upload:', targetKeys);
                const a = excelDate.filter(
                  (e: DataType) => !!targetKeys.find((key) => key === e.key)
                );
                uploadBulk(a);
              },
            });
          }}
        >
          {excelDate.length === 0
            ? '엑셀 파일을 첨부 해주세요'
            : targetKeys.length === 0
            ? '신청할 목록을 확인하세요'
            : `${targetKeys.length}개 신청하기`}
        </Button>
      </div>
    </>
  );
}

// #components-transfer-demo-table-transfer .ant-table td {
//   background: transparent;
// }
