import { ApolloClient, HttpLink, InMemoryCache, from, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { notification } from 'antd';

import AuthHelper from 'utils/AuthHelper';
import { ResponseMessage } from 'utils/ResponseMessage';
import { history } from 'stores';

const URL = process.env.REACT_APP_BASE_URL;
const httpLink = new HttpLink({ uri: URL });

const authLink = setContext((_, { headers }) => {
  const { id_token } = AuthHelper.getUser();

  if (AuthHelper.isExpired()) {
    AuthHelper.resetToken();

    notification.error({
      message: '👀',
      description: '미네랄이 부족합니다.',
      placement: 'bottomRight',
      duration: 5,
    });

    history.push('/login');
    return;
  }

  return {
    headers: {
      ...headers,
      'X-GOOGLE-OAUTH-TICKET': id_token,
    },
  };
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.errors) {
      return response;
    }

    if (ResponseMessage[operation.operationName]) {
      notification.success({
        message: ResponseMessage[operation.operationName].message,
        description: ResponseMessage[operation.operationName].description,
        duration: 5,
        placement: 'bottomRight',
      });
    }

    return response;
  });
});

const client = new ApolloClient({
  link: from([authLink, afterwareLink, httpLink]),
  cache: new InMemoryCache({
    // addTypename: false,
  }),
});

export default client;
