import { IEventPoster, IEventState } from 'modules/event/event.models';

interface IEventValidation extends IEventState {
  eventPosters: IEventPoster[];
}

export function eventValidation(props: IEventValidation) {
  const {
    id,
    hospitalId,
    name,
    categoryIds,
    therapyName,
    range: { start, end },
    eventPosters,
  } = props;

  return (
    id === null ||
    id < 1 ||
    hospitalId === null ||
    hospitalId < 1 ||
    name.trim() === '' ||
    categoryIds.length === 0 ||
    therapyName.trim() === '' ||
    start === '' ||
    end === '' ||
    eventPosters.length === 0
  );
}
