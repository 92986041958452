import React, { ChangeEvent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface IInputFileSingle {
  setImage?: any;
  onChange(file: File, preview: any): void;
}

export default function InputFileSingle({ setImage, onChange }: IInputFileSingle) {
  const [preview, setPreview] = useState<FileReader['result']>();

  const validateImage = (file: File): Promise<boolean> => {
    const image = new Image();
    image.src = window.URL.createObjectURL(file);

    return new Promise((resolve) => {
      image.onload = () => {
        return resolve(image.width < 420 && image.height < 420);
      };
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      const isCorrectImage = await validateImage(file);
      if (!isCorrectImage || Math.ceil(file.size / 1000) > 100) {
        message.error('이미지 사이즈(420 x 420)와 용량(100kb미만)을 확인해주세요');
        return;
      }

      setPreview(reader.result);
      onChange(file, reader.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setPreview(setImage);
  }, [setImage]);

  return (
    <Container>
      {preview ? (
        <Label htmlFor="input-file-single">
          <PreviewImage src={preview as string} alt="preview" />
        </Label>
      ) : (
        <Label htmlFor="input-file-single">
          <PlusOutlined />
        </Label>
      )}
      <Input
        type="file"
        onChange={handleChange}
        id="input-file-single"
        accept="image/jpg, image/png, image/jpeg, image/webp"
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  border: 1px solid #d9d9d9;
  cursor: pointer;
`;

const Input = styled.input`
  display: none;
`;

const PreviewImage = styled.img`
  width: 10rem;
  height: 10rem;
  object-fit: cover;
`;
