import Axios, { AxiosResponse } from 'axios';
import { takeLatest, all, fork, select } from 'redux-saga/effects';
import { print } from 'graphql';

import { RootState } from 'modules/rootState';
import { PostState, IResSignedUrl } from './post.models';
import {
  actionCreateFakePost,
  REQUEST_CREATE_FAKE_POST,
  actionUpdatePost,
  REQUEST_UPDATE_POST,
} from './post.actions';
import { GetEvents } from 'graphql/event.query';
import { getSignedUrl, removeKey, replaceEndPoint } from 'utils/createEventHelper';

const ODOCTOR_APP_URL = process.env.REACT_APP_ODOCTOR_APP_URL || '';
const POST_IMAGE_UPLOAD_PATH = process.env.REACT_APP_POST_IMAGE_USER_FOLDER;

function* workerRequestCreateFakePost(action: ReturnType<typeof actionCreateFakePost>) {
  const fakePost: any = yield select((state: RootState) => state.fakePost);

  try {
    const { callback, post, postImages } = action.payload;
    const files = postImages.map((f) => f.file);
    const signedUrls = files.map((file, idx) => {
      return getSignedUrl(file, idx === 0, POST_IMAGE_UPLOAD_PATH);
    });
    const response: AxiosResponse<{ data: IResSignedUrl }>[] = yield Promise.all(signedUrls);

    const s3Urls = response.map((res, idx) => {
      Axios.put(res.data.data.signS3.signedRequest, files[idx]);
      return res.data.data.signS3.url;
    });

    const regx = new RegExp(`${POST_IMAGE_UPLOAD_PATH}.*$`);
    const { images, ...fpost } = post;

    yield callback({
      variables: {
        input: {
          ...fpost,
          imageUrls: s3Urls.flatMap((url: string) => `/${regx.exec(url)}`),
        },
      },
    });
  } catch (error) {
    console.error('workerRequestUpdateEvent', error);
  }
}

function* workerRequestUpdatePost(action: ReturnType<typeof actionUpdatePost>) {
  console.log('action.payload', action.payload);

  try {
    const { callback, post, postImages } = action.payload;
    // const files = postImages.map((f) => f.file);
    // const signedUrls = files.map((file, idx) => {
    //   return getSignedUrl(file, idx === 0, POST_IMAGE_UPLOAD_PATH);
    // });
    // const response: AxiosResponse<{ data: IResSignedUrl }>[] = yield Promise.all(signedUrls);

    // const s3Urls = response.map((res, idx) => {
    //   Axios.put(res.data.data.signS3.signedRequest, files[idx]);
    //   return res.data.data.signS3.url;
    // });

    // const regx = new RegExp(`${POST_IMAGE_UPLOAD_PATH}.*$`);
    // const { images, ...fpost } = post;

    yield callback({
      variables: {
        input: {
          ...post,
          // imageUrls: s3Urls.flatMap((url: string) => `/${regx.exec(url)}`),
        },
      },
    });
  } catch (error) {
    console.error('workerRequestUpdateEvent', error);
  }
}

// function* workerGetEvents() {
//   const response: AxiosResponse<IResGetEvents> = yield Axios.post(ODOCTOR_APP_URL, {
//     query: print(GetEvents),
//     variables: {
//       pagination: {
//         perPage: 1000,
//       },
//     },
//   });

//   console.log(response.data.data.events.data);
// }

function* watchRequestCreateFakePost() {
  yield takeLatest(REQUEST_CREATE_FAKE_POST, workerRequestCreateFakePost);
}

function* watchGetEvents() {
  yield takeLatest(REQUEST_UPDATE_POST, workerRequestUpdatePost);
}

export default function* postSaga() {
  yield all([fork(watchRequestCreateFakePost), fork(watchGetEvents) /* fork(watchGetEvents) */]);
}
