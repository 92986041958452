import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';

import { CreateEvent, CreateSignedUrl, GetEventCategories } from 'graphql/event.query';
import { CreateHospital } from 'graphql/hospital.query';
import { ResCreateHospital, ReqCreateHospital } from 'modules/hospital/hospital.models';
import { resetEvent } from 'modules/event/event.actions';

import { HospitalPayload } from 'modules/hospital/hospital.models';

interface IResCreateHospital {
  ok: boolean;
  error: {
    path: string;
    message: string;
  };
}

export interface IReqCreateHospital {
  input: HospitalPayload;
}

export default function useCreateHospital(resetAfterCreateEvent: () => void) {
  const [createHospital, { loading }] = useMutation<ResCreateHospital, ReqCreateHospital>(
    CreateHospital,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
        // dispatch(resetEvent());
        // resetAfterCreateEvent();
      },
      onError: (error) => {
        // dispatch(resetEvent());
        // resetAfterCreateEvent();

        notification.error({
          message: '망함',
          description: error.message,
          placement: 'bottomRight',
          duration: 5,
        });
      },
    }
  );

  return { createHospital, loading };
}
