import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PageHeader, Table, Popconfirm, Tag } from 'antd';
import { useMutation } from '@apollo/client';

import { DeActivateUser, GetUsers } from 'graphql/user.query';
import UserFilter from 'components/Filter/UserFilter';
import useFilterModel from 'hooks/useFilterModel';
import useUser from 'hooks/userUser';

export default function UserList() {
  const { userFilterModel } = useFilterModel();
  const { getUsers, list, pagination } = useUser();

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const [deActivateUser] = useMutation(DeActivateUser, {
    refetchQueries: [
      {
        query: GetUsers,
        variables: {
          pagination: {
            currentPage: pagination.currentPage,
          },
        },
      },
    ],
  });

  const columns = [
    {
      title: '아이디',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '핸드폰번호',
      dataIndex: 'phonenum',
      key: 'phonenum',
    },
    {
      title: '활성상태(탈퇴여부)',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive, record) => (
        <div>
          {isActive ? (
            <div>
              <Popconfirm
                title="레알 비활성화?"
                onConfirm={(e) =>
                  deActivateUser({
                    variables: {
                      id: record.id,
                    },
                  })
                }
                okText="비활성 ㄱㄱ"
                cancelText="ㄴㄴ"
              >
                <Tag color="#2db7f5" style={{ cursor: 'pointer' }}>
                  활성
                </Tag>
              </Popconfirm>
            </div>
          ) : (
            <Tag color="#f50">비활성</Tag>
          )}
        </div>
      ),
    },
  ];

  return (
    <Container>
      <PageHeader title="유저 목록" subTitle="회원탈퇴" />
      <SearchRow>
        <UserFilter />
      </SearchRow>
      <Table
        columns={columns}
        dataSource={list}
        rowKey={(record) => record.id}
        pagination={{
          current: pagination.currentPage,
          total: pagination.total,
        }}
        onChange={(paging) =>
          getUsers({
            variables: {
              filter: userFilterModel,
              pagination: {
                perPage: paging.pageSize,
                currentPage: paging.current,
              },
            },
          })
        }
      />
    </Container>
  );
}

const Container = styled.div`
  min-width: 1200px;
  padding: 1.6rem;
  margin: 0 1.6rem;
  background: #fff;
`;

const SearchRow = styled.div`
  margin-bottom: 1rem;
`;

export const DiscountRate = styled.span`
  font-size: 2.4rem;
  line-height: 0.83;
  color: #dd4124;
  font-weight: 600;
`;

export const Price = styled.span<{ single?: boolean }>`
  font-size: 2.4rem;
  color: #dd4326;
  font-weight: 600;
  margin-left: ${({ single }) => !single && '0.6rem'};
`;

export const Origin = styled.span`
  font-size: 1.8rem;
  color: #b1b3b2;
  line-height: 1.11;
  margin-left: 0.6rem;
  text-decoration: line-through;
`;

export const CustomRow = styled.div`
  margin-bottom: 1rem;
`;

export const DnDWrapper = styled.div`
  padding: 1rem;
`;
