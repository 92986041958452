import { notification } from 'antd';
import { takeLatest, all, fork, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import Http from '@http';
import {
  actionGetDeliveries,
  actionGetInvoices,
  actionGetPickups,
  actionPutInvoices,
  actionGetPayments,
  actionGetTreatments,
  actionExportExcel,
} from 'modules/philing/philing.actions';
import { IDelivery } from 'odoc-interface-module/odoc-manager-front/delivery';
import { IInvoice } from 'odoc-interface-module/odoc-manager-front/invoice';
import { IPayment } from 'odoc-interface-module/odoc-manager-front/payment';
import { IPaymentByTreatment } from 'odoc-interface-module/odoc-manager-front/paymentByTreatment';

function* workerGetDeliveries() {
  try {
    const response: AxiosResponse<IDelivery.Response> = yield Http.instance.get('/deliveries');
    yield put(actionGetDeliveries.success(response.data));
  } catch (error) {
    console.error(error);
  }
}

function* workerPutInvoices(action: ReturnType<typeof actionPutInvoices.request>) {
  try {
    const response: AxiosResponse<IInvoice.Response> = yield Http.instance.put(
      '/invoices',
      action.payload
    );
    if (response.status === 201) {
      notification.success({
        message: '송장등록',
        description: '🎉 송장등록 완료',
        placement: 'bottomRight',
        duration: 3,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* workerGetPickups() {
  try {
    const response = yield Http.instance.get('/deliveries/pickups?page=1');
    console.log(response);
  } catch (error) {
    console.error(error);
  }
}

function* workerGetInvoices() {
  try {
    const response = yield Http.instance.get('/deliveries/invoices?page=1');
    console.log(response);
  } catch (error) {
    console.error(error);
  }
}

function* workerGetPayments(action) {
  const filter = new URLSearchParams({ limit: 100, ...action.payload }).toString();

  try {
    const response: AxiosResponse<IPayment.Response> = yield Http.instance.get(
      `/order_lines?${filter}`
    );
    yield put(actionGetPayments.success(response.data));
  } catch (error) {
    console.error(error);
  }
}

function* workerGetTreatments(action) {
  const filter = new URLSearchParams({ limit: 100, ...action.payload }).toString();

  try {
    const response: AxiosResponse<IPaymentByTreatment.Response> = yield Http.instance.get(
      `/treatments?${filter}`
    );
    yield put(actionGetTreatments.success(response.data));
  } catch (error) {
    console.error(error);
  }
}

function* workerExportExcel(action) {
  const api = {
    PAYMENT: '/order_lines?',
    TREATMENT: '/treatments?',
  };
  const { type, filter, callback } = action.payload;

  const filterQuery = new URLSearchParams({ limit: 99999, ...filter }).toString();

  try {
    const response: AxiosResponse = yield Http.instance.get(`${api[type]}${filterQuery}`);
    callback({ type, data: response.data, filterQuery });
    console.log(response);
  } catch (error) {
    console.error(error);
  }
}

function* watchGetDeliveries() {
  yield takeLatest(actionGetDeliveries.request, workerGetDeliveries);
}

function* watchPutInvoices() {
  yield takeLatest(actionPutInvoices.request, workerPutInvoices);
}

function* watchGetPickups() {
  yield takeLatest(actionGetPickups.request, workerGetPickups);
}

function* watchGetInvoices() {
  yield takeLatest(actionGetInvoices.request, workerGetInvoices);
}

function* watchGetPayments() {
  yield takeLatest(actionGetPayments.request, workerGetPayments);
}

function* watchGetTreatments() {
  yield takeLatest(actionGetTreatments.request, workerGetTreatments);
}

function* watchExportExcel() {
  yield takeLatest(actionExportExcel.request, workerExportExcel);
}

export default function* philingSaga() {
  yield all([
    fork(watchGetDeliveries),
    fork(watchPutInvoices),
    fork(watchGetPickups),
    fork(watchGetInvoices),
    fork(watchGetPayments),
    fork(watchGetTreatments),
    fork(watchExportExcel),
  ]);
}
