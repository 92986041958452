import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import {
  SET_RESERVATION_FILTER,
  RESET_RESERVATION_FILTER,
  SET_POINT_FILTER,
  RESET_POINT_FILTER,
  SET_CHARGE_FILTER,
  RESET_CHARGE_FILTER,
  RESET_DELIVERY_FILTER,
  SET_DELIVERY_FILTER,
  SET_PAYMENT_FILTER,
  RESET_PAYMENT_FILTER,
  SET_PAYMENT_TREAT_FILTER,
  RESET_PAYMENT_TREAT_FILTER,
  SET_USER_FILTER,
} from './filter.actions';
import { IFilterState } from './filter.models';

const initialReservation = {
  status: 'A01' as const,
  range: [null, null] as any,
  hospitalName: '',
  userName: '',
  path: 'All' as const,
  userPhonenum: '',
};

const initialPoint = {
  eventType: 'All' as const,
  range: [null, null] as any,
  userId: '',
  itemName: '',
};

const initialCharge = {
  action: 'All' as const,
  range: [null, null] as any,
  hospitalName: '',
  description: '',
};

const initialDelivery = {
  status: 0 as const,
  range: [null, null] as any,
  pharmacyName: '',
  deliveryNumber: '',
  name: '',
};

const initialPayment = {
  productId: '0',
  paid: '2',
  range: [null, null] as any,
  clientName: '',
  userName: '',
};

const initialPaymentTreat = {
  status: 0 as const,
  range: [null, null] as any,
  userName: '',
  hospitalName: '',
  pharmacyName: '',
};

const initialUser = {
  id: '',
  name: '',
  email: '',
  phonenum: '',
};

const initialState = {
  reservation: initialReservation,
  point: initialPoint,
  charge: initialCharge,
  delivery: initialDelivery,
  payment: initialPayment,
  paymentTreat: initialPaymentTreat,
  user: initialUser,
};

const filterReducer = createReducer<IFilterState, RootAction>(initialState, {
  [SET_RESERVATION_FILTER]: (state, action) => ({
    ...state,
    reservation: {
      ...state.reservation,
      [action.payload.key]: action.payload.value,
      /// userName || userPhonenum
      ...(action.payload.key === 'userName' && { userPhonenum: '' }),
      ...(action.payload.key === 'userPhonenum' && { userName: '' }),
    },
  }),

  [SET_POINT_FILTER]: (state, action) => ({
    ...state,
    point: {
      ...state.point,
      [action.payload.key]: action.payload.value,
    },
  }),

  [SET_CHARGE_FILTER]: (state, action) => ({
    ...state,
    charge: {
      ...state.charge,
      [action.payload.key]: action.payload.value,
    },
  }),

  [SET_DELIVERY_FILTER]: (state, action) => ({
    ...state,
    delivery: {
      ...state.delivery,
      [action.payload.key]: action.payload.value,
    },
  }),

  [SET_PAYMENT_FILTER]: (state, action) => ({
    ...state,
    payment: {
      ...state.payment,
      [action.payload.key]: action.payload.value,
    },
  }),

  [SET_PAYMENT_TREAT_FILTER]: (state, action) => ({
    ...state,
    paymentTreat: {
      ...state.paymentTreat,
      [action.payload.key]: action.payload.value,
    },
  }),

  [SET_USER_FILTER]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      [action.payload.key]: action.payload.value,
    },
  }),

  [RESET_CHARGE_FILTER]: (state) => ({ ...state, charge: initialCharge }),

  [RESET_RESERVATION_FILTER]: (state) => ({ ...state, reservation: initialReservation }),

  [RESET_POINT_FILTER]: (state) => ({ ...state, point: initialPoint }),

  [RESET_DELIVERY_FILTER]: (state) => ({ ...state, delivery: initialDelivery }),

  [RESET_PAYMENT_FILTER]: (state) => ({ ...state, payment: initialPayment }),

  [RESET_PAYMENT_TREAT_FILTER]: (state) => ({ ...state, paymentTreat: initialPaymentTreat }),
});

export default filterReducer;
