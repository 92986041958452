import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import { RootState } from './rootState';
import authReducer from './auth/auth.reducer';
import filterReducer from './filter/filter.reducer';
import uiReducer from './ui/ui.reducers';
import pointHistoryReducer from './pointHistory/pointHistory.reducer';
import chargeHistoryReducer from './chargeHistory/chargeHistory.reducer';
import eventReducer from './event/event.reducer';
import philingReducer from 'modules/philing/philing.reducer';
import userReducer from './user/user.reducer';
import hospitalReducer from './hospital/hospital.reducer';
import fakePostReducer from './post/post.reducer';
import blacklistReducer from './eventBlacklist/blacklist.reducer';
import managePostReducer from './managePost/managePost.reducer';
import managePostCatReducer from './managePostCategory/managePostCat.reducer';
import manageTagReducer from './manageTag/manageTag.reducer';

const createRootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    auth: authReducer,
    filter: filterReducer,
    ui: uiReducer,
    pointHistory: pointHistoryReducer,
    chargeHistory: chargeHistoryReducer,
    event: eventReducer,
    philing: philingReducer,
    user: userReducer,
    hospital: hospitalReducer,
    fakePost: fakePostReducer,
    blacklist: blacklistReducer,
    postList: managePostReducer,
    postCatList: managePostCatReducer,
    tagList: manageTagReducer,
  });

export default createRootReducer;
