import gql from 'graphql-tag';

// 유저 포인트 히스토리
export const GetUserPointHistory = gql`
  query UserPointHistory($filter: UserPointHistoryFilter, $pagination: PaginationInput) {
    userPointHistory(filter: $filter, pagination: $pagination) {
      data {
        ... on RewardHistory {
          id
          user {
            id
            name
            phonenum
          }
          pointAmount
          eventType
          rewardItem {
            id
            itemName
          }
          createdAt
        }
        ... on UseHistory {
          id
          user {
            id
            name
            phonenum
          }
          pointAmount
          eventType
          useItem {
            id
            itemName
            itemBrandName
          }
          createdAt
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

// 유저정보
export const GetUser = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      name
      phonenum
      email
      point {
        id
        pointAmount
        updatedAt
      }
    }
  }
`;

// 적립 리스트
export const UserPointRewardItems = gql`
  query UserPointRewardItems {
    userPointRewardItems {
      id
      itemName
      itemPoint
      itemDescription
      itemCode
    }
  }
`;

// 포인트 내역 추가
export const CreateUserPointHistory = gql`
  mutation CreateUserPointHistory($input: UserPointHistoryCreationInput) {
    createUserPointHistory(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;
