import gql from 'graphql-tag';

export const GetReviewCategory = gql`
  query GetReviewCategory {
    reviewEstimationCategories {
      id
      name
      ratingEqualDivision
    }
  }
`;

export const CreateEventReview = gql`
  mutation CreateEventReview($input: EventReviewInput!) {
    createEventReview(input: $input) {
      id
    }
  }
`;
