import { Dispatch, SetStateAction } from 'react';
import { MutationFunctionOptions } from '@apollo/client';
import { createAction, ActionType, createAsyncAction } from 'typesafe-actions';
import {
  IEventPoster,
  IEventState,
  IReqCreateEvent,
  IReqUpdateEvent,
  IResCreateEvent,
  IResUpdateEvent,
} from './event.models';
import { IEvent } from 'hooks/useEventList';

interface IPayload {
  key: keyof IEventState;
  value: any;
}

export const SET_EVENT_BY_KEY = 'SET_EVENT_BY_KEY';
export const setEventByKey = createAction(SET_EVENT_BY_KEY)<IPayload>();

export const RESET_EVENT = 'RESET_EVENT';
export const resetEvent = createAction(RESET_EVENT)();

export const CREATE_EVENT = 'CREATE_EVENT';
export const actionCreateEvent = createAction(CREATE_EVENT)<{
  callback: (
    options?: MutationFunctionOptions<IResCreateEvent, IReqCreateEvent> | undefined
  ) => void;
  eventPosters: IEventPoster[];
  thumbnailImage: File;
}>();

export const REQUEST_UPDATE_EVENT = 'REQUEST_UPDATE_EVENT';
export const actionRequestUpdateEvent = createAction(REQUEST_UPDATE_EVENT)<{
  callback: (
    options?: MutationFunctionOptions<IResUpdateEvent, IReqUpdateEvent> | undefined
  ) => void;
  setShowDetail: Dispatch<SetStateAction<boolean>>;
  eventPosters: IEventPoster[];
  eventThumbnail: any;
  event: IEvent;
}>();

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';
export const actionGetEvents = createAsyncAction(
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE
)<undefined>();

const actions = {
  setEventByKey,
  resetEvent,
  actionCreateEvent,
  actionGetEvents,
};

export type EventActions = ActionType<typeof actions>;
