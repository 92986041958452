import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PageHeader,
  Select,
  InputNumber,
  Input,
  Switch,
  Button,
  Modal,
  message,
  Popover,
} from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import styled from 'styled-components';

import useCreateEvent from 'hooks/useCreateEvent';
import LabelRow from 'components/CreateEvent/LabelRow';
import { DatePicker } from 'components/CustomPicker';
import { RootState } from 'modules/rootState';
import { IEventState } from 'modules/event/event.models';
import { actionCreateEvent, setEventByKey } from 'modules/event/event.actions';
import { IEventPoster } from 'modules/event/event.models';
import InputFileSingle from 'components/Input/InputFileSingle';
import InputFileMultiple from 'components/Input/InputFileMultiple';
import { eventValidation } from 'utils/eventValidation';

import { QuestionCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Option } = Select;

export default function Event() {
  const dispatch = useDispatch();
  const event = useSelector((state: RootState) => state.event);
  const { createEvent, loading, categories } = useCreateEvent(() => {
    setThumbnailImage(undefined);
    setEventPosters([]);
  });
  const [thumbnailImage, setThumbnailImage] = useState<File>();
  const [eventPosters, setEventPosters] = useState<IEventPoster[]>([]);

  function changeDataByKey(key: keyof IEventState, value: any) {
    dispatch(setEventByKey({ key, value }));
  }

  function onSubmit() {
    if (eventValidation({ ...event, eventPosters }) || !thumbnailImage) {
      message.error('필수입력을 확인해주세요');
      return;
    }

    confirm({
      title: '이벤트를 생성하겠습니까?',
      onOk() {
        dispatch(actionCreateEvent({ callback: createEvent, eventPosters, thumbnailImage }));
      },
    });
  }

  return (
    <div style={{ minWidth: '1200px', padding: '1.6rem', margin: '0 1.6rem', background: '#fff' }}>
      <PageHeader title="이벤트 생성" subTitle="alpha version" />
      <LabelRow label="이벤트 아이디 🆔" isRequired>
        <InputNumber
          min={1}
          value={event.id || undefined}
          onChange={(value) => changeDataByKey('id', value)}
        />
      </LabelRow>
      <LabelRow label="병원 아이디" isRequired>
        <InputNumber
          min={1}
          value={event.hospitalId || undefined}
          onChange={(value) => changeDataByKey('hospitalId', value)}
        />
      </LabelRow>
      <LabelRow label="지점명">
        <Input
          placeholder="오닥터 역삼점"
          value={event.hospitalBranchName}
          onChange={({ target: { value } }) => changeDataByKey('hospitalBranchName', value)}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow label="이벤트 카테고리" isRequired>
        <Select<string>
          mode="multiple"
          placeholder="최소 1개 이상 선택, 카테고리명으로 검색"
          filterOption
          optionFilterProp="children"
          allowClear
          onChange={(value) => changeDataByKey('categoryIds', value)}
          value={event.categoryIds as any}
          style={{ width: '40rem' }}
        >
          {categories.map(({ id, name }) => (
            <Option value={id} key={id}>
              {`[${id}] - ${name}`}
            </Option>
          ))}
        </Select>
      </LabelRow>
      <LabelRow label="이벤트 제목" isRequired>
        <TextArea
          placeholder="3줄 넘지않게 작성, 엔터키로 줄바꿈"
          autoSize={{ maxRows: 3 }}
          value={event.name}
          onChange={({ target: { value } }) => changeDataByKey('name', value)}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow label="이벤트 설명">
        <TextArea
          placeholder="이벤트 설명"
          autoSize={{ minRows: 3, maxRows: 5 }}
          value={event.description}
          onChange={({ target: { value } }) => changeDataByKey('description', value)}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow
        label="이벤트 설명2"
        icon={
          <Popover title="픽 상세 시안 위쪽에 노출되는 단락(없어도 무방)">
            <QuestionCircleOutlined style={{ marginLeft: '0.3rem' }} />
          </Popover>
        }
      >
        <TextArea
          placeholder="이벤트 설명2 (픽상세 중앙부분 노출되는 설명)"
          autoSize={{ minRows: 3, maxRows: 5 }}
          value={event.description2}
          onChange={({ target: { value } }) => changeDataByKey('description2', value)}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow label="치료이름" isRequired>
        <Input
          placeholder="ex) 오스템 BA 임플란트"
          value={event.therapyName}
          onChange={({ target: { value } }) => changeDataByKey('therapyName', value)}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow label="이벤트 가격 💰">
        <span style={{ marginRight: '1rem' }}>원가</span>
        <InputNumber
          min={0}
          formatter={(value) => `${value} 원`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          value={event.originalPrice || undefined}
          onChange={(value) => changeDataByKey('originalPrice', value)}
          style={{ width: '15rem' }}
        />
        <span style={{ margin: '0 1rem' }}>할인가</span>
        <InputNumber
          min={0}
          formatter={(value) => `${value} 원`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          value={event.discountedPrice || undefined}
          onChange={(value) => changeDataByKey('discountedPrice', value)}
          style={{ width: '15rem' }}
        />
        <span style={{ margin: '0 1rem' }}>과금액</span>
        <InputNumber
          min={0}
          formatter={(value) => `${value} 원`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          value={event.chargePrice || undefined}
          onChange={(value) => changeDataByKey('chargePrice', value)}
          style={{ width: '15rem' }}
        />
      </LabelRow>
      <LabelRow label="할인률">
        <InputNumber
          min={0}
          max={100}
          formatter={(value) => `${value} %`}
          value={event.discountRate || undefined}
          onChange={(value) => changeDataByKey('discountRate', value)}
        />
      </LabelRow>
      <LabelRow label="가격 보조설명">
        <Input
          placeholder="ex) 1CC 기준, VAT 별도"
          value={event.priceDescription || ''}
          onChange={({ target: { value } }) => changeDataByKey('priceDescription', value)}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow label="이벤트 메인 노출">
        <Switch
          checkedChildren="노출"
          unCheckedChildren="비노출"
          defaultChecked
          checked={event.visible}
          onChange={(checked) => changeDataByKey('visible', checked)}
        />
      </LabelRow>
      <LabelRow label="카카오톡 발송">
        <Switch
          checkedChildren="노출"
          unCheckedChildren="비노출"
          defaultChecked
          checked={event.isEnableKakao}
          onChange={(checked) => changeDataByKey('isEnableKakao', checked)}
        />
      </LabelRow>
      <LabelRow
        label="블랙리스트"
        icon={
          <Popover title="블랙리스트에 등록된 전화번호 이벤트 신청 차단/과금X">
            <QuestionCircleOutlined style={{ marginLeft: '0.3rem' }} />
          </Popover>
        }
      >
        <Switch
          checkedChildren="차단"
          unCheckedChildren="허용"
          defaultChecked
          checked={event.useBlock}
          onChange={(checked) => changeDataByKey('useBlock', checked)}
        />
      </LabelRow>
      <LabelRow
        label="CRM 에만 표시 여부"
        icon={
          <Popover title="사용시 파트너페이지에선 신청자 목록이 표시 되지 않고, crm에만 표시됨">
            <QuestionCircleOutlined style={{ marginLeft: '0.3rem' }} />
          </Popover>
        }
      >
        <Switch
          checkedChildren="사용"
          unCheckedChildren="미사용"
          defaultChecked
          checked={event.crmViewMode === 2}
          onChange={(checked) => changeDataByKey('crmViewMode', checked ? 2 : 1)}
        />
      </LabelRow>
      <LabelRow label="신청자 수">
        <InputNumber
          min={1}
          value={event.applyCount}
          onChange={(value) => changeDataByKey('applyCount', value)}
        />
      </LabelRow>
      <LabelRow label="이벤트 기간" isRequired>
        <RangePicker
          onChange={(value) => {
            const calEndDay = value?.map((v, idx) => {
              if (idx === 1) {
                return v?.endOf('day');
              }

              return v?.startOf('day');
            });

            changeDataByKey('range', calEndDay || [null, null]);
          }}
          value={event.range}
          locale={locale}
        />
      </LabelRow>
      <LabelRow label="썸네일 이미지" isRequired>
        <InputWrapper>
          <InputFileSingle onChange={(file) => setThumbnailImage(file)} />
        </InputWrapper>
        <Warn>
          권장 사양: 이미지 사이즈 360 x 360, 50kb 이하, webp로 저장할때 손실률(80~90)
          <br />
          최소사양: 이미지사이즈 420 x 420, 100kb 이하 webp/jpg/png/gif
        </Warn>
      </LabelRow>
      <LabelRow label="상세 이미지" isRequired>
        <InputWrapper>
          <InputFileMultiple eventPosters={eventPosters} setEventPosters={setEventPosters} />
        </InputWrapper>
        <Warn>Webp: 개별 이미지 사이즈 720(필수) x 4000, 400kb 이하,</Warn>
        <Warn>Gif: gif는 최대한 용량 줄여서.. (https://ezgif.com) 2000kb 이하</Warn>
      </LabelRow>
      <Button type="primary" onClick={onSubmit} style={{ width: '20rem' }} disabled={loading}>
        확인
      </Button>
    </div>
  );
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Warn = styled.p`
  margin: 0;
  color: #ff8080;
`;
