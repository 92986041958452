import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { RESET_BLACKLIST, SET_BLACKLIST } from './blacklist.actions';
import { EventBlacklistState } from './blacklist.models';

const blacklistReducer = createReducer<EventBlacklistState, RootAction>([], {
  // [SET_BLACKLIST]: (state, action) => [...action.payload.value],
  // [RESET_BLACKLIST]: (state, action) => [],
});

export default blacklistReducer;
