import { createReducer } from 'typesafe-actions';

import { IPhilingState } from 'modules/philing/philing.models';
import { RootAction } from 'modules/rootAction';
import {
  GET_DELIVERIES_REQUEST,
  GET_DELIVERIES_SUCCESS,
  GET_PAYMENTS_REQUEST,
  GET_PAYMENTS_SUCCESS,
  GET_TREATMENTS_REQUEST,
  GET_TREATMENTS_SUCCESS,
} from 'modules/philing/philing.actions';

const initialState = {
  isFetching: false,
  delivery: {
    items: [],
    meta: {
      total_items: 0,
      item_count: 0,
      items_per_page: 0,
      current_page: 1,
      total_pages: 0,
    },
  },
  payment: {
    items: [],
    meta: {
      total_items: 0,
      item_count: 0,
      items_per_page: 0,
      current_page: 1,
      total_pages: 0,
    },
  },
  treatment: {
    items: [],
    meta: {
      total_items: 0,
      item_count: 0,
      items_per_page: 0,
      current_page: 1,
      total_pages: 0,
    },
  },
};

const philingReducer = createReducer<IPhilingState, RootAction>(initialState, {
  [GET_DELIVERIES_REQUEST]: (state) => ({ ...state, isFetching: true }),

  [GET_DELIVERIES_SUCCESS]: (state, action) => {
    return { ...state, isFetching: false, delivery: action.payload };
  },

  [GET_PAYMENTS_REQUEST]: (state) => ({ ...state, isFetching: true }),

  [GET_PAYMENTS_SUCCESS]: (state, action) => {
    return { ...state, isFetching: false, payment: action.payload };
  },

  [GET_TREATMENTS_REQUEST]: (state) => ({ ...state, isFetching: true }),

  [GET_TREATMENTS_SUCCESS]: (state, action) => {
    return { ...state, isFetching: false, treatment: action.payload };
  },
});

export default philingReducer;
