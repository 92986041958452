import { createAction, ActionType } from 'typesafe-actions';

import { TagListState } from './manageTag.models';

export const SET_TAG_LIST = 'SET_TAG_LIST';
export const actionSetTagList = createAction(SET_TAG_LIST)<TagListState>();

export const CREATE_TAG_LIST = 'CREATE_TAG_LIST';
export const actionCreateTagList = createAction(CREATE_TAG_LIST)<any>();

export const UPDATE_TAG_LIST = 'UPDATE_TAG_LIST';
export const actionUpdateTagList = createAction(UPDATE_TAG_LIST)<any>();

const actions = {
  actionSetTagList,
  actionCreateTagList,
  actionUpdateTagList,
};

export type ManageTagAction = ActionType<typeof actions>;
