import { createAsyncAction, ActionType } from 'typesafe-actions';

import { IDelivery } from 'odoc-interface-module/odoc-manager-front/delivery';
import { IInvoice } from 'odoc-interface-module/odoc-manager-front/invoice';
import { IPayment } from 'odoc-interface-module/odoc-manager-front/payment';
import { IPaymentByTreatment } from 'odoc-interface-module/odoc-manager-front/paymentByTreatment';

export const GET_DELIVERIES_REQUEST = 'GET_DELIVERIES_REQUEST';
export const GET_DELIVERIES_SUCCESS = 'GET_DELIVERIES_SUCCESS';
export const GET_DELIVERIES_FAILURE = 'GET_DELIVERIES_FAILURE';
export const actionGetDeliveries = createAsyncAction(
  GET_DELIVERIES_REQUEST,
  GET_DELIVERIES_SUCCESS,
  GET_DELIVERIES_FAILURE
)<undefined, IDelivery.Response>();

export const PUT_INVOICES_REQUEST = 'PUT_INVOICES_REQUEST';
export const PUT_INVOICES_SUCCESS = 'PUT_INVOICES_SUCCESS';
export const PUT_INVOICES_FAILURE = 'PUT_INVOICES_FAILURE';
export const actionPutInvoices = createAsyncAction(
  PUT_INVOICES_REQUEST,
  PUT_INVOICES_SUCCESS,
  PUT_INVOICES_FAILURE
)<IInvoice.Item[], undefined>();

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';
export const actionGetInvoices = createAsyncAction(
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE
)<undefined>();

export const GET_PICKUPS_REQUEST = 'GET_PICKUPS_REQUEST';
export const GET_PICKUPS_SUCCESS = 'GET_PICKUPS_SUCCESS';
export const GET_PICKUPS_FAILURE = 'GET_PICKUPS_FAILURE';
export const actionGetPickups = createAsyncAction(
  GET_PICKUPS_REQUEST,
  GET_PICKUPS_SUCCESS,
  GET_PICKUPS_FAILURE
)<undefined>();

export const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';
export const actionGetPayments = createAsyncAction(
  GET_PAYMENTS_REQUEST,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_FAILURE
)<IPayment.RequestParams, IPayment.Response>();

export const GET_TREATMENTS_REQUEST = 'GET_TREATMENTS_REQUEST';
export const GET_TREATMENTS_SUCCESS = 'GET_TREATMENTS_SUCCESS';
export const GET_TREATMENTS_FAILURE = 'GET_TREATMENTS_FAILURE';
export const actionGetTreatments = createAsyncAction(
  GET_TREATMENTS_REQUEST,
  GET_TREATMENTS_SUCCESS,
  GET_TREATMENTS_FAILURE
)<IPaymentByTreatment.RequestParams, IPaymentByTreatment.Response>();

export const EXPORT_EXCEL_REQUEST = 'EXPORT_EXCEL_REQUEST';
export const EXPORT_EXCEL_SUCCESS = 'EXPORT_EXCEL_SUCCESS';
export const EXPORT_EXCEL_FAILURE = 'EXPORT_EXCEL_FAILURE';
export const actionExportExcel = createAsyncAction(
  EXPORT_EXCEL_REQUEST,
  EXPORT_EXCEL_SUCCESS,
  EXPORT_EXCEL_FAILURE
)<any, any>();

const actions = {
  actionGetDeliveries,
  actionPutInvoices,
  actionGetInvoices,
  actionGetPickups,
  actionGetPayments,
  actionGetTreatments,
  actionExportExcel,
};

export type PhilingActions = ActionType<typeof actions>;
