import Axios, { AxiosResponse } from 'axios';
import { takeLatest, all, fork, select } from 'redux-saga/effects';
import { print } from 'graphql';

import { RootState } from 'modules/rootState';
import { TagListState } from './manageTag.models';
import {
  actionCreateTagList,
  actionUpdateTagList,
  CREATE_TAG_LIST,
  UPDATE_TAG_LIST,
} from './manageTag.actions';

function* workerCreateTagList(action: ReturnType<typeof actionCreateTagList>) {
  // const blacklist: EventBlacklistState = yield select((state: RootState) => state.postCatList);

  try {
    const { callback, list } = action.payload;

    yield callback({
      variables: {
        input: list,
      },
    });
  } catch (error) {
    console.error('workerCreateTagList', error);
  }
}

function* workerUpdateTagList(action: ReturnType<typeof actionUpdateTagList>) {
  // const blacklist: EventBlacklistState = yield select((state: RootState) => state.postCatList);

  try {
    const { callback, list } = action.payload;

    yield callback({
      variables: {
        input: list,
      },
    });
  } catch (error) {
    console.error('workerUpdateTagList', error);
  }
}

function* watchCreateTagList() {
  yield takeLatest(CREATE_TAG_LIST, workerCreateTagList);
}

function* watchUpdateTagList() {
  yield takeLatest(UPDATE_TAG_LIST, workerUpdateTagList);
}

export default function* manageTagSaga() {
  yield all([fork(watchCreateTagList), fork(watchUpdateTagList)]);
}
