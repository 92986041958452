import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { notification } from 'antd';

import { RootState } from 'modules/rootState';
import {
  CreateHospitalPointTransaction,
  GetChargeHistory,
  GetHospital,
  GetTransactionTypes,
} from 'graphql/charge.query';
import {
  setChargeByKey,
  setChargeHistory,
  setHospital,
} from 'modules/chargeHistory/chargeHistory.actions';
import { GetReservation } from 'graphql/reservations.query';
import { EventReservation } from 'odoc-interface-module/odoc-manager-front/eventReservation';
import useFilterModel from './useFilterModel';
import { HospitalPointTransactions } from 'odoc-interface-module/odoc-manager-front/hospitalPointTransactions';
import { HospitalTransactionTypes } from 'odoc-interface-module/odoc-manager-front/hospitalTransactionTypes';
import { Hospital } from 'odoc-interface-module/common/hospital';

export default function useChargeHistory() {
  const dispatch = useDispatch();
  const { lists, pagination } = useSelector(
    (state: RootState) => state.chargeHistory.responseChargeHistory
  );
  const { chargeFilterModel } = useFilterModel();

  const [getChargeHistory, { loading }] = useLazyQuery<
    HospitalPointTransactions.Response,
    HospitalPointTransactions.Request
  >(GetChargeHistory, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => dispatch(setChargeHistory(res)),
  });

  const [getTransactionTypes] = useLazyQuery<HospitalTransactionTypes.Response>(
    GetTransactionTypes,
    {
      onCompleted: (res) =>
        dispatch(
          setChargeByKey({
            key: 'transactionType',
            value: res.hospitalPointTransactionTypes.map((type) => ({ ...type, selected: false })),
          })
        ),
    }
  );

  const [getHospital, { loading: getHospitalLoading }] = useLazyQuery<
    Hospital.Response,
    Hospital.Request
  >(GetHospital, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      if (res.hospital) {
        const {
          name,
          point: { balance },
        } = res.hospital;
        dispatch(setHospital({ hospitalName: name, balance }));
      } else {
        dispatch(setHospital({ hospitalName: '', balance: 0 }));
      }
    },
  });

  const [getReservation, { loading: getReservationLoading }] =
    useLazyQuery<EventReservation.Response>(GetReservation, {
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
        if (res.eventReservation) {
          const { reservationPath, event, chargePrice } = res.eventReservation;
          dispatch(
            setChargeByKey({
              key: 'reservation',
              value: { path: `${reservationPath} ${event.name}`, price: chargePrice },
            })
          );
        } else {
          notification.error({
            message: '예약 ID',
            description: '예약 ID를 확인해주세요',
            placement: 'bottomRight',
            duration: 5,
          });
        }
      },
    });

  const [createHospitalPointTransaction] = useMutation(CreateHospitalPointTransaction, {
    onCompleted: (res) => getChargeHistory({ variables: { filter: chargeFilterModel } }),
    onError: (err) => {
      notification.error({
        message: err.message,
        description: '해당병원에 이벤트가 있는지 확인해주세요',
        placement: 'bottomRight',
        duration: 5,
      });
    },
  });

  return {
    getChargeHistory,
    getTransactionTypes,
    getHospital,
    getReservation,
    createHospitalPointTransaction,
    loading,
    getHospitalLoading,
    getReservationLoading,
    lists,
    pagination,
  };
}
