import Axios from 'axios';
import { print } from 'graphql';
import { nanoid } from 'nanoid';

import { CreateSignedUrl } from 'graphql/event.query';
import AuthHelper from 'utils/AuthHelper';
import { IEventState } from 'modules/event/event.models';

const URL = process.env.REACT_APP_BASE_URL || '';
const S3_FOLDER = process.env.REACT_APP_S3_FOLDER || '';

// request s3 signed url
export function getSignedUrl(file: File | null, isThumbnail: boolean, path?: string) {
  const { id_token } = AuthHelper.getUser();

  if (!file) {
    return new Promise((resolve, reject) => resolve(null));
  }

  const S3_PATH = path ? path : `${S3_FOLDER}/${isThumbnail ? 'thumbnail' : 'contents'}`;

  return Axios.post(
    URL,
    {
      query: print(CreateSignedUrl),
      variables: {
        filename: `${S3_PATH}/${createUniqueName(file.name)}`,
        filetype: file.type,
      },
    },
    {
      headers: {
        'X-GOOGLE-OAUTH-TICKET': id_token,
      },
    }
  );
}

// re order array
export function reorder<T>(list: Array<T>, startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

// remove key
type EventState = Omit<IEventState, 'range'>;

export function removeKey(origin: IEventState): EventState {
  const removeKeys = ['range'];

  return Object.keys(origin).reduce((acc, key) => {
    if (!removeKeys.some((removeKey) => removeKey === key)) {
      acc[key] = origin[key];
    }

    return acc;
  }, {} as any);
}

// create unique name
export function createUniqueName(name: string) {
  return `${nanoid()}_${name}`;
}

// https://v2-odoctor-resource.s3.amazonaws.com -> https://images.odoctor.co.kr/
export function replaceEndPoint(url: string) {
  return url.replace('v2-odoctor-resource.s3.amazonaws.com', 'images.odoctor.co.kr');
}
