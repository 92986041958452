import Home from './Home';
import Charge from './Charge';
import Point from './Point';
import Reservations from './Reservations';
import Event from './Event/Create';
import Apply from './Event/Apply';
import List from './Event/List';
import BlackList from './Event/BlackList';
import PhilingDelivery from 'pages/Philing/Delivery';
import PhilingPayment from 'pages/Philing/Payment';
import PhilingPaymentByTreatment from 'pages/Philing/PaymentByTreatment';
import UserList from './User/List';
import ReviewPage from './Event/Review';
import HospitalList from './HospitalManage/List';
import HospitalCreate from './HospitalManage/Create';
import HospitalMetaCreate from './HospitalManage/MetaCreate';
import JujakPost from './Lounge/Jujak';
import JujakLike from './Lounge/Jujak/PostLike';
import ManagePostCat from './Lounge/ManagePostCat';
import ManagePostTag from './Lounge/ManagePostTag';
import ManagePost from './Lounge/ManagePost';

interface IRoute {
  label: string;
  path: string;
  depth: number;
  isVisible: boolean;
  isPrivate: boolean;
  component: any;
  child: IRoute[];
}

export const limitAccounts: string[] = ['assistant'];

export const routeData: IRoute[] = [
  {
    label: `🐮 ${process.env.REACT_APP_NODE_ENV === 'development' ? '데부' : '레알'}`,
    path: '/',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: Home,
    child: [],
  },
  {
    label: '병원관리',
    path: '',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: null,
    child: [
      {
        label: '병원 목록',
        path: '/hospital/list',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: HospitalList,
        child: [],
      },
      {
        label: '병원 생성',
        path: '/hospital/create',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: HospitalCreate,
        child: [],
      },
      {
        label: '스토리 관리',
        path: '/hospital/meta',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: HospitalMetaCreate,
        child: [],
      },
    ],
  },
  {
    label: '예약관리',
    path: '/reservations',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: Reservations,
    child: [],
  },
  {
    label: '충전금관리',
    path: '/charge',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: Charge,
    child: [],
  },
  {
    label: '포인트관리',
    path: '/point',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: Point,
    child: [],
  },
  {
    label: '이벤트관리',
    path: '',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: null,
    child: [
      {
        label: '이벤트 목록',
        path: '/event/list',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: List,
        child: [],
      },
      {
        label: '이벤트 생성',
        path: '/event/create',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: Event,
        child: [],
      },
      {
        label: '이벤트 신청',
        path: '/event/apply',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: Apply,
        child: [],
      },
      {
        label: '리뷰 생성',
        path: '/event/review',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: ReviewPage,
        child: [],
      },
      {
        label: '블랙리스트 관리',
        path: '/event/blacklist',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: BlackList,
        child: [],
      },
    ],
  },
  {
    label: '유저 관리',
    path: '',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: null,
    child: [
      {
        label: '회원탈퇴',
        path: '/user/list',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: UserList,
        child: [],
      },
    ],
  },
  {
    label: '커뮤니티 관리',
    path: '',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: null,
    child: [
      {
        label: '커뮤니티',
        path: '/lounge/post',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: ManagePost,
        child: [],
      },
      {
        label: '카테고리',
        path: '/lounge/cat',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: ManagePostCat,
        child: [],
      },
      {
        label: '태그',
        path: '/lounge/tag',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: ManagePostTag,
        child: [],
      },
      {
        label: '관리하기',
        path: '/lounge/jujak',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: JujakPost,
        child: [],
      },
      {
        label: '좋아요 관리하기',
        path: '/lounge/jujak/like',
        depth: 2,
        isVisible: true,
        isPrivate: false,
        component: JujakLike,
        child: [],
      },
    ],
  },
  // {
  //   label: '👩🏻‍⚕️ 오닥터 비대면',
  //   path: '',
  //   depth: 1,
  //   isVisible: false,
  //   isPrivate: false,
  //   component: null,
  //   child: [
  //     {
  //       label: '배송관리',
  //       path: '/philing/delivery',
  //       depth: 2,
  //       isVisible: true,
  //       isPrivate: false,
  //       component: PhilingDelivery,
  //       child: [],
  //     },
  //     {
  //       label: '정산관리',
  //       path: '/philing/payment',
  //       depth: 2,
  //       isVisible: true,
  //       isPrivate: false,
  //       component: PhilingPayment,
  //       child: [],
  //     },
  //     {
  //       label: '진료관리',
  //       path: '/philing/paymentTreatment',
  //       depth: 2,
  //       isVisible: true,
  //       isPrivate: false,
  //       component: PhilingPaymentByTreatment,
  //       child: [],
  //     },
  //   ],
  // },
];

export const limitAccountRouteData: IRoute[] = [
  {
    label: '🐮',
    path: '/',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: Home,
    child: [],
  },
  {
    label: '포인트관리',
    path: '/point',
    depth: 1,
    isVisible: true,
    isPrivate: false,
    component: Point,
    child: [],
  },
];

class Routes {
  private routeData: IRoute[];
  private limitAccountRouteData: IRoute[];

  constructor() {
    this.routeData = routeData;
    this.limitAccountRouteData = limitAccountRouteData;
  }

  getRoutes() {
    return this.flatRoute(this.routeData, []);
  }

  getLimitAccountRoutes() {
    return this.flatRoute(this.limitAccountRouteData, []);
  }

  getNavigation() {
    return this.makeNavigation(this.routeData, []);
  }

  getLimitAccountNavigation() {
    return this.makeNavigation(this.limitAccountRouteData, []);
  }

  getDefaultOpenKeys(path: string) {
    return this.routeData
      .filter((route) => !route.component)
      .reduce<string[]>((acc, route) => {
        if (route.child.some((child) => child.path === path)) {
          acc.push(route.label);
        }

        return acc;
      }, []);
  }

  private flatRoute(routeData: IRoute[], routes: IRoute[]) {
    routeData.forEach((route) => {
      if (route.component) {
        routes.push(route);
      }

      this.flatRoute(route.child, routes);
    });

    return routes;
  }

  private makeNavigation(routeData: IRoute[], navigation: any[]) {}
}

export const routes = new Routes();
