import { createAction, ActionType } from 'typesafe-actions';

import { PostListState } from './managePost.models';

export const SET_POST_LIST = 'SET_POST_LIST';
export const actionSetPostList = createAction(SET_POST_LIST)<PostListState>();

const actions = {
  actionSetPostList,
};

export type ManagePostAction = ActionType<typeof actions>;
