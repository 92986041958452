import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  PageHeader,
  Table,
  Tag,
  Button,
  Drawer,
  Input,
  Switch,
  InputNumber,
  Alert,
  Space,
  Form,
  Modal,
} from 'antd';
import type { FormInstance } from 'antd/es/form';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import useBlackList, { IEvnetBlackList } from 'hooks/useEventBlackList';
import LabelRow from 'components/CreateEvent/LabelRow';
import InputFileMultiple from 'components/Input/InputFileMultiple';
import { IEventPoster } from 'modules/event/event.models';
import { actionRequestUpdateEvent } from 'modules/event/event.actions';
import useCreateBlacklist from 'hooks/useCreateBlacklist';
import { actionCreateBlacklist } from 'modules/eventBlacklist/blacklist.actions';

const { Search } = Input;
const { confirm } = Modal;

export default function BlackList() {
  const formRef = React.createRef<FormInstance>();

  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [eventPosters, setEventPosters] = useState<IEventPoster[]>([]);
  const [event, setEvent] = useState<IEvnetBlackList>({
    id: '',
    phone: '',
    name: '',
    hospital_id: '',
    memo: '',
    kickban: false,
    is_delete: false,
  });
  const { eventBlackList /* updateEvent */ } = useBlackList();
  const { createBlacklist } = useCreateBlacklist(() => {
    alert('완료!');
  });

  const createMarkup = (value: string) => {
    if (search === '') {
      return { __html: value };
    }

    return { __html: value.replaceAll(search, (match) => `<mark>${match}</mark>`) };
  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: '12rem',
      render: (id) => <div>{id}</div>,
    },
    {
      title: 'phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => <div>{phone}</div>,
    },
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      render: (name) => <div>{name}</div>,
    },
    {
      title: 'h_id',
      dataIndex: 'hospitalId',
      key: 'hospitalId',
      render: (hospital_id) => <div>{hospital_id}</div>,
    },
    {
      title: 'memo',
      dataIndex: 'memo',
      key: 'memo',
      render: (memo) => <div>{memo}</div>,
    },
    {
      title: 'Kickban(전체차단)',
      dataIndex: 'kickban',
      key: 'kickban',
      render: (kickban) => (
        <div>{kickban ? <Tag color="red">yes</Tag> : <Tag color="green">no</Tag>}</div>
      ),
    },
    {
      title: 'isDelete',
      dataIndex: 'isDelete',
      key: 'isDelete',
      render: (is_delete) => (
        <div>{is_delete ? <Tag color="red">삭제</Tag> : <Tag color="green">활성</Tag>}</div>
      ),
    },
  ];

  // const onChangeEvent = (key: keyof typeof event) => (value) => {
  //   if (typeof value === 'number' || typeof value === 'boolean') {
  //     setEvent({ ...event, [key]: value });
  //   } else {
  //     if (key === 'priceDescription' || key === 'hospitalBranchName') {
  //       setEvent({ ...event, [key]: value.target.value });
  //     }
  //   }
  // };

  // const onClickSubmit = () => {
  //   dispatch(
  //     actionRequestUpdateEvent({ callback: updateEvent, eventPosters, event, setShowDetail })
  //   );
  // };

  // const filterEvents = events.filter(
  //   (event) =>
  //     event.name.includes(search) ||
  //     event.id.includes(search) ||
  //     event.hospital.name.includes(search)
  // );

  // const handleDisplay = () => {
  //   const { originalPrice, discountRate, discountedPrice } = event;
  //   const prices = [discountRate, discountedPrice, originalPrice];
  //   // 모든정보
  //   const isCorrectPrice = prices.every((price) => !!price);
  //   // 할인율만 존재
  //   const isOnlyDiscountRate = !!discountRate && !discountedPrice;
  //   // 할인가만 존재
  //   const isOnlyDiscountedPrice = !!discountedPrice && !discountRate;

  //   if (isCorrectPrice) {
  //     return (
  //       <div>
  //         <DiscountRate>{discountRate}%</DiscountRate>
  //         <Price>{String(discountedPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</Price>
  //         <Origin>{String(originalPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</Origin>
  //       </div>
  //     );
  //   } else if (isOnlyDiscountRate) {
  //     return (
  //       <div>
  //         <DiscountRate>{discountRate}% 특별할인</DiscountRate>
  //       </div>
  //     );
  //   } else if (isOnlyDiscountedPrice) {
  //     return (
  //       <div>
  //         <Price single>
  //           특가 {String(discountedPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
  //         </Price>
  //       </div>
  //     );
  //   } else {
  //     return '아무것도 안보여줌';
  //   }
  // };

  const onClickOpenPanel = () => {
    setShowAddPanel(true);
  };

  const onClickClosePanel = () => {
    setShowAddPanel(false);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  const onFinish = (values: any) => {
    console.log('Received values of form:', values);

    confirm({
      title: '블랙리스트를 생성하겠습니까?',
      onOk() {
        dispatch(actionCreateBlacklist({ callback: createBlacklist, list: values.blacklist }));
      },
    });
  };

  return (
    <Container>
      <PageHeader
        title="블랙리스트"
        subTitle="차단된 사용자 목록 (* 전화번호 기준), kickban: 전체 차단 차단!"
      />
      <div onClick={onClickOpenPanel}>블랙리스트 추가</div>
      {/* <SearchRow>
        <Search
          placeholder="이벤트명, 이벤트아이디, 병원명 검색"
          allowClear
          size="large"
          onChange={(e) => setSearch(e.target.value)}
        />
      </SearchRow> */}
      <Table columns={columns} dataSource={eventBlackList} rowKey={(record) => record.id} />
      <Drawer
        title="블랙리스트 추가하기"
        width={700}
        onClose={onClickClosePanel}
        visible={showAddPanel}
        placement={'right'}
      >
        <div>
          이름은 한글/영어만 가능
          <br />
          전화번호는 숫자만 추출되어 자동 변환 됩니다. 예) 010-1234-1234 입력시, 01012341234 출력
          <br />
          메모는 선택사항임니다!
          <br />* 추가후 리셋 버튼을 누르면 폼이 초기화 됨
          <br />* 스위치 토글시 병원ID 와 상관없이 모든 병원/이벤트에서 번호 차단 (전체차단)
          <br /> (kickban = true 사용시 병워ID: 28 입력 권장 )
        </div>
        <Form ref={formRef} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
          <Form.List name="blacklist" initialValue={[1]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[
                        { required: true, message: '' },
                        { pattern: /[a-z|A-Z|ㄱ-ㅎ|가-힣]/g, message: '한글영어만' },
                      ]}
                    >
                      <Input placeholder="이름" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'phone']}
                      normalize={(v) => v.replace(/[^0-9]/g, '')}
                      rules={[
                        // { transform: (value) => 1 + value + 'ㅘ리', message: 'a' },
                        { required: true, message: '' },
                        { pattern: /[0-9]/g, message: '숫자만요!' },
                      ]}
                    >
                      <Input placeholder="전화번호" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'hospital_id']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <InputNumber placeholder="병워ID" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'memo']}
                      rules={[{ required: false, message: 'Missing last name' }]}
                    >
                      <Input placeholder="메모" />
                    </Form.Item>

                    <Form.Item {...restField} name={[name, 'kickban']} valuePropName="checked">
                      <Switch checkedChildren="전체차단" unCheckedChildren="NO" />
                    </Form.Item>

                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(name)}
                      />
                    ) : null}
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button
              style={{ marginLeft: '1rem' }}
              htmlType="button"
              onClick={() => {
                formRef.current!.resetFields();
              }}
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </Container>
  );
}

const Container = styled.div`
  min-width: 1200px;
  padding: 1.6rem;
  margin: 0 1.6rem;
  background: #fff;
`;

const SearchRow = styled.div`
  margin-bottom: 1rem;
`;

export const DiscountRate = styled.span`
  font-size: 2.4rem;
  line-height: 0.83;
  color: #dd4124;
  font-weight: 600;
`;

export const Price = styled.span<{ single?: boolean }>`
  font-size: 2.4rem;
  color: #dd4326;
  font-weight: 600;
  margin-left: ${({ single }) => !single && '0.6rem'};
`;

export const Origin = styled.span`
  font-size: 1.8rem;
  color: #b1b3b2;
  line-height: 1.11;
  margin-left: 0.6rem;
  text-decoration: line-through;
`;

export const CustomRow = styled.div`
  margin-bottom: 1rem;
`;

export const DnDWrapper = styled.div`
  padding: 1rem;
`;
