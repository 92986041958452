import gql from 'graphql-tag';

// 게시글
// 리스트 / 글수정 / 글쓰기 / 삭제하기;
export const GetPosts = gql`
  query ($filter: PostListFilter, $pagination: PaginationInput) {
    posts(filter: $filter, pagination: $pagination) {
      data {
        id
        title
        content
        user {
          id
          name
        }
        images {
          id
          url
        }
        category {
          id
          title
        }
        tags {
          id
          name
        }
        isDelete
        isPickShow
        isBest
        commentCount
        likeCount
        createdAt
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const UpdatePost = gql`
  mutation ($input: UpdatePostInput!) {
    updatePost(input: $input) {
      ok
    }
  }
`;

export const CreatePost = gql`
  mutation ($input: CreatePostInput!) {
    createPost(input: $input) {
      title
    }
  }
`;

export const DeletePost = gql`
  mutation ($input: CreatePostInput!) {
    deletePost(input: $input) {
      title
    }
  }
`;

// 태그
// 리스트/추가/수정/삭제
export const GetTags = gql`
  # "pagination": { "perPage": 10 }
  # isPickVisible 픽상세에서 태그 노출

  query ($filter: TagFilter, $pagination: PaginationInput) {
    tag(filter: $filter, pagination: $pagination) {
      data {
        id
        name
        type
        isPickVisible
        isVisible
        isDelete
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const CreateTag = gql`
  mutation ($input: [TagInput]!) {
    createTag(input: $input) {
      ok
    }
  }
`;

export const UpdateTag = gql`
  mutation ($id: ID!, $input: [TagInput]!) {
    updateTag(id: $id, input: $input) {
      ok
    }
  }
`;

export const DeleteTag = gql`
  mutation ($id: ID!) {
    deleteTag(id: $id) {
      ok
    }
  }
`;

// 카테고리
// 리스트/추가/수정/삭제
export const GetCats = gql`
  query ($filter: CategoryFilter, $pagination: PaginationInput) {
    postCategory(filter: $filter, pagination: $pagination) {
      data {
        id
        title
        order
        isVisible
        isDelete
        createdAt
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const CreateCat = gql`
  mutation ($input: [PostCategoryInput]!) {
    createPostCat(input: $input) {
      ok
    }
  }
`;

export const UpdateCat = gql`
  mutation ($id: ID!, $input: PostCategoryInput!) {
    updatePostCat(id: $id, input: $input) {
      ok
    }
  }
`;

export const DeleteCat = gql`
  mutation ($id: ID!) {
    deletePostCat(id: $id) {
      ok
    }
  }
`;
