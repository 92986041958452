import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, Select, InputNumber, Input, Switch, Button, Modal, message } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import styled from 'styled-components';

import useCreateHospital from 'hooks/useCreateHospital';

import LabelRow from 'components/CreateEvent/LabelRow';
import { DatePicker } from 'components/CustomPicker';
import { RootState } from 'modules/rootState';
import { IEventState } from 'modules/event/event.models';
import { actionCreateEvent, setEventByKey } from 'modules/event/event.actions';
import { IEventPoster } from 'modules/event/event.models';
import InputFileSingle from 'components/Input/InputFileSingle';
import InputFileMultiple from 'components/Input/InputFileMultiple';
import { hospitalCreateValidation } from 'utils/hospitalValidation';

import { HospitalState } from 'modules/hospital/hospital.models';
import { actionCreateHospital, setHospitalByKey } from 'modules/hospital/hospital.actions';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Option } = Select;

const initMeta = {
  cctv: {
    enable: 0,
    images: [],
  },
  preview: {
    enable: 0,
    images: [],
  },
  certi: {
    enable: 0,
    images: [],
  },
};

export default function CreateHospital() {
  const dispatch = useDispatch();
  const hospital = useSelector((state: RootState) => state.hospital);
  const { createHospital, loading } = useCreateHospital(() => {
    // setThumbnailImage(undefined);
    // setEventPosters([]);
  });
  const [thumbnailImage, setThumbnailImage] = useState<File>();
  const [eventPosters, setEventPosters] = useState<IEventPoster[]>([]);
  const [describe, setDescribe] = useState<any[]>([]);

  function changeDataByKey(key: keyof HospitalState, value: any) {
    console.log(key, value);

    dispatch(setHospitalByKey({ key, value }));
  }

  function onSubmit() {
    if (hospitalCreateValidation({ ...hospital })) {
      message.error('필수입력을 확인해주세요');
      return;
    }

    confirm({
      title: '병원을 생성하겠습니까?',
      onOk() {
        dispatch(actionCreateHospital({ callback: createHospital }));
      },
    });
  }

  return (
    <div style={{ minWidth: '1200px', padding: '1.6rem', margin: '0 1.6rem', background: '#fff' }}>
      <PageHeader title="병원 생성" subTitle="alpha version" />
      <LabelRow label="병원 이름 🆔" isRequired>
        <Input
          placeholder="오닥터치과의원"
          onChange={({ target: { value } }) => changeDataByKey('name', value)}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow label="시군구 🆔" isRequired>
        <InputNumber
          min={1}
          // value={event.hospitalId || undefined}
          onChange={(value) => changeDataByKey('gunguId', value)}
        />
      </LabelRow>
      <LabelRow label="병원 카테고리 🆔" isRequired>
        <InputNumber min={1} max={5} onChange={(value) => changeDataByKey('categoryId', value)} />
        <div>1.피부과, 2.치과, 3.성형외과, 4.한의원, 5.안과</div>
      </LabelRow>
      <LabelRow label="히라코드">
        <Input
          placeholder="123456"
          onChange={({ target: { value } }) => changeDataByKey('hiraCode', value)}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow label="전화번호" isRequired>
        <Input
          placeholder="02-1234-1234"
          onChange={({ target: { value } }) => changeDataByKey('tel', value)}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow label="E-mail" isRequired>
        <Input
          placeholder="odoctor@odoctor.co.kr"
          onChange={({ target: { value } }) => changeDataByKey('email', value)}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      {/* <LabelRow label="병원 카테고리 🆔" isRequired>
        <Select<string>
          mode="multiple"
          placeholder="최소 1개 이상 선택, 카테고리명으로 검색"
          filterOption
          optionFilterProp="children"
          allowClear
          onChange={(value) => changeDataByKey('categoryIds', value)}
          value={event.categoryIds as any}
          style={{ width: '40rem' }}
        >
          {categories.map(({ id, name }) => (
            <Option value={id} key={id}>
              {`[${id}] - ${name}`}
            </Option>
          ))}
        </Select>
      </LabelRow>
      <LabelRow label="이벤트 제목" isRequired>
        <TextArea
          placeholder="3줄 넘지않게 작성, 엔터키로 줄바꿈"
          autoSize={{ maxRows: 3 }}
          value={event.name}
          onChange={({ target: { value } }) => changeDataByKey('name', value)}
          style={{ width: '40rem' }}
        />
      </LabelRow> */}

      <Button type="primary" onClick={onSubmit} style={{ width: '20rem' }} disabled={loading}>
        확인
      </Button>
    </div>
  );
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Warn = styled.p`
  margin: 0;
  color: #ff8080;
`;
