import { Dispatch, SetStateAction } from 'react';
import { MutationFunctionOptions } from '@apollo/client';
import { createAction, ActionType, createAsyncAction } from 'typesafe-actions';
import {
  IEventPoster,
  IEventState,
  IReqCreateEvent,
  ReqUpdateHospital,
  ResUpdateHospital,
  IResCreateEvent,
  HospitalState,
  ReqCreateHospital,
  ResCreateHospital,
} from './hospital.models';
import { IEvent } from 'hooks/useEventList';
import { IHospital } from 'hooks/useHospitalList';

interface IPayload {
  key: keyof HospitalState;
  value: any;
}

export const RESET_HOSPITAL_INFO = 'RESET_HOSPITAL_INFO';
export const resetHospitalInfo = createAction(RESET_HOSPITAL_INFO)();

export const SET_HOSPITAL_INFO = 'SET_HOSPITAL_INFO';
export const setHospitalInfo = createAction(SET_HOSPITAL_INFO)();

export const SET_HOSPITAL_BY_KEY = 'SET_HOSPITAL_BY_KEY';
export const setHospitalByKey = createAction(SET_HOSPITAL_BY_KEY)<IPayload>();

export const CREATE_HOSPITAL = 'CREATE_HOSPITAL';
export const actionCreateHospital = createAction(CREATE_HOSPITAL)<{
  callback: (
    options?: MutationFunctionOptions<ResCreateHospital, ReqCreateHospital> | undefined
  ) => void;
}>();

export const REQUEST_UPDATE_HOSPITAL = 'REQUEST_UPDATE_HOSPITAL';
export const actionRequestUpdateHospital = createAction(REQUEST_UPDATE_HOSPITAL)<{
  callback: (
    options?: MutationFunctionOptions<ResUpdateHospital, ReqUpdateHospital> | undefined
  ) => void;
  setShowDetail: Dispatch<SetStateAction<boolean>>;
  hospital: IHospital;
}>();

export const REQUEST_UPDATE_HOSPITAL_META = 'REQUEST_UPDATE_HOSPITAL_META';
export const actionUpdateHospitalMeta = createAction(REQUEST_UPDATE_HOSPITAL_META)<{
  callback: (
    options?: MutationFunctionOptions<ResUpdateHospital, ReqUpdateHospital> | undefined
  ) => void;
  cctvImage: any;
  certiImage: any;
  hospitalId: number;
}>();

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';
export const actionGetEvents = createAsyncAction(
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE
)<undefined>();

const actions = {
  resetHospitalInfo,
  setHospitalInfo,
  setHospitalByKey,
  actionCreateHospital,
  actionUpdateHospitalMeta,
  actionGetEvents,
};

export type HospitalActions = ActionType<typeof actions>;
