import { createAction, ActionType } from 'typesafe-actions';

import { IUserState } from './user.models';

export const SET_USERS = 'SET_USERS';
export const actionSetUsers = createAction(SET_USERS)<IUserState>();

const actions = {
  actionSetUsers,
};

export type UserActions = ActionType<typeof actions>;
