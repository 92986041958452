import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';

import { GetPosts, GetCats, GetTags, UpdatePost } from 'graphql/post.manage.query';
import { RootState } from 'modules/rootState';
import { actionSetPostList } from 'modules/managePost/managePost.actions';
import { notification } from 'antd';

export default function usePostManage(onCompleted?: () => void | undefined) {
  const dispatch = useDispatch();
  const { list, pagination } = useSelector((state: RootState) => state.postList);

  const [getPostList] = useLazyQuery<any>(GetPosts, {
    onCompleted: (res) =>
      dispatch(actionSetPostList({ list: res.posts.data, pagination: res.posts.pagination })),
  });

  // const [updateEvent] = useMutation<IResUpdateEvent, IReqUpdateEvent>(UpdateEvent, {
  //   refetchQueries: [{ query: GetEventsAll, variables: { pagination } }],
  // });

  // #2 글쓰기 주작
  const [updatePost, { loading }] = useMutation<any, any>(UpdatePost, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      onCompleted && onCompleted();
    },
    onError: (error) => {
      // dispatch(resetEvent());
      // resetAfterCreateEvent();

      notification.error({
        message: '망함',
        description: error.message,
        placement: 'bottomRight',
        duration: 5,
      });
    },
  });

  return { getPostList, list, pagination, updatePost };
}
