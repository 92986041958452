import { Dispatch, SetStateAction } from 'react';
import { MutationFunctionOptions } from '@apollo/client';
import { createAction, ActionType, createAsyncAction } from 'typesafe-actions';
import { ResCreateBlacklist, ReqCreateBlacklist } from './blacklist.models';

export const RESET_BLACKLIST = 'RESET_BLACKLIST';
export const resetBlacklist = createAction(RESET_BLACKLIST)();

export const SET_BLACKLIST = 'SET_BLACKLIST';
export const setBlacklist = createAction(SET_BLACKLIST)();

export const CREATE_BLACKLIST = 'CREATE_BLACKLIST';
export const actionCreateBlacklist = createAction(CREATE_BLACKLIST)<{
  callback: (
    options?: MutationFunctionOptions<ResCreateBlacklist, ReqCreateBlacklist> | undefined
  ) => void;
  list: any;
}>();

const actions = {
  actionCreateBlacklist,
  resetBlacklist,
  setBlacklist,
};

export type EventActions = ActionType<typeof actions>;
