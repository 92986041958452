import { createAction, ActionType } from 'typesafe-actions';

import { DeliveryFilter, PaymentFilter, PaymentByTreatFilter } from 'modules/filter/filter.models';

interface IFilterPayload {
  key: string;
  value: any;
}

export const SET_RESERVATION_FILTER = 'SET_RESERVATION_FILTER';
export const setReservationFilter = createAction(SET_RESERVATION_FILTER)<IFilterPayload>();

export const RESET_RESERVATION_FILTER = 'RESET_RESERVATION_FILTER';
export const resetReservationFilter = createAction(RESET_RESERVATION_FILTER)();

export const SET_POINT_FILTER = 'SET_POINT_FILTER';
export const setPointFilter = createAction(SET_POINT_FILTER)<IFilterPayload>();

export const RESET_POINT_FILTER = 'RESET_POINT_FILTER';
export const resetPointFilter = createAction(RESET_POINT_FILTER)();

export const SET_CHARGE_FILTER = 'SET_CHARGE_FILTER';
export const setChargeFilter = createAction(SET_CHARGE_FILTER)<IFilterPayload>();

export const RESET_CHARGE_FILTER = 'RESET_CHARGE_FILTER';
export const resetChargeFilter = createAction(RESET_CHARGE_FILTER)();

export const SET_DELIVERY_FILTER = 'SET_DELIVERY_FILTER';
export const actionSetDeliveryFilter = createAction(SET_DELIVERY_FILTER)<{
  key: keyof DeliveryFilter;
  value: any;
}>();

export const RESET_DELIVERY_FILTER = 'RESET_DELIVERY_FILTER';
export const actionResetDeliveryFilter = createAction(RESET_DELIVERY_FILTER)();

export const SET_PAYMENT_FILTER = 'SET_PAYMENT_FILTER';
export const actionSetPaymentFilter = createAction(SET_PAYMENT_FILTER)<{
  key: keyof PaymentFilter;
  value: any;
}>();

export const RESET_PAYMENT_FILTER = 'RESET_PAYMENT_FILTER';
export const actionResetPaymentFilter = createAction(RESET_PAYMENT_FILTER)();

export const SET_PAYMENT_TREAT_FILTER = 'SET_PAYMENT_TREAT_FILTER';
export const actionSetPaymentTreatFilter = createAction(SET_PAYMENT_TREAT_FILTER)<{
  key: keyof PaymentByTreatFilter;
  value: any;
}>();

export const RESET_PAYMENT_TREAT_FILTER = 'RESET_PAYMENT_TREAT_FILTER';
export const actionResetPaymentTreatFilter = createAction(RESET_PAYMENT_TREAT_FILTER)();

export const SET_USER_FILTER = 'SET_USER_FILTER';
export const actionSetUserFilter = createAction(SET_USER_FILTER)<IFilterPayload>();

const actions = {
  setReservationFilter,
  resetReservationFilter,
  setPointFilter,
  resetPointFilter,
  setChargeFilter,
  resetChargeFilter,
  actionSetDeliveryFilter,
  actionResetDeliveryFilter,
  actionSetPaymentFilter,
  actionResetPaymentFilter,
  actionSetPaymentTreatFilter,
  actionResetPaymentTreatFilter,
  actionSetUserFilter,
};

export type FilterActions = ActionType<typeof actions>;
