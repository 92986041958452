import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { RESET_EVENT, SET_EVENT_BY_KEY } from './event.actions';
import { IEventState } from './event.models';

function getRandomIntInclusive(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //최댓값도 포함, 최솟값도 포함
}

const initialState = {
  id: 0,
  hospitalId: 0,
  name: '',
  description: '',
  chargePrice: 0,
  discountRate: null,
  discountedPrice: 0,
  originalPrice: null,
  visible: true,
  applyCount: getRandomIntInclusive(70, 150),
  categoryIds: [],
  therapyName: '',
  range: {
    start: '',
    end: '',
  },
  priceDescription: null,
  hospitalBranchName: '',
  useBlock: true,
  isDelete: false,
  isEnableKakao: true,
  crmViewMode: 1,
};

const eventReducer = createReducer<IEventState, RootAction>(initialState, {
  [SET_EVENT_BY_KEY]: (state, action) => ({ ...state, [action.payload.key]: action.payload.value }),

  [RESET_EVENT]: (state, action) => initialState,
});

export default eventReducer;
