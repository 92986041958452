import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { PostState } from './post.models';
import { SET_FAKE_USER, SET_POST, SET_POST_COMMENT } from './post.actions';

const initialState = {
  fakeUser: {
    id: '',
    name: '',
    nickname: '',
    avatar: '',
  },
  post: {
    title: '',
    categoryId: 0,
    content: '',
    userId: '',
    nickname: '',
    tags: '',
    images: null,
  },
  comment: {
    postId: 0,
    comment: '',
    userId: '',
    nickname: '',
  },
};

const fakePostReducer = createReducer<PostState, RootAction>(initialState, {
  [SET_FAKE_USER]: (state, action) => ({
    ...state,
    fakeUser: action.payload.fakeUser,
  }),
  [SET_POST]: (state, action) => ({
    ...state,
    post: action.payload.post,
  }),
  [SET_POST_COMMENT]: (state, action) => ({
    ...state,
    comment: action.payload.comment,
  }),
});

export default fakePostReducer;
