import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import { GetEventReservations } from 'graphql/reservations.query';
import { EventReservations } from 'odoc-interface-module/odoc-manager-front/eventReservations';
import { resetReservationFilter } from 'modules/filter/filter.actions';
import { exportToExcel } from 'utils/exportToExcel';
import useFilterModel from './useFilterModel';

export default function useReservation() {
  const dispatch = useDispatch();
  const { reservationFilterModel } = useFilterModel();

  const [getEventReservations, { data, loading }] = useLazyQuery<
    EventReservations.Response,
    EventReservations.Request
  >(GetEventReservations);

  const [getEventReservationsToExcel] = useLazyQuery<
    EventReservations.Response,
    EventReservations.Request
  >(GetEventReservations, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ eventReservations }) =>
      exportToExcel(eventReservations.data, reservationFilterModel),
    onError: (err) => console.error('엑셀안댐', err),
  });

  useEffect(() => {
    getEventReservations();

    return () => {
      dispatch(resetReservationFilter());
    };
  }, [dispatch, getEventReservations]);

  return { getEventReservations, getEventReservationsToExcel, data, loading };
}
