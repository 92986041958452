import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import {
  RESET_CHARGE_INPUT,
  SELECT_TRANSACTION_TYPE,
  SET_CHARGE_BY_KEY,
  SET_CHARGE_HISTORY,
  SET_HOSPITAL,
} from './chargeHistory.actions';
import { IChargeHistoryState } from './chargeHistory.models';

const initialState = {
  responseChargeHistory: {
    lists: [],
    pagination: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 0,
    },
  },
  hospitalId: '',
  hospitalName: '',
  balance: 0,
  transactionType: [],
  reservationId: '',
  charge: 0,
  reservation: {
    path: '',
    price: 0,
  },
};

const chargeHistoryReducer = createReducer<IChargeHistoryState, RootAction>(initialState, {
  [SET_CHARGE_HISTORY]: (state, action) => ({
    ...state,
    responseChargeHistory: {
      lists: action.payload.hospitalPointTransactions.data,
      pagination: action.payload.hospitalPointTransactions.pagination,
    },
  }),

  [SET_CHARGE_BY_KEY]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }),

  [RESET_CHARGE_INPUT]: (state) => ({
    ...state,
    hospitalId: '',
    hospitalName: '',
    balance: 0,
    reservationId: '',
    reservationPath: '',
    charge: 0,
    refundPrice: 0,
    transactionType: state.transactionType.map((transaction) => ({
      ...transaction,
      selected: false,
    })),
  }),

  [SET_HOSPITAL]: (state, action) => ({
    ...state,
    hospitalName: action.payload.hospitalName,
    balance: action.payload.balance,
  }),

  [SELECT_TRANSACTION_TYPE]: (state, action) => ({
    ...state,
    reservationId: '',
    charge: 0,
    reservation: {
      path: '',
      price: 0,
    },
    transactionType: state.transactionType.map((transaction) => {
      if (transaction.id === action.payload) {
        return { ...transaction, selected: true };
      }

      return { ...transaction, selected: false };
    }),
  }),
});

export default chargeHistoryReducer;
