import React from 'react';

export default function Home() {
  return (
    <div>
      <h1 style={{ fontSize: '20rem', textAlign: 'center' }}>
        <span role="img" aria-label="cow">
          🐮
        </span>
      </h1>
    </div>
  );
}
