import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';

import { CreateBlacklist } from 'graphql/blacklist.query';
import { ResCreateBlacklist, ReqCreateBlacklist } from 'modules/eventBlacklist/blacklist.models';
import { resetBlacklist } from 'modules/eventBlacklist/blacklist.actions';

export default function useCreateBlacklist(onCompleted: () => void) {
  const [createBlacklist, { loading }] = useMutation<ResCreateBlacklist, ReqCreateBlacklist>(
    CreateBlacklist,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
        // dispatch(resetEvent());
        onCompleted();
      },
      onError: (error) => {
        // dispatch(resetEvent());
        // resetAfterCreateEvent();

        notification.error({
          message: '망함',
          description: error.message,
          placement: 'bottomRight',
          duration: 5,
        });
      },
    }
  );

  return { createBlacklist, loading };
}
