import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';

import { CreateEvent, CreateSignedUrl, GetEventCategories } from 'graphql/event.query';
import {
  ICategories,
  IReqCreateEvent,
  IReqSignedUrl,
  IResCreateEvent,
  IResSignedUrl,
} from 'modules/event/event.models';
import { resetEvent } from 'modules/event/event.actions';

export default function useCreateEvent(resetAfterCreateEvent: () => void) {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<ICategories[]>([]);
  useQuery(GetEventCategories, {
    onCompleted: (res) => setCategories(res.eventCategories.filter(({ id }) => Number(id) >= 12)),
  });

  const [createEvent, { loading }] = useMutation<IResCreateEvent, IReqCreateEvent>(CreateEvent, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      dispatch(resetEvent());
      resetAfterCreateEvent();
    },
    onError: (error) => {
      dispatch(resetEvent());
      resetAfterCreateEvent();

      notification.error({
        message: '망함',
        description: error.message,
        placement: 'bottomRight',
        duration: 5,
      });
    },
  });

  const [createSignedUrl] = useMutation<IResSignedUrl, IReqSignedUrl>(CreateSignedUrl, {
    onCompleted: (res) => console.log('created signed url', res),
  });

  return { createEvent, createSignedUrl, loading, categories };
}
