import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';

import { GetTags, CreateTag, UpdateTag } from 'graphql/post.manage.query';
import { RootState } from 'modules/rootState';
import { actionSetTagList } from 'modules/manageTag/manageTag.actions';

import { notification } from 'antd';

export default function useTagManage() {
  const dispatch = useDispatch();
  const { list, pagination } = useSelector((state: RootState) => state.tagList);

  const [getTagList] = useLazyQuery<any>(GetTags, {
    onCompleted: (res) => {
      return dispatch(
        actionSetTagList({
          list: res.tag.data,
          pagination: res.tag.pagination,
        })
      );
    },
  });

  // const [updateEvent] = useMutation<IResUpdateEvent, IReqUpdateEvent>(UpdateEvent, {
  //   refetchQueries: [{ query: GetEventsAll, variables: { pagination } }],
  // });

  return { getTagList, list, pagination };
}

export function useCreatePostTag(onCompleted: () => void) {
  const [createPostTag, { loading }] = useMutation<any, any>(CreateTag, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      // dispatch(resetEvent());
      onCompleted();
    },
    onError: (error) => {
      // dispatch(resetEvent());
      // resetAfterCreateEvent();

      notification.error({
        message: '망함',
        description: error.message,
        placement: 'bottomRight',
        duration: 5,
      });
    },
  });

  return { createPostTag, loading };
}

export function useUpdatePostTag(onCompleted: () => void) {
  const [updatePostTag, { loading }] = useMutation<any, any>(UpdateTag, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      // dispatch(resetEvent());
      onCompleted();
    },
    onError: (error) => {
      // dispatch(resetEvent());
      // resetAfterCreateEvent();

      notification.error({
        message: '망함',
        description: error.message,
        placement: 'bottomRight',
        duration: 5,
      });
    },
  });

  return { updatePostTag, loading };
}
