import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { PageHeader, Table, Tag, Button, Drawer, Input, Switch, InputNumber, Alert } from 'antd';

import useHospitalList, { IHospital } from 'hooks/useHospitalList';
import LabelRow from 'components/CreateEvent/LabelRow';
import InputFileMultiple from 'components/Input/InputFileMultiple';
import { IEventPoster } from 'modules/event/event.models';
import { actionRequestUpdateHospital } from 'modules/hospital/hospital.actions';

import { CheckCircleOutlined } from '@ant-design/icons';

const { Search } = Input;

export default function List() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [eventPosters, setEventPosters] = useState<IEventPoster[]>([]);
  const [hospital, setHospital] = useState<any>({
    name: '',
    huser: {
      email: '',
    },
    tel: '',
    hiraCode: '',
    category: { id: '' },
    location: { id: '' },
    isUseCrmService: false,
  });

  const { hospitals, updateHospital } = useHospitalList();

  const createMarkup = (value: string) => {
    if (search === '') {
      return { __html: value };
    }

    return { __html: value.replaceAll(search, (match) => `<mark>${match}</mark>`) };
  };

  const columns = [
    {
      title: '병원ID',
      dataIndex: 'id',
      key: 'id',
      width: '12rem',
      render: (id) => <div>{id}</div>,
    },
    {
      title: '병원명',
      dataIndex: 'name',
      key: 'name', //<Tag color="#2db7f5">#2db7f5</Tag>
      render: (name, row) => {
        return (
          <div>
            {name}{' '}
            {row.isUseCrmService && (
              <Tag style={{ borderRadius: '4px' }} color="#2db7f5">
                CRM
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: '메일(계정)',
      dataIndex: ['huser', 'email'],
      key: 'email',
      render: (email) => <div>{email}</div>,
    },
    {
      title: '전화번호',
      dataIndex: 'tel',
      key: 'tel',
      render: (tel) => <div>{tel}</div>,
    },
    {
      title: 'hira',
      dataIndex: 'hiraCode',
      key: 'hiraCode',
      render: (hiraCode) => <div>{hiraCode}</div>,
    },
    {
      title: '포인트잔액',
      dataIndex: ['point', 'balance'],
      key: 'point',
      render: (point) => <div>{point}</div>,
    },
    {
      title: '진료과',
      dataIndex: ['category', 'id'],
      key: 'category',
      render: (category) => <div>{category}</div>,
    },
    {
      title: '위치',
      dataIndex: ['location', 'id'],
      key: 'location',
      render: (location) => <div>{location}</div>,
    },
    {
      title: '',
      key: 'detail',
      width: '10rem',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            const [selectHospital] = hospitals.filter((h) => h.id === record.id);
            setHospital({ ...selectHospital });
            setShowDetail(true);
          }}
        >
          상세
        </Button>
      ),
    },
  ];

  const onClickSubmit = () => {
    dispatch(actionRequestUpdateHospital({ callback: updateHospital, hospital, setShowDetail }));
  };

  const onChangeEvent = (key: keyof typeof hospital) => (value) => {
    if ('category,location'.includes(String(key))) {
      setHospital({ ...hospital, [key]: { id: value.target.value } });
    } else if ('isUseCrmService'.includes(String(key))) {
      setHospital({ ...hospital, [key]: value });
    } else {
      setHospital({ ...hospital, [key]: value.target.value });
    }
  };

  const filterHospitals = hospitals.filter(
    (hospital) => hospital.name.includes(search) || hospital.id.includes(search)
  );

  return (
    <Container>
      <PageHeader title="병원 목록" subTitle="병원 목록" />
      <SearchRow>
        <Search
          placeholder="병원명, 병원아이디, 병원명 검색"
          allowClear
          size="large"
          onChange={(e) => setSearch(e.target.value)}
        />
      </SearchRow>
      <Table columns={columns} dataSource={filterHospitals} rowKey={(record) => record.id} />
      <Drawer
        title="병원정보 수정"
        placement="right"
        width="70rem"
        onClose={() => setShowDetail(false)}
        visible={showDetail}
      >
        <Alert
          message={
            <div>
              <p style={{ margin: 0 }}>계정정보는 수정 불가</p>
            </div>
          }
          type="warning"
          style={{ marginBottom: '2rem' }}
        />
        <LabelRow label="병원명">
          <Input
            value={hospital.name}
            onChange={onChangeEvent('name')}
            placeholder="오닥터 독산지산"
            style={{ width: '100%' }}
          />
        </LabelRow>

        <LabelRow label="계정">
          <Input disabled value={hospital.huser.email} style={{ width: '100%' }} />
        </LabelRow>

        <LabelRow label="전화번호">
          <Input
            value={hospital.tel}
            onChange={onChangeEvent('tel')}
            style={{ width: '100%' }}
            placeholder="02-1234-1234"
          />
        </LabelRow>

        <LabelRow label="히라코드">
          <Input
            value={hospital.hiraCode}
            onChange={onChangeEvent('hiraCode')}
            style={{ width: '100%' }}
            placeholder="02-1234-1234"
          />
        </LabelRow>

        <LabelRow label="진료과">
          <Input
            value={hospital.category.id}
            onChange={onChangeEvent('category')}
            style={{ width: '100%' }}
            placeholder="1"
          />
        </LabelRow>

        <LabelRow label="위치">
          <Input
            value={hospital.location.id}
            onChange={onChangeEvent('location')}
            style={{ width: '100%' }}
            placeholder="25"
          />
        </LabelRow>

        <LabelRow label="crm 사용">
          <Switch
            checked={hospital.isUseCrmService}
            checkedChildren="사용"
            unCheckedChildren="미사용"
            onChange={onChangeEvent('isUseCrmService')}
          />
        </LabelRow>

        {/* <LabelRow label="노출/비노출">
          <Switch
            checked={hospital.isActive}
            checkedChildren="노출"
            unCheckedChildren="비노출"
            onChange={onChangeEvent('visible')}
          />
        </LabelRow> */}

        <Button onClick={onClickSubmit} style={{ width: '100%' }} type="primary">
          확인
        </Button>
      </Drawer>
    </Container>
  );
}

const Container = styled.div`
  min-width: 1200px;
  padding: 1.6rem;
  margin: 0 1.6rem;
  background: #fff;
`;

const SearchRow = styled.div`
  margin-bottom: 1rem;
`;

export const DiscountRate = styled.span`
  font-size: 2.4rem;
  line-height: 0.83;
  color: #dd4124;
  font-weight: 600;
`;

export const Price = styled.span<{ single?: boolean }>`
  font-size: 2.4rem;
  color: #dd4326;
  font-weight: 600;
  margin-left: ${({ single }) => !single && '0.6rem'};
`;

export const Origin = styled.span`
  font-size: 1.8rem;
  color: #b1b3b2;
  line-height: 1.11;
  margin-left: 0.6rem;
  text-decoration: line-through;
`;

export const CustomRow = styled.div`
  margin-bottom: 1rem;
`;

export const DnDWrapper = styled.div`
  padding: 1rem;
`;
