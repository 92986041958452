import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { RootState } from 'modules/rootState';

dayjs.extend(utc);

export default function useFilterModel() {
  const { reservation, point, charge, delivery, payment, paymentTreat, user } = useSelector(
    (state: RootState) => state.filter
  );

  const { status, path, hospitalName, userName, userPhonenum, range } = reservation;
  const { eventType, range: pointRange, userId, itemName } = point;
  const { action, range: chargeRange, hospitalName: chargeHospitalName, description } = charge;
  const { status: deliveryStatus, pharmacyName, deliveryNumber, name } = delivery;
  const {
    productId,
    paid,
    range: paymentRange,
    clientName: paymentClientName,
    userName: paymentUserName,
  } = payment;

  const reservationFilterModel = Object.assign(
    {},
    status !== 'A01' && { status },
    path !== 'All' && { path },
    userName.trim() !== '' && { username: userName },
    userPhonenum !== '' && { userPhonenum },
    hospitalName.trim() !== '' && { hospitalName },
    range.every((value) => value !== null) && {
      date: {
        startDate: range[0].utc(),
        endDate: range[1].utc(),
      },
    }
  );

  const pointFilterModel = Object.assign(
    {},
    eventType !== 'All' && { eventType },
    userId.trim() !== '' && { userId: Number(userId) },
    itemName.trim() !== '' && { itemName },
    pointRange.every((value) => value !== null) && {
      date: {
        startDate: pointRange[0].utc(),
        endDate: pointRange[1].utc(),
      },
    }
  );

  const chargeFilterModel = Object.assign(
    {},
    action !== 'All' && { action },
    chargeHospitalName.trim() !== '' && { hospitalName: chargeHospitalName },
    description.trim() !== '' && { description },
    chargeRange.every((value) => value !== null) && {
      date: {
        startDate: chargeRange[0].utc(),
        endDate: chargeRange[1].utc(),
      },
    }
  );

  const deliveryFilterModel = Object.assign(
    {},
    deliveryStatus !== 0 && { status: deliveryStatus },
    name !== '' && { name },
    pharmacyName !== '' && { pharmacyName },
    deliveryNumber !== '' && { deliveryNumber }
  );

  const paymentFilterModel = Object.assign(
    {},
    productId !== '0' && { productId },
    paid !== '2' && { paid: paid === '1' },
    paymentRange.every((value) => value !== null) && {
      startDate: dayjs(paymentRange[0].utc()).format('YYYY-MM-DD HH:mm:ss'),
      endDate: dayjs(paymentRange[1].utc()).format('YYYY-MM-DD HH:mm:ss'),
    },
    paymentClientName !== '' && { clientName: paymentClientName },
    paymentUserName !== '' && { userName: paymentUserName }
  );

  const paymentTreatmentFilterModel = Object.assign(
    {},
    paymentTreat.status && { status: paymentTreat.status },
    paymentTreat.range.every((value) => value !== null) && {
      startDate: dayjs(paymentTreat[0].utc()).format('YYYY-MM-DD HH:mm:ss'),
      endDate: dayjs(paymentTreat[1].utc()).format('YYYY-MM-DD HH:mm:ss'),
    },
    paymentTreat.userName !== '' && { userName: paymentTreat.userName },
    paymentTreat.hospitalName !== '' && { hospitalName: paymentTreat.hospitalName },
    paymentTreat.pharmacyName !== '' && { pharmacyName: paymentTreat.pharmacyName }
  );

  const userFilterModel = Object.assign(
    {},
    user.id !== '' && { id: user.id },
    user.name !== '' && { name: user.name },
    user.email !== '' && { email: user.email },
    user.phonenum !== '' && { phonenum: user.phonenum }
  );

  return {
    reservationFilterModel,
    pointFilterModel,
    chargeFilterModel,
    deliveryFilterModel,
    paymentFilterModel,
    paymentTreatmentFilterModel,
    userFilterModel,
  };
}
