import { useMutation, useQuery } from '@apollo/client';

import { GetEventsAll, UpdateEvent } from 'graphql/event.query';
import { IReqUpdateEvent, IResUpdateEvent } from 'modules/event/event.models';

export type IEvent = {
  id: string;
  name: string;
  therapyName: string;
  visible: boolean;
  priceDescription: string;
  originalPrice: number;
  discountRate: number;
  discountedPrice: number;
  chargePrice: number;
  contentImgUrls: string[];
  thumbnailUrl: string;
  hospitalBranchName: string;
  hospital: {
    name: string;
  };
  useBlock: boolean;
  isDelete: boolean;
  buttonType: number;
  description: string;
  description2: string;
  isEnableKakao: boolean;
  isEnableKakaoHuser: boolean;
  crmViewMode: number;
  isEnableCommunity: boolean;
  eventTags: any;
};

interface IRes {
  events: {
    data: IEvent[];
  };
}

export default function useEventList() {
  // TODO: 전체 이벤트 가져오는 api요청
  const pagination = { perPage: 9999 };

  const { data } = useQuery<IRes>(GetEventsAll, { variables: { pagination } });

  const [updateEvent] = useMutation<IResUpdateEvent, IReqUpdateEvent>(UpdateEvent, {
    refetchQueries: [{ query: GetEventsAll, variables: { pagination } }],
  });

  if (!data) {
    return {
      events: [],
      updateEvent,
    };
  }

  return { events: data.events.data, updateEvent };
}
