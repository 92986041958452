import gql from 'graphql-tag';

// https://api-dev-manager.odoctor.co.kr/graphql
export const GetEventsAll = gql`
  query GetEvents($pagination: PaginationInput) {
    events(pagination: $pagination) {
      data {
        id
        name
        therapyName
        visible
        priceDescription
        hospitalBranchName
        originalPrice
        discountRate
        discountedPrice
        chargePrice
        hasAgency
        contentImgUrls
        thumbnailUrl
        categories {
          id
          name
        }
        hospital {
          id
          name
        }
        useBlock
        isDelete
        buttonType
        description
        description2
        isEnableKakao
        crmViewMode
        isEnableKakaoHuser
        isEnableCommunity
        eventTags {
          id
          name
          type
          isPickVisible
          isVisible
          isDelete
        }
      }
    }
  }
`;

export const UpdateEvent = gql`
  mutation UpdateEvent($input: EventUpdateInput) {
    updateEvent(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const GetEventCategories = gql`
  query GetEventCategories {
    eventCategories {
      id
      name
    }
  }
`;

// 이벤트 생성
export const CreateEvent = gql`
  mutation CreateEvent($input: EventCreationInput) {
    createEvent(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

// create signed url
export const CreateSignedUrl = gql`
  mutation CreateSignedUrl($filename: String!, $filetype: String!) {
    signS3(filename: $filename, filetype: $filetype) {
      signedRequest
      url
    }
  }
`;

// getEvents v2-odoctor-app
export const GetEvents = gql`
  query GetEvents($pagination: PaginationInput, $filter: EventListFilter) {
    events(pagination: $pagination, filter: $filter) {
      data {
        id
        name
      }
    }
  }
`;

// apply event v2-odoctor-app
export const ApplyEvent = gql`
  mutation ApplyEvent($input: CreateEventReservationInput!) {
    createEventReservation(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;
