import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QueryLazyOptions } from '@apollo/client';
import { Button, Input, Select } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ko_KR';

import { RootState } from 'modules/rootState';
import { DatePicker } from 'components/CustomPicker';
import { setChargeFilter, resetChargeFilter } from 'modules/filter/filter.actions';
import useFilterModel from 'hooks/useFilterModel';
import { HospitalPointTransactions } from 'odoc-interface-module/odoc-manager-front/hospitalPointTransactions';
import { ChargeAction } from 'odoc-interface-module/common/status';

const { Option } = Select;

interface IChargeFilter {
  getChargeHistory: (
    options?: QueryLazyOptions<HospitalPointTransactions.Request> | undefined
  ) => void;
  setShowInput: Dispatch<SetStateAction<boolean>>;
}

export default function ChargeFilter({ getChargeHistory, setShowInput }: IChargeFilter) {
  const dispatch = useDispatch();
  const { chargeFilterModel } = useFilterModel();
  const { action, range, hospitalName, description } = useSelector(
    (state: RootState) => state.filter.charge
  );

  const onSubmit = () => {
    getChargeHistory({ variables: { filter: chargeFilterModel } });
  };

  return (
    <div style={{ display: 'flex', margin: '1rem 0 2rem 0' }}>
      <Select
        value={action}
        onChange={(value) => dispatch(setChargeFilter({ key: 'action', value }))}
        showArrow={false}
        listHeight={369}
        style={{ width: 120, marginRight: '1rem' }}
      >
        {Object.entries(ChargeAction).map(([key, value]) => (
          <Option value={key} key={key}>
            {value}
          </Option>
        ))}
      </Select>
      <DatePicker.RangePicker
        locale={locale}
        value={range}
        onChange={(value) => {
          const calEndDay = value?.map((v, idx) => {
            if (idx === 1) {
              return v?.endOf('day');
            }

            return v?.startOf('day');
          });

          dispatch(setChargeFilter({ key: 'range', value: calEndDay || [null, null] }));
        }}
        ranges={{
          오늘: [dayjs(), dayjs()],
          이번주: [dayjs().startOf('week'), dayjs().endOf('week')],
          이번달: [dayjs().startOf('month'), dayjs().endOf('month')],
          '3개월': [dayjs().subtract(3, 'month'), dayjs()],
          '6개월': [dayjs().subtract(6, 'month'), dayjs()],
          '1년': [dayjs().subtract(12, 'month'), dayjs()],
        }}
      />
      <Input
        placeholder="병원 이름"
        value={hospitalName}
        onChange={(e) => dispatch(setChargeFilter({ key: 'hospitalName', value: e.target.value }))}
        onKeyUp={(e) => e.key === 'Enter' && onSubmit()}
        style={{ width: 200, margin: '0 1rem' }}
      />
      <Input
        placeholder="내역/예약ID"
        value={description}
        onChange={(e) => dispatch(setChargeFilter({ key: 'description', value: e.target.value }))}
        onKeyUp={(e) => e.key === 'Enter' && onSubmit()}
        style={{ width: 200 }}
      />
      <Button
        style={{ margin: '0 0.5rem 0 2rem' }}
        onClick={() => {
          dispatch(resetChargeFilter());
          getChargeHistory();
        }}
      >
        검색 초기화
      </Button>
      <Button type="primary" onClick={onSubmit}>
        검색
      </Button>
      <Button type="primary" onClick={() => setShowInput(true)} style={{ marginLeft: '0.5rem' }}>
        충전금 내역 추가
      </Button>
    </div>
  );
}
