import React, { useEffect } from 'react';
import styled from 'styled-components';
import WritePostComponent from './WritePost';
import FakeUserSelectorComponent from './FakeUserSelector';
import WritePostCommentComponent from './WritePostComment';

import useUser from 'hooks/userUser';
import usePostJujak from 'hooks/usePostJujak';

import { Divider } from 'antd';

export default function UserList() {
  const { getUsers } = useUser();
  const { getFakeUser } = usePostJujak(() => {
    alert('완료');
  });

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <Container>
      <FakeUserSelectorComponent getFakeUser={getFakeUser} />
      <Divider />
      <WritePostComponent />
      <Divider />
      <WritePostCommentComponent />
    </Container>
  );
}

const Container = styled.div`
  min-width: 1200px;
  padding: 1.6rem;
  margin: 0 1.6rem;
  background: #fff;
`;
