import { all, fork } from 'redux-saga/effects';

import authSaga from 'modules/auth/auth.sagas';
import eventSaga from 'modules/event/event.sagas';
import hospitalSage from 'modules/hospital/hospital.sagas';
import philingSaga from 'modules/philing/philing.saga';
import blacklistSaga from './eventBlacklist/blacklist.sagas';
import postSaga from './post/post.sagas';
import managePostSaga from './managePost/managePost.sagas';
import managePostCatSaga from './managePostCategory/managePostCat.sagas';
import manageTagSaga from './manageTag/manageTag.sagas';

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(eventSaga),
    fork(philingSaga),
    fork(hospitalSage),
    fork(blacklistSaga),
    fork(postSaga),
    fork(managePostSaga),
    fork(managePostCatSaga),
    fork(manageTagSaga),
  ]);
}
