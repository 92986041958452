import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Drawer, Empty, Input, Select, Alert } from 'antd';

import useChargeHistory from 'hooks/useChargeHistory';
import {
  resetChargeInput,
  selectTransactionType,
  setChargeByKey,
} from 'modules/chargeHistory/chargeHistory.actions';
import { RootState } from 'modules/rootState';
import useChargeInputModel from 'hooks/useChargeInputModel';
import {
  TransactionRefund,
  TransactionStrings,
  Transaction,
} from 'odoc-interface-module/common/status';

const { Search } = Input;
const { Option } = Select;

interface IInputChargeHistory {
  showInput: boolean;
  setShowInput: Dispatch<SetStateAction<boolean>>;
}

const initialDisabled = { action: '', price: '', path: '' };
export default function InputChargeHistory({ showInput, setShowInput }: IInputChargeHistory) {
  const dispatch = useDispatch();
  const disabled = useRef(initialDisabled);

  const { hospitalId, hospitalName, balance, transactionType, charge, reservationId, reservation } =
    useSelector((state: RootState) => state.chargeHistory);
  disabled.current.path = reservation.path;

  const {
    getTransactionTypes,
    getHospital,
    getReservation,
    createHospitalPointTransaction,
    getHospitalLoading,
    getReservationLoading,
  } = useChargeHistory();

  const model = useChargeInputModel();

  useEffect(() => {
    getTransactionTypes();
  }, [getTransactionTypes]);

  const onSearchHospital = () => {
    getHospital({ variables: { id: hospitalId } });
  };

  const onSearchReservation = () => {
    getReservation({ variables: { id: reservationId } });
  };

  const onClose = () => {
    setShowInput(false);
    dispatch(resetChargeInput());
    disabled.current = initialDisabled;
  };

  const onSubmit = () => {
    createHospitalPointTransaction({ variables: { input: model } });
    onClose();
  };

  const renderPriceOrId = () => {
    const [transaction] = transactionType.filter((transaction) => transaction.selected);
    if (!transaction) {
      return null;
    }

    if (TransactionRefund[transaction.id]) {
      return (
        <div>
          <div>
            <span style={{ width: '50px' }}>예약 ID</span>
            <Search
              loading={getReservationLoading}
              enterButton
              onChange={(e) =>
                dispatch(setChargeByKey({ key: 'reservationId', value: e.target.value }))
              }
              onKeyUp={(e) => e.key === 'Enter' && onSearchReservation()}
              onSearch={() => onSearchReservation()}
              value={reservationId}
              min={1}
              type="number"
            />
          </div>
          <Alert message="병원명, 이벤트 맵핑 확인" type="warning" style={{ marginTop: '1rem' }} />
          <div style={{ display: 'flex', marginTop: '1rem' }}>
            <Input disabled value={reservation.path} />
            <Input disabled value={reservation.price} style={{ marginLeft: '1rem' }} />
          </div>
        </div>
      );
    } else {
      return (
        <>
          <span style={{ width: '50px' }}>금액</span>
          <Input
            type="number"
            onChange={(e) => {
              dispatch(setChargeByKey({ key: 'charge', value: e.target.value }));
              disabled.current.price = e.target.value;
            }}
            value={charge}
          />
        </>
      );
    }
  };

  let isDisabled = true;
  if (TransactionRefund[disabled.current.action]) {
    isDisabled = disabled.current.path.trim() === '';
  } else {
    isDisabled = disabled.current.price === '';
  }

  return (
    <Drawer
      title="충전금 내역 추가"
      placement="right"
      width={'40%'}
      onClose={onClose}
      visible={showInput}
    >
      <span>병원 ID</span>
      <Search
        loading={getHospitalLoading}
        enterButton
        onChange={(e) => dispatch(setChargeByKey({ key: 'hospitalId', value: e.target.value }))}
        onKeyUp={(e) => e.key === 'Enter' && onSearchHospital()}
        onSearch={() => onSearchHospital()}
        min={1}
        type="number"
        value={hospitalId}
      />
      {hospitalName.trim() === '' ? (
        <Empty style={{ margin: '2rem 0' }} />
      ) : (
        <div>
          <Divider plain>추가</Divider>
          <div style={{ display: 'flex', marginTop: '1rem' }}>
            <div style={{ flex: 1 }}>
              <Input disabled value={hospitalName} />
            </div>
            <div style={{ flex: 1, marginLeft: '1rem' }}>
              <Input
                disabled
                value={`${balance.toLocaleString('ko', { maximumFractionDigits: 1 })}원`}
              />
            </div>
          </div>
          <div style={{ marginTop: '1rem', display: 'flex' }}>
            <Select
              onChange={(value: TransactionStrings) => {
                dispatch(selectTransactionType(value));
                disabled.current.action = value;
              }}
              style={{ flex: 1 }}
              listHeight={400}
            >
              {Object.entries(Transaction).map(([key, value]) => (
                <Option value={key} key={key}>
                  {value}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ flex: 1, marginTop: '1rem', alignItems: 'center' }}>
            {renderPriceOrId()}
          </div>
          <Button
            style={{ width: '100%', marginTop: '3rem' }}
            type="primary"
            onClick={onSubmit}
            disabled={isDisabled}
          >
            적용
          </Button>
        </div>
      )}
    </Drawer>
  );
}
