import { createAction, ActionType } from 'typesafe-actions';

import { IPointItems, IResUser } from 'components/Input/InputPointHistory.models';

interface IPointPayload {
  key: string;
  value: any;
}

export const SET_POINT_HISTORY_USER = 'SET_POINT_HISTORY_USER';
export const setPointHistoryUser = createAction(SET_POINT_HISTORY_USER)<IResUser>();

export const SET_POINT_BY_KEY = 'SET_POINT_BY_KEY';
export const setPointByKey = createAction(SET_POINT_BY_KEY)<IPointPayload>();

export const RESET_POINT_HISTORY = 'RESET_POINT_HISTORY';
export const resetPointHistory = createAction(RESET_POINT_HISTORY)<'unmount' | 'user'>();

export const SET_POINT_HISTORY = 'SET_POINT_HISTORY';
export const setPointHistory = createAction(SET_POINT_HISTORY)<any>();

export const SET_POINT_ITEMS = 'SET_POINT_ITEMS';
export const setPointItems = createAction(SET_POINT_ITEMS)<IPointItems>();

export const SELECTED_POINT_ITEM = 'SELECTED_POINT_ITEM';
export const selectedPointItem = createAction(SELECTED_POINT_ITEM)<string>();

const actions = {
  setPointHistoryUser,
  resetPointHistory,
  setPointByKey,
  setPointItems,
  selectedPointItem,
  setPointHistory,
};

export type PointHistoryActions = ActionType<typeof actions>;
