import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  PageHeader,
  Table,
  Popconfirm,
  Tag,
  Button,
  Drawer,
  Input,
  Popover,
  Switch,
  Select,
  Modal,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import LabelRow from 'components/CreateEvent/LabelRow';

import { actionUpdatePost } from 'modules/post/post.actions';

import { DeActivateUser, GetUsers } from 'graphql/user.query';
import UserFilter from 'components/Filter/UserFilter';
import useFilterModel from 'hooks/useFilterModel';
import useUser from 'hooks/userUser';
import usePostManage from 'hooks/usePost';
import usePostCatManage from 'hooks/usePostCat';
import { useDispatch } from 'react-redux';
const { confirm } = Modal;

const { Search, TextArea } = Input;

export default function ManagePost() {
  const [showDetail, setShowDetail] = useState(false);
  const { userFilterModel } = useFilterModel();
  // const { getUsers, list } = useUser();
  const { getPostList, list, pagination, updatePost } = usePostManage(() => {
    alert('완료');
  });
  const dispatch = useDispatch();

  const [post, setPost] = useState<any>({});
  const [change, setChange] = useState({ id: post.id });

  useEffect(() => {
    // getUsers();
    getPostList();
  }, [getPostList]);

  const onClickSubmit = () => {
    if (Object.keys(change).length === 0) return;
    confirm({
      title: '수정하겠습니까?',
      onOk() {
        dispatch(actionUpdatePost({ callback: updatePost, post: change, postImages: [] }));
      },
    });
  };

  const { getPostCatList, list: catList } = usePostCatManage();

  useEffect(() => {
    getPostCatList({
      variables: {
        pagination: {
          perPage: 30,
          currentPage: 1,
        },
      },
    });
  }, [getPostCatList]);

  const onChangePost = (key) => (value) => {
    const v = typeof value === 'object' ? value.target.value : value;
    if (key === 'categoryId') {
      setPost({ ...post, category: { id: v } });
    } else {
      setPost({ ...post, [key]: v });
    }

    setChange({ ...change, [key]: v, id: post.id });
  };

  // const [deActivateUser] = useMutation(DeActivateUser, {
  //   refetchQueries: [
  //     {
  //       query: GetUsers,
  //       variables: {
  //         pagination: {
  //           currentPage: pagination.currentPage,
  //         },
  //       },
  //     },
  //   ],
  // });

  const columns = [
    {
      title: '아이디',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '작성자',
      dataIndex: ['user', 'name'],
      key: 'user',
    },
    {
      title: '카테고리',
      dataIndex: ['category', 'title'],
      key: 'cat',
    },
    {
      title: '태그',
      dataIndex: 'tags',
      key: 'tags',
      render: (dd) => {
        return <div>{dd.map(({ name }) => name).join()}</div>;
      },
    },
    {
      title: '작성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => <div>{dayjs(date).format('YYYY.MM.DD')}</div>,
    },
    {
      title: '베스트 지정여부',
      dataIndex: 'isBest',
      key: 'isBest',
      render: (isBest, record) => (
        <div>
          {isBest ? (
            <div>
              <Popconfirm
                title="베스트 취소?"
                // onConfirm={(e) =>
                //   deActivateUser({
                //     variables: {
                //       id: record.id,
                //     },
                //   })
                // }
                okText="확인"
                cancelText="취소"
              >
                <Tag color="#2db7f5" style={{ cursor: 'pointer' }}>
                  베스트
                </Tag>
              </Popconfirm>
            </div>
          ) : (
            <Tag color="#f50">-</Tag>
          )}
        </div>
      ),
    },
    {
      title: '픽 노출여부',
      dataIndex: 'isPickShow',
      key: 'isPickShow',
      render: (isPickShow, record) => (
        <div>
          {isPickShow ? (
            <div>
              <Popconfirm
                title="레알 비 노출?"
                // onConfirm={(e) =>
                //   deActivateUser({
                //     variables: {
                //       id: record.id,
                //     },
                //   })
                // }
                okText="확인"
                cancelText="취소"
              >
                <Tag color="#2db7f5" style={{ cursor: 'pointer' }}>
                  노출
                </Tag>
              </Popconfirm>
            </div>
          ) : (
            <Tag color="#f50">비노출</Tag>
          )}
        </div>
      ),
    },
    {
      title: '삭제여부',
      dataIndex: 'isDelete',
      key: 'isDelete',
      render: (isDelete, record) => (
        <div>
          {!isDelete ? (
            <div>
              <Popconfirm
                title="레알 삭제?"
                // onConfirm={(e) =>
                //   deActivateUser({
                //     variables: {
                //       id: record.id,
                //     },
                //   })
                // }
                okText="삭제 ㄱㄱ"
                cancelText="ㄴㄴ"
              >
                <Tag color="#2db7f5" style={{ cursor: 'pointer' }}>
                  -
                </Tag>
              </Popconfirm>
            </div>
          ) : (
            <Tag color="#f50">삭제됨</Tag>
          )}
        </div>
      ),
    },
    {
      title: '',
      key: 'detail',
      width: '10rem',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            const [selectPost] = list.filter(({ id }) => id === record.id);
            setPost({
              ...selectPost,
              tags: selectPost.tags.map(({ name }) => name).join(','),
            });
            console.info(selectPost);

            setShowDetail(true);
          }}
        >
          상세
        </Button>
      ),
    },
  ];

  return (
    <Container>
      <PageHeader title="커뮤니티" subTitle="관리하기" />
      <SearchRow>
        <UserFilter />
      </SearchRow>
      <Table
        columns={columns}
        dataSource={list}
        rowKey={(record) => record.id}
        pagination={{
          current: pagination.currentPage,
          total: pagination.total,
        }}
        onChange={(paging) =>
          getPostList({
            variables: {
              filter: userFilterModel,
              pagination: {
                perPage: paging.pageSize,
                currentPage: paging.current,
              },
            },
          })
        }
      />

      {/* 포스트 수정하기 Drawer */}
      <Drawer
        title="이벤트 상세"
        placement="right"
        width="70rem"
        onClose={() => setShowDetail(false)}
        visible={showDetail}
      >
        {/* <Alert
          message={
            <div>
              <p style={{ margin: 0 }}>다른거 고치고 싶으시면 @dev로 문의해주세여</p>
              <p style={{ margin: 0 }}>할인가,할인률,원가 표시안할거면 숫자0 입력</p>
            </div>
          }
          type="warning"
          style={{ marginBottom: '2rem' }}
        /> */}
        <LabelRow label="포스트 아이디">
          <Input disabled value={post.id} style={{ width: '100%' }} />
        </LabelRow>
        <LabelRow label="제목">
          <TextArea value={post.title} rows={2} onChange={onChangePost('title')} />
          {/* <Input value={post.name.replaceAll('$n', '')} style={{ width: '100%' }} /> */}
        </LabelRow>

        <LabelRow label="내용">
          <TextArea value={post.content} rows={5} onChange={onChangePost('content')} />
          {/* <Input value={post.name.replaceAll('$n', '')} style={{ width: '100%' }} /> */}
        </LabelRow>

        <LabelRow label="베스트">
          <Switch
            checked={post.isBest}
            checkedChildren="베스트"
            unCheckedChildren="-"
            onChange={onChangePost('isBest')}
          />
        </LabelRow>

        <LabelRow label="픽노출">
          <Switch
            checked={post.isPickShow}
            checkedChildren="노출"
            unCheckedChildren="비노출"
            onChange={onChangePost('isPickShow')}
          />
        </LabelRow>

        <LabelRow label="삭제">
          <Switch
            checked={post.isDelete}
            checkedChildren="삭제"
            unCheckedChildren="-"
            onChange={onChangePost('isDelete')}
          />
        </LabelRow>

        <LabelRow label="카테고리">
          <Select<string>
            // mode="multiple"
            placeholder="최소 1개 이상 선택, 카테고리명으로 검색"
            filterOption
            optionFilterProp="children"
            allowClear
            onChange={onChangePost('categoryId')}
            value={post.category?.id as any}
            style={{ width: '40rem' }}
          >
            {catList
              .filter(({ isDelete, isVisible }) => !isDelete && isVisible)
              .map(({ title, id }, idx) => {
                return (
                  <Select.Option value={id} key={idx}>
                    {title} ({id})
                  </Select.Option>
                );
              })}
          </Select>
        </LabelRow>

        <LabelRow label="태그">
          <Input
            value={post.tags}
            onChange={onChangePost('tags')}
            style={{ width: '100%' }}
            placeholder="태그1,태그2"
          />
        </LabelRow>

        <Button onClick={onClickSubmit} style={{ width: '100%' }} type="primary">
          확인
        </Button>
      </Drawer>
    </Container>
  );
}

const Container = styled.div`
  min-width: 1200px;
  padding: 1.6rem;
  margin: 0 1.6rem;
  background: #fff;
`;

const SearchRow = styled.div`
  margin-bottom: 1rem;
`;

export const DiscountRate = styled.span`
  font-size: 2.4rem;
  line-height: 0.83;
  color: #dd4124;
  font-weight: 600;
`;

export const Price = styled.span<{ single?: boolean }>`
  font-size: 2.4rem;
  color: #dd4326;
  font-weight: 600;
  margin-left: ${({ single }) => !single && '0.6rem'};
`;

export const Origin = styled.span`
  font-size: 1.8rem;
  color: #b1b3b2;
  line-height: 1.11;
  margin-left: 0.6rem;
  text-decoration: line-through;
`;

export const CustomRow = styled.div`
  margin-bottom: 1rem;
`;

export const DnDWrapper = styled.div`
  padding: 1rem;
`;
