import React, { ChangeEvent, Dispatch } from 'react';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';

import { IEventPoster } from 'modules/event/event.models';
import { reorder } from 'utils/createEventHelper';

interface IInputFileMultiple {
  index?: number;
  eventPosters: IEventPoster[];
  setEventPosters: Dispatch<React.SetStateAction<IEventPoster[]>>;
}

export default function InputFileMultiple({
  index,
  eventPosters,
  setEventPosters,
}: IInputFileMultiple) {
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(eventPosters, result.source.index, result.destination.index);
    setEventPosters(items);
  };

  const GIF_MAX_SIZE = 5000;
  const IMAGE_MAX_SIZE = 1000;
  const handleFileOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files) return;
    Array.from(e.target.files).forEach((file) => {
      let imageMaxSize = 0;

      if (file.type.match(/gif/gi)) {
        imageMaxSize = GIF_MAX_SIZE;
      }

      if (file.type.match(/webp|png|jpg|jpeg/gi)) {
        imageMaxSize = IMAGE_MAX_SIZE;
      }

      // 이미지는 개당 400kb를 넘으면 안된다
      if (Math.ceil(file.size / 1000) > imageMaxSize) {
        message.error(`${file.name} 이미지 용량초과`);
        return;
      }

      const reader = new FileReader();
      reader.onload = () =>
        setEventPosters((state) => [...state, { file, preview: reader.result }]);
      reader.readAsDataURL(file);
    });
  };

  const onClickButtonRemove = (index: number) => () => {
    setEventPosters(eventPosters.filter((_, idx) => idx !== index));
  };

  return (
    <Container>
      <div style={{ overflowX: 'auto' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <List ref={provided.innerRef} {...provided.droppableProps}>
                {eventPosters.map((item, index) => (
                  <Draggable key={`d-${index}`} draggableId={`d-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <Preview
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ButtonRemove onClick={onClickButtonRemove(index)}>⌫</ButtonRemove>
                        <PreviewImage src={item.preview as string} alt="preview" />
                      </Preview>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div>
        <Label htmlFor={`input-file-multiple${index && '-' + index}`}>
          <PlusOutlined />
        </Label>
        <Input
          type="file"
          accept="image/webp, image/gif, image/png, image/jpeg"
          id={`input-file-multiple${index && '-' + index}`}
          multiple
          onChange={handleFileOnChange}
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  border: 1px solid #d9d9d9;
  cursor: pointer;
`;

const Input = styled.input`
  display: none;
`;

const List = styled.div`
  display: flex;
`;

const Preview = styled.div`
  margin-right: 1rem;
  position: relative;
`;

const ButtonRemove = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: rgb(0 0 0 / 20%);
  cursor: pointer;
  color: #fff;
  font-size: 2rem;
  border-radius: 1rem;
`;

const PreviewImage = styled.img`
  width: 10.2rem;
  height: 10.2rem;
  object-fit: cover;
`;
