import dayjs from 'dayjs';

import { IAuthPayload, IAuthState } from 'modules/auth/auth.models';

class AuthHelper {
  isSignedIn() {
    try {
      const { id_token } = this.getUser();
      return !!id_token;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  getUser(): IAuthState {
    try {
      const user = localStorage.getItem('user');
      if (user) {
        return JSON.parse(user);
      } else {
        return { email: '', imageUrl: '', expires_at: 0, expires_in: 0, id_token: '' };
      }
    } catch (error) {
      console.error(error);
      return { email: '', imageUrl: '', expires_at: 0, expires_in: 0, id_token: '' };
    }
  }

  setUser(user: IAuthPayload) {
    try {
      localStorage.setItem(
        'user',
        JSON.stringify({
          email: user.email,
          imageUrl: user.imageUrl,
          expires_at: user.expires_at,
          expires_in: user.expires_in,
          id_token: user.id_token,
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  resetToken() {
    localStorage.removeItem('user');
  }

  isExpired() {
    const now = dayjs();
    const { expires_at } = this.getUser();
    return now.isAfter(dayjs(expires_at));
  }
}

export default new AuthHelper();
