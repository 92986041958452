import React from 'react';
import { useSelector } from 'react-redux';
import { Input, InputNumber, Button, Modal, Form, Typography } from 'antd';

import usePostJujak from 'hooks/usePostJujak';

import { RootState } from 'modules/rootState';

export default function WritePostCommentComponent() {
  const { TextArea } = Input;
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const { Title } = Typography;

  const { createFakePostComment } = usePostJujak(() => {
    alert('완료');
    form.resetFields();
  });

  const fakePost = useSelector((state: RootState) => state.fakePost);

  const onFinish = ({ ...values }: any) => {
    confirm({
      title: '댓글을 생성하겠습니까?',
      onOk() {
        createFakePostComment({
          variables: {
            input: values,
          },
        });
      },
    });
  };

  return (
    <div>
      <Title level={3}>댓글 쓰기</Title>

      <Form
        onFinish={onFinish}
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        //  disabled={componentDisabled}
        style={{ maxWidth: 600 }}
        fields={[{ name: ['userId'], value: fakePost.fakeUser?.id }]}
      >
        <Form.Item label="유저ID" name="userId" rules={[{ required: true }]}>
          <Input placeholder="1" style={{ width: '40rem' }} />
        </Form.Item>

        <Form.Item label="본문ID" name="postId" rules={[{ required: true }]}>
          <InputNumber min={1} max={9999} placeholder="본문 id" style={{ width: '40rem' }} />
        </Form.Item>

        <Form.Item label="댓글" name="comment" rules={[{ required: true }]}>
          <TextArea
            placeholder="롤은 하나요??"
            autoSize={{ minRows: 3, maxRows: 5 }}
            style={{ width: '40rem' }}
          />
        </Form.Item>

        <Button type="primary" style={{ width: '20rem' }} htmlType="submit">
          댓글 쓰기
        </Button>
      </Form>
    </div>
  );
}
