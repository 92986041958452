import { createAction, ActionType } from 'typesafe-actions';

import { IAuthPayload } from './auth.models';

export const SET_AUTH = 'SET_AUTH';
export const setAuth = createAction(SET_AUTH)<IAuthPayload>();

export const SET_LOGOUT = 'SET_LOGOUT';
export const setLogout = createAction(SET_LOGOUT)();

const actions = { setAuth, setLogout };

export type AuthActions = ActionType<typeof actions>;
