import React, { useEffect } from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { setAuth } from 'modules/auth/auth.actions';

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default function Login() {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <Container>
      <h1>오닥터 내부어드민</h1>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY as string}
        onSuccess={(result) => {
          if ('profileObj' in result) {
            const { email, imageUrl } = result.profileObj;
            const { expires_at, expires_in, id_token } = result.tokenObj;
            dispatch(setAuth({ email, imageUrl, expires_at, expires_in, id_token }));
          }
        }}
        onFailure={(result) => console.log('failure', result)}
      />
    </Container>
  );
}
