import gql from 'graphql-tag';

// 예약리스트
export const GetEventReservations = gql`
  query EventReservations($filter: eventReservationListFilter, $pagination: PaginationInput) {
    eventReservations(filter: $filter, pagination: $pagination) {
      data {
        id
        username
        userPhonenum
        userBirthYear
        userGender
        reservationPath
        event {
          id
          name
          description
          discountRate
          discountedPrice
          originalPrice
          applyCount
          therapyName
        }
        status
        discountRate
        discountedPrice
        originalPrice
        reservationTime
        memo
        chargePrice
        createdAt
        hospital {
          id
          name
          tel
          phones
          hiraCode
        }
        refund {
          type
        }
        contactableType
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const GetReservation = gql`
  query GetReservation($id: ID!) {
    eventReservation(id: $id) {
      id
      username
      reservationPath
      event {
        id
        name
        description
        discountRate
        discountedPrice
        originalPrice
        applyCount
      }
      status
      chargePrice
    }
  }
`;
