import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CommentOutlined, PhoneOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { numberWithCommas } from 'utils/numberWithCommas';
import useReservation from 'hooks/useReservations';
import ReservationFilter from 'components/Filter/ReservationFilter';
import useFilterModel from 'hooks/useFilterModel';
import { RootState } from 'modules/rootState';
import { showMemoModal } from 'modules/ui/ui.actions';
import { PathStrings, Path, Status } from 'odoc-interface-module/common/status';
import { EventReservations } from 'odoc-interface-module/odoc-manager-front/eventReservations';

export default function Reservations() {
  const dispatch = useDispatch();
  const { visible, memo } = useSelector((state: RootState) => state.ui.memoModal);
  const { getEventReservations, getEventReservationsToExcel, data, loading } = useReservation();
  const { reservationFilterModel } = useFilterModel();

  const columns: ColumnsType<EventReservations.IEventReservationData> = [
    {
      title: '신청일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <span>{dayjs(createdAt).format('YYYY.MM.DD HH:mm')}</span>,
    },
    {
      title: '병원',
      dataIndex: 'hospital',
      key: 'hospitalName',
      ellipsis: true,
      width: 200,
      render: (hospital) => (
        <span>
          {hospital.name}({hospital.id})
        </span>
      ),
    },
    {
      title: '유저',
      dataIndex: 'username',
      key: 'username',
      render: (username, record) => (
        <>
          <div>
            <div>{username}</div>
            <div>
              {record.userGender && record.userGender === 'male' ? '남자 ' : '여자 '}
              {record.userBirthYear && `${dayjs().year() - record.userBirthYear + 1}세`}
            </div>
            <div style={{ display: 'flex', gap: '0.3rem', alignItems: 'center' }}>
              {[<PhoneOutlined />, <CommentOutlined />].filter(
                (c, i) => i + 1 == record.contactableType
              )}
              <span>{record.userPhonenum}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      title: '이벤트명',
      dataIndex: 'reservationPath',
      key: 'reservationPath',
      ellipsis: true,
      width: 300,
      render: (text: PathStrings, data) => {
        return (
          <>
            <div>
              <span>
                <Tag color={text === 'Weekly' ? 'blue' : 'orange'}>{Path[text]}</Tag>
                <span>{data.event.therapyName}</span>
              </span>
            </div>
            <div>
              <Tag>예약 ID: {data.id}</Tag>
            </div>
          </>
        );
      },
    },
    {
      title: '가격 (할인)',
      dataIndex: 'discountedPrice',
      key: 'discountedPrice',
      width: '12rem',
      render: (discountedPrice, data) => {
        return (
          <>
            <div>{numberWithCommas(discountedPrice || '')}</div>
            {discountedPrice && <div>({data.discountRate}%)</div>}
          </>
        );
      },
    },
    {
      title: '과금액',
      dataIndex: 'chargePrice',
      key: 'chargePrice',
      width: '12rem',
      render: (chargePrice, record) => (
        <span style={record.refund ? { textDecoration: 'line-through' } : {}}>
          {numberWithCommas(chargePrice || '')}
        </span>
      ),
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (code) => <span>{Status[code]}</span>,
    },
    {
      title: '메모',
      dataIndex: 'memo',
      key: 'memo',
      width: '8rem',
      render: (memo) => {
        return (
          memo && (
            <Button type="link" onClick={() => dispatch(showMemoModal({ visible: true, memo }))}>
              메모
            </Button>
          )
        );
      },
    },
    {
      title: '예약일정',
      dataIndex: 'reservationTime',
      key: 'reservationTime',
      render: (reservationTime) => (
        <span>{reservationTime && dayjs(reservationTime).format('YYYY.MM.DD')}</span>
      ),
    },
  ];

  const pagination = {
    current: data?.eventReservations.pagination.currentPage,
    total: data?.eventReservations.pagination.total,
  };

  return (
    <div style={{ minWidth: '1400px', padding: '0 1.6rem' }}>
      <ReservationFilter
        getEventReservations={getEventReservations}
        getEventReservationsToExcel={getEventReservationsToExcel}
      />
      <Table<EventReservations.IEventReservationData>
        dataSource={data ? data.eventReservations.data : []}
        columns={columns}
        rowKey={(record) => record.id}
        pagination={pagination}
        loading={loading}
        onChange={(paging) => {
          getEventReservations({
            variables: {
              pagination: {
                perPage: paging.pageSize,
                currentPage: paging.current,
              },
              filter: reservationFilterModel,
            },
          });
        }}
      />
      <Modal
        title="메모"
        visible={visible}
        onOk={() => dispatch(showMemoModal({ visible: false, memo: '' }))}
        onCancel={() => dispatch(showMemoModal({ visible: false, memo: '' }))}
      >
        {memo &&
          memo.split('\n').map((v, i) => (
            <p key={i} style={{ margin: 0 }}>
              {v}
            </p>
          ))}
      </Modal>
    </div>
  );
}
