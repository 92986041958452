import { takeLatest, all, fork, getContext } from 'redux-saga/effects';
import { notification } from 'antd';

import AuthHelper from 'utils/AuthHelper';
import { setAuth, SET_AUTH, SET_LOGOUT } from './auth.actions';
import Http from '@http';

function* workerAuth(action: ReturnType<typeof setAuth>) {
  const history = yield getContext('history');
  AuthHelper.setUser(action.payload);
  Http.updateToken(action.payload.id_token);

  if (history.location.pathname === '/login') {
    history.replace('/');
  }
}

function* workerLogout() {
  const history = yield getContext('history');
  AuthHelper.resetToken();

  notification.success({
    message: '로그아웃',
    description: '로그아웃 완료',
    placement: 'bottomRight',
    duration: 5,
  });

  history.replace('/login', { status: 'logout' });
}

function* watchAuth() {
  yield takeLatest(SET_AUTH, workerAuth);
}

function* watchLogout() {
  yield takeLatest(SET_LOGOUT, workerLogout);
}

export default function* authSaga() {
  yield all([fork(watchAuth), fork(watchLogout)]);
}
