import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InputNumber, Button, Typography } from 'antd';

import useUser from 'hooks/userUser';

import { RootState } from 'modules/rootState';
import usePostCatManage from 'hooks/usePostCat';

const AvatarSelector = ({ user }) => {
  const { id, nickname, avatar } = user;

  return (
    <AvatarDiv>
      <div className="avatar">
        <img
          width="100%"
          height="100%"
          src={`https://images.odoctor.co.kr${avatar}`}
          alt="아바타"
        />
      </div>
      <div>
        <div>ID: {id}</div>
        <div>{nickname}</div>
      </div>
    </AvatarDiv>
  );
};

export default function FakeUserSelector({ getFakeUser }) {
  const { Title } = Typography;

  const { getPostCatList, list: catList } = usePostCatManage();

  useEffect(() => {
    getPostCatList({
      variables: {
        pagination: {
          perPage: 30,
          currentPage: 1,
        },
      },
    });
  }, [getPostCatList]);

  const [fakeUserIndex, setFUIndex] = useState<number>(1);

  const { getUsers, list, pagination } = useUser();

  const fakePost = useSelector((state: RootState) => state.fakePost);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div>
      <Title level={3}>유저 확인</Title>
      <InputNumber
        min={0}
        max={272}
        placeholder="숫자 0~69 사이"
        defaultValue={fakeUserIndex}
        onChange={(v) => {
          setFUIndex(v || 0);
        }}
      />
      <Button
        onClick={() => {
          getFakeUser({
            variables: {
              index: fakeUserIndex,
            },
          });
        }}
      >
        확인
      </Button>
      <div>
        <p>
          선택된 유저:{' '}
          {fakePost.fakeUser?.id ? <AvatarSelector user={fakePost.fakeUser} /> : '0~271 사이 숫자'}
        </p>
      </div>
    </div>
  );
}

const AvatarDiv = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;

  .avatar {
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
    width: 5rem;
    height: 5rem;
  }
`;

export const DiscountRate = styled.span`
  font-size: 2.4rem;
  line-height: 0.83;
  color: #dd4124;
  font-weight: 600;
`;

export const Price = styled.span<{ single?: boolean }>`
  font-size: 2.4rem;
  color: #dd4326;
  font-weight: 600;
  margin-left: ${({ single }) => !single && '0.6rem'};
`;

export const Origin = styled.span`
  font-size: 1.8rem;
  color: #b1b3b2;
  line-height: 1.11;
  margin-left: 0.6rem;
  text-decoration: line-through;
`;

export const CustomRow = styled.div`
  margin-bottom: 1rem;
`;

export const DnDWrapper = styled.div`
  padding: 1rem;
`;
