import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  PageHeader,
  Table,
  Popconfirm,
  Tag,
  Form,
  Drawer,
  Input,
  Switch,
  Space,
  Button,
  InputNumber,
  Modal,
} from 'antd';
import type { FormInstance } from 'antd/es/form';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';

import { DeActivateUser, GetUsers } from 'graphql/user.query';
import UserFilter from 'components/Filter/UserFilter';
import useFilterModel from 'hooks/useFilterModel';
import useUser from 'hooks/userUser';
import useTagManage, { useCreatePostTag } from 'hooks/useTag';

import { actionCreateTagList } from 'modules/manageTag/manageTag.actions';
const { confirm } = Modal;

export default function UserList() {
  const { userFilterModel } = useFilterModel();
  const { getTagList, list, pagination } = useTagManage();

  const [showAddPanel, setShowAddPanel] = useState(false);

  useEffect(() => {
    getTagList({
      variables: {
        pagination: {
          perPage: 30,
          currentPage: 1,
        },
      },
    });
  }, [getTagList]);

  // const [deActivateUser] = useMutation(DeActivateUser, {
  //   refetchQueries: [
  //     {
  //       query: GetUsers,
  //       variables: {
  //         pagination: {
  //           currentPage: pagination.currentPage,
  //         },
  //       },
  //     },
  //   ],
  // });

  const columns = [
    {
      title: '아이디',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '타입',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '픽노출',
      dataIndex: 'isPickVisible',
      key: 'isPickVisible',
      render: (isPickVisible, record) => (
        <div>
          {isPickVisible ? (
            <div>
              <Popconfirm
                title="레알 비활성화?"
                // onConfirm={(e) =>
                //   deActivateUser({
                //     variables: {
                //       id: record.id,
                //     },
                //   })
                // }
                okText="비활성 ㄱㄱ"
                cancelText="ㄴㄴ"
              >
                <Tag color="#2db7f5" style={{ cursor: 'pointer' }}>
                  T
                </Tag>
              </Popconfirm>
            </div>
          ) : (
            <Tag color="#f50">-</Tag>
          )}
        </div>
      ),
    },
    {
      title: '숨김',
      dataIndex: 'isVisible',
      key: 'isVisible',
      render: (isVisible, record) => (
        <div>
          {isVisible ? (
            <div>
              <Popconfirm
                title="레알 비활성화?"
                // onConfirm={(e) =>
                //   deActivateUser({
                //     variables: {
                //       id: record.id,
                //     },
                //   })
                // }
                okText="비활성 ㄱㄱ"
                cancelText="ㄴㄴ"
              >
                <Tag color="#2db7f5" style={{ cursor: 'pointer' }}>
                  T
                </Tag>
              </Popconfirm>
            </div>
          ) : (
            <Tag color="#f50">-</Tag>
          )}
        </div>
      ),
    },
    {
      title: '삭제',
      dataIndex: 'isDelete',
      key: 'isDelete',
      render: (isActive, record) => (
        <div>
          {isActive ? (
            <div>
              <Popconfirm
                title="레알 비활성화?"
                // onConfirm={(e) =>
                //   deActivateUser({
                //     variables: {
                //       id: record.id,
                //     },
                //   })
                // }
                okText="비활성 ㄱㄱ"
                cancelText="ㄴㄴ"
              >
                <Tag color="#2db7f5" style={{ cursor: 'pointer' }}>
                  활성
                </Tag>
              </Popconfirm>
            </div>
          ) : (
            <Tag color="#f50">비활성</Tag>
          )}
        </div>
      ),
    },
  ];

  const onClickOpenPanel = () => {
    setShowAddPanel(true);
  };

  const onClickClosePanel = () => {
    setShowAddPanel(false);
  };

  return (
    <Container>
      <PageHeader title="태그" subTitle="관리하기" />
      <div onClick={onClickOpenPanel}>태그 추가</div>
      {/* <SearchRow>
        <UserFilter />
      </SearchRow> */}
      <Table
        columns={columns}
        dataSource={list}
        rowKey={(record) => record.id}
        pagination={{
          current: pagination.currentPage,
          total: pagination.total,
        }}
        onChange={(paging) =>
          getTagList({
            variables: {
              filter: userFilterModel,
              pagination: {
                perPage: paging.pageSize,
                currentPage: paging.current,
              },
            },
          })
        }
      />
      <FormAddTag showAddPanel={showAddPanel} onClickClose={onClickClosePanel} />
    </Container>
  );
}

const FormAddTag = ({ showAddPanel, onClickClose }) => {
  const formRef = React.createRef<FormInstance>();

  const { createPostTag, loading } = useCreatePostTag(() => {
    console.log('hi');
  });

  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    console.log('Received values of form:', values);

    confirm({
      title: '카테고리를 생성합니다',
      onOk() {
        dispatch(actionCreateTagList({ callback: createPostTag, list: values.postTagList }));
      },
    });
  };

  return (
    <Drawer
      title="블랙리스트 추가하기"
      width={700}
      onClose={onClickClose}
      open={showAddPanel}
      placement={'right'}
    >
      <div>
        이름은 한글/영어만 가능
        <br />
        전화번호는 숫자만 추출되어 자동 변환 됩니다. 예) 010-1234-1234 입력시, 01012341234 출력
        <br />
        메모는 선택사항임니다!
        <br />* 추가후 리셋 버튼을 누르면 폼이 초기화 됨
        <br />* 스위치 토글시 병원ID 와 상관없이 모든 병원/이벤트에서 번호 차단 (전체차단)
        <br /> (kickban = true 사용시 병워ID: 28 입력 권장 )
      </div>
      <Form ref={formRef} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
        <Form.List name="postTagList" initialValue={[1]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    rules={[
                      { required: true, message: '' },
                      { pattern: /[a-z|A-Z|ㄱ-ㅎ|가-힣]/g, message: '한글영어만' },
                    ]}
                  >
                    <Input placeholder="이름" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'type']}
                    // normalize={(v) => v.replace(/[^0-9]/g, '')}
                    rules={[
                      // { transform: (value) => 1 + value + 'ㅘ리', message: 'a' },
                      { required: true, message: '' },
                      { pattern: /[0-9]/g, message: '숫자만요!' },
                    ]}
                  >
                    <Input placeholder="순서" />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(name)}
                    />
                  ) : null}
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button
            style={{ marginLeft: '1rem' }}
            htmlType="button"
            onClick={() => {
              formRef.current!.resetFields();
            }}
          >
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

const Container = styled.div`
  min-width: 1200px;
  padding: 1.6rem;
  margin: 0 1.6rem;
  background: #fff;
`;

const SearchRow = styled.div`
  margin-bottom: 1rem;
`;

export const DiscountRate = styled.span`
  font-size: 2.4rem;
  line-height: 0.83;
  color: #dd4124;
  font-weight: 600;
`;

export const Price = styled.span<{ single?: boolean }>`
  font-size: 2.4rem;
  color: #dd4326;
  font-weight: 600;
  margin-left: ${({ single }) => !single && '0.6rem'};
`;

export const Origin = styled.span`
  font-size: 1.8rem;
  color: #b1b3b2;
  line-height: 1.11;
  margin-left: 0.6rem;
  text-decoration: line-through;
`;

export const CustomRow = styled.div`
  margin-bottom: 1rem;
`;

export const DnDWrapper = styled.div`
  padding: 1rem;
`;
