interface IMessage {
  [key: string]: {
    message: string;
    description: string;
  };
}

export const ResponseMessage: IMessage = {
  CreateUserPointHistory: {
    message: '포인트 내역 추가',
    description: '🎉 포인트 내역 추가 완료',
  },

  CreateEvent: {
    message: '이벤트 생성',
    description: '🎉 이벤트 생성 완료',
  },

  UpdateEvent: {
    message: '이벤트 업데이트',
    description: '🎉 이벤트 업데이트 완료',
  },
};

export const ErrorMessage: IMessage = {};
