import { useState, useReducer, useEffect, useCallback } from 'react';
import { notification } from 'antd';
import { print } from 'graphql';
import Axios, { AxiosResponse } from 'axios';

import { ApplyEvent, GetEvents } from 'graphql/event.query';
import { IApplyEventState, IEvents, IResGetEvents } from 'modules/event/event.models';
import { resultKeyNameFromField } from '@apollo/client/utilities';

const ODOCTOR_APP_URL = process.env.REACT_APP_ODOCTOR_APP_URL || '';

type Action =
  | {
      type: 'UPDATE_PROPS';
      payload: {
        key: keyof IApplyEventState;
        value: string | boolean;
      };
    }
  | { type: 'RESET' };

const initialState: IApplyEventState = {
  eventId: '',
  username: '',
  userPhonenum: '',
  userGender: 'female',
  userAgeGroup: 'teenager',
  memo: '',
  answer1: '',
  answer3: '',
  answer2: '',
  age: 0,
  isDisableKakaoForAdmin: true,
};

function getBirthYearFromAge(age) {
  if (age) {
    const today = new Date();
    const thisYear = today.getUTCFullYear();

    const birthYear = thisYear - age;
    return birthYear;
  }

  return false;
}

const reducer = (state: IApplyEventState, action: Action) => {
  switch (action.type) {
    case 'UPDATE_PROPS':
      return { ...state, [action.payload.key]: action.payload.value };
    case 'RESET':
      return { ...state, username: '', userPhonenum: '', memo: '' };
    default:
      return state;
  }
};

export default function useApplyEvent() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [events, setEvents] = useState<IEvents[]>([]);

  useEffect(() => {
    const getEvents = async () => {
      const response: AxiosResponse<IResGetEvents> = await Axios.post(ODOCTOR_APP_URL, {
        query: print(GetEvents),
        variables: {
          pagination: {
            perPage: 1000,
          },
        },
      });

      setEvents(response.data.data.events.data);
    };

    getEvents();
  }, []);

  const applyEvent = useCallback(() => {
    const { age, answer1, answer2, answer3, ...other } = state;
    const run = async () => {
      try {
        const userBirthYear = getBirthYearFromAge(age);
        await Axios.post(ODOCTOR_APP_URL, {
          query: print(ApplyEvent),
          variables: {
            input: {
              ...other,
              ...(userBirthYear && { userBirthYear }),
              etc: {
                answer1,
                answer2,
                answer3,
              },
              marketingConsent: true,
            },
          },
        });

        notification.success({
          message: '이벤트 신청',
          description: '🎉 이벤트 신청 완료',
          placement: 'bottomRight',
        });
      } catch (error) {
        notification.error({
          message: '망함',
          description: '망함',
          placement: 'bottomRight',
          duration: 5,
        });

        if (error instanceof Error) {
          throw new Error(error.message);
        }
      } finally {
        dispatch({ type: 'RESET' });
      }
    };

    run();
  }, [state]);

  const bulkApplyEvent = useCallback((data, eventId, isCreatedAt) => {
    console.log('신청데이터:', data);

    const run = async () => {
      try {
        // const p = data.map((o) => {
        //   return () => {
        //     const { key, date, ...input } = o;
        //     return Axios.post(ODOCTOR_APP_URL, {
        //       query: print(ApplyEvent),
        //       variables: {
        //         input: {
        //           eventId,
        //           ...input,
        //           marketingConsent: true,
        //         },
        //       },
        //     });
        //   };
        // });
        // const a = () => {
        //   return new Promise((resolve, reject) => {
        //     setTimeout(() => resolve(e), e * 1000);
        //   });
        // };

        let final: any = [];

        const worker = (arr) => {
          return arr.reduce((promise, item) => {
            return promise
              .then((result) => {
                console.log(`item ${item.username}`);
                const { key, date, ...input } = item;

                return Axios.post(ODOCTOR_APP_URL, {
                  query: print(ApplyEvent),
                  variables: {
                    input: {
                      eventId,
                      ...input,
                      marketingConsent: true,
                      ...(isCreatedAt && { createdAt: date }),
                    },
                  },
                }).then((result) => {
                  if (result.data.data.createEventReservation?.ok) {
                    console.log(`${item.username} done`);
                    final.push({ name: item.username, phone: item.userPhonenum });
                  }

                  return new Promise((resolve, reject) => {
                    setTimeout(() => {
                      resolve(result);
                    }, 500);
                  });
                });

                // return new Promise((resolve, reject) => {
                //   setTimeout(() => {
                //     return Axios.post(ODOCTOR_APP_URL, {
                //       query: print(ApplyEvent),
                //       variables: {
                //         input: {
                //           eventId,
                //           ...input,
                //           marketingConsent: true,
                //         },
                //       },
                //     }).then((result) => {
                //       if (result.data.data.createEventReservation?.ok) {
                //         final.push({ name: item.username, phone: item.userPhonenum });
                //       }

                //       resolve(result);
                //       return result;
                //     });
                //   }, 500);
                // });
              })
              .catch(console.error);
          }, Promise.resolve());
        };

        worker(data).then(() => {
          console.log(`FINAL RESULT is ${final}`);
          console.log(final);
          alert(`${data.length}개의 중 ${final.length}개 성공`);
        });

        // Promise.all(p).then((res) => {
        //   alert(
        //     `${data.length}개의 중 ${
        //       res.filter((r: any) => r.data.data.createEventReservation?.ok).length
        //     }개 성공`
        //   );
        // });
      } catch (error) {
        notification.error({
          message: '망함',
          description: '망함',
          placement: 'bottomRight',
          duration: 5,
        });

        if (error instanceof Error) {
          throw new Error(error.message);
        }
      } finally {
        // dispatch({ type: 'RESET' });
      }
    };

    run();
  }, []);

  return { events, state, dispatch, applyEvent, bulkApplyEvent };
}
