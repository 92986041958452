import { useMutation, useQuery } from '@apollo/client';

import { GetHospitals, UpdateHospital } from 'graphql/hospital.query';
import { ReqUpdateHospital, ResUpdateHospital } from 'modules/hospital/hospital.models';

type Location = {
  id: number;
};

type Category = {
  id: number;
};

export type IHospital = {
  id: string;
  name: string;
  location: Location;
  category: Category;
  hiraCode: string;
  tel: string;
  phones: string[];
  // point: HospitalPoint;
  isUseCrmService: boolean;
};

interface IRes {
  hospitals: {
    data: IHospital[];
  };
}

export default function useHospitalList() {
  const pagination = { perPage: 9999 };

  const { data } = useQuery<IRes>(GetHospitals, { variables: { pagination } });

  const [updateHospital] = useMutation<ResUpdateHospital, ReqUpdateHospital>(UpdateHospital, {
    refetchQueries: [{ query: GetHospitals, variables: { pagination } }],
  });

  if (!data) {
    return {
      hospitals: [],
      updateHospital,
    };
  }

  return { hospitals: data.hospitals.data, updateHospital };
}
