import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';

import usePoint from 'hooks/usePoint';
import { numberWithCommas } from 'utils/numberWithCommas';
import PointFilter from 'components/Filter/PointFilter';
import useFilterModel from 'hooks/useFilterModel';
import InputPointHistory from 'components/Input/InputPointHistory';
import { resetPointFilter } from 'modules/filter/filter.actions';
import { UserPointHistory } from 'odoc-interface-module/odoc-manager-front/userPointHistory';
import { EventType } from 'odoc-interface-module/common/status';

export default function Point() {
  const dispatch = useDispatch();
  const [showInput, setShowInput] = useState(false);
  const { getUserPointHistory, loading, lists, pagination } = usePoint();
  const { pointFilterModel } = useFilterModel();

  useEffect(() => {
    getUserPointHistory();

    return () => {
      dispatch(resetPointFilter());
    };
  }, [dispatch, getUserPointHistory]);

  const columns: ColumnsType<UserPointHistory.PointHistory> = [
    {
      title: '사용자',
      dataIndex: 'user',
      key: 'user',
      ellipsis: true,
      width: 400,
      render: (user, record) => (
        <>
          <div>{user.name}</div>
          <div>
            <Tag>ID: {record.user.id}</Tag>
          </div>
        </>
      ),
    },
    {
      title: '구분',
      dataIndex: 'eventType',
      key: 'eventType',
      width: 80,
      render: (type) => (
        <Tag color={type === 'reward' ? 'geekblue' : 'volcano'}>{EventType[type]}</Tag>
      ),
    },
    {
      title: '내역',
      dataIndex: 'rewardItem',
      key: 'rewardItem',
      width: 300,
      render: (rewardItem, record) => {
        return <span>{rewardItem ? rewardItem.itemName : record.useItem?.itemBrandName}</span>;
      },
    },
    {
      title: '변동 포인트',
      dataIndex: 'pointAmount',
      key: 'pointAmount',
      render: (pointAmount) => {
        return (
          <span>
            {pointAmount > 0 && '+'}
            {numberWithCommas(pointAmount)}
          </span>
        );
      },
    },
    {
      title: '일자',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        return <span>{dayjs(createdAt).format('YYYY.MM.DD HH:mm')}</span>;
      },
    },
  ];

  const pagingData = {
    current: pagination.currentPage,
    total: pagination.total,
  };

  return (
    <div style={{ minWidth: '1200px', padding: '0 1.6rem' }}>
      <PointFilter getUserPointHistory={getUserPointHistory} setShowInput={setShowInput} />
      <Table<UserPointHistory.PointHistory>
        dataSource={lists}
        columns={columns}
        rowKey={(record) => record.id}
        pagination={pagingData}
        loading={loading}
        onChange={(paging) =>
          getUserPointHistory({
            variables: {
              filter: pointFilterModel,
              pagination: {
                perPage: paging.pageSize,
                currentPage: paging.current,
              },
            },
          })
        }
      />
      <InputPointHistory showInput={showInput} setShowInput={setShowInput} />
    </div>
  );
}
