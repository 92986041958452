import gql from 'graphql-tag';

export const GetFakeUser = gql`
  query ($index: Int!) {
    fakeUser(index: $index) {
      id
      name
      nickname
      avatar
    }
  }
`;

export const CreateFakePost = gql`
  mutation ($input: CreatePostInput!) {
    createPost(input: $input) {
      title
    }
  }
`;

export const CreateFakePostComment = gql`
  mutation ($input: CreateCommentInput!) {
    createPostComment(input: $input) {
      comment
    }
  }
`;

export const CreateFakePostLike = gql`
  mutation ($input: fakePostLike!) {
    fakePostLike(input: $input) {
      ok
    }
  }
`;
