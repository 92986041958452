import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';

import { CreateUserPointHistory, GetUser, UserPointRewardItems } from 'graphql/point.query';
import { IPointItems, IReqUser, IResUser } from 'components/Input/InputPointHistory.models';
import {
  resetPointHistory,
  setPointHistoryUser,
  setPointByKey,
  setPointItems,
  selectedPointItem,
} from 'modules/pointHistory/pointHistory.actions';
import { RootState } from 'modules/rootState';
import usePoint from './usePoint';
import useFilterModel from './useFilterModel';
import { ICreateUserPointHistory } from 'odoc-interface-module/odoc-manager-front/createUserPointHistory';

export default function useInputPoint() {
  const dispatch = useDispatch();
  const { pointHistory } = useSelector((state: RootState) => state);

  const { getUserPointHistory } = usePoint();
  const { pointFilterModel } = useFilterModel();

  const [getUser, { data, loading }] = useLazyQuery<IResUser, IReqUser>(GetUser, {
    fetchPolicy: 'no-cache',
    onCompleted: (user) => dispatch(setPointHistoryUser(user)),
    onError: () => dispatch(setPointByKey({ key: 'isEmpty', value: true })),
  });

  const [getRewardItems] = useLazyQuery<IPointItems>(UserPointRewardItems, {
    onCompleted: (res) => dispatch(setPointItems(res)),
  });

  const [createUserPointHistory] = useMutation<
    ICreateUserPointHistory.Response,
    ICreateUserPointHistory.Request
  >(CreateUserPointHistory, {
    onCompleted: (res) => getUserPointHistory({ variables: { filter: pointFilterModel } }),
  });

  useEffect(() => {
    getRewardItems();
  }, [getRewardItems]);

  const handleUserId = (id: string) => {
    dispatch(setPointByKey({ key: 'id', value: id }));
  };

  const resetState = (type: 'unmount' | 'user') => {
    dispatch(resetPointHistory(type));
  };

  const onChangePointItem = (id: string) => {
    dispatch(selectedPointItem(id));
  };

  return {
    getUser,
    resetState,
    handleUserId,
    onChangePointItem,
    createUserPointHistory,
    data,
    loading,
    pointHistory,
  };
}
