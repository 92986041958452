import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { IUserState } from './user.models';
import { SET_USERS } from './user.actions';

const initialState = {
  list: [],
  pagination: {
    currentPage: 1,
    lastPage: 1,
    perPage: 1,
    total: 1,
  },
};

const userReducer = createReducer<IUserState, RootAction>(initialState, {
  [SET_USERS]: (state, action) => ({
    list: action.payload.list,
    pagination: action.payload.pagination,
  }),
});

export default userReducer;
