import { createReducer } from 'typesafe-actions';

import { RootAction } from 'modules/rootAction';
import { SET_POST_CAT_LIST } from './managePostCat.actions';
import { PostCatListState } from './managePostCat.models';

const initialState = {
  list: [],
  pagination: {
    currentPage: 1,
    lastPage: 1,
    perPage: 1,
    total: 1,
  },
};

const managePostCatReducer = createReducer<PostCatListState, RootAction>(initialState, {
  [SET_POST_CAT_LIST]: (state, action) => ({
    list: action.payload.list,
    pagination: action.payload.pagination,
  }),
});

export default managePostCatReducer;
