import { notification } from 'antd';
import axios, { AxiosInstance } from 'axios';

import { history } from 'stores';
import AuthHelper from 'utils/AuthHelper';

class Http {
  private baseURL: string;
  instance: AxiosInstance;

  constructor() {
    this.baseURL = process.env.REACT_APP_GATEWAY || '';
    this.instance = axios.create({ baseURL: this.baseURL, timeout: 10000 });

    this.initialize();
  }

  private initialize() {
    this.instance.interceptors.request.use((config) => {
      const { id_token } = AuthHelper.getUser();
      config.headers['X-GOOGLE-OAUTH-TICKET'] = id_token;
      return config;
    });

    this.instance.interceptors.response.use(undefined, (error) => {
      const { status } = error.response;
      if (status === 401) {
        notification.error({
          message: '👀',
          description: '미네랄이 부족합니다.',
          placement: 'bottomRight',
          duration: 5,
        });

        history.push('/login');
      }
    });
  }

  updateToken(id_token: string) {
    this.instance.defaults.headers.common['X-GOOGLE-OAUTH-TICKET'] = id_token;
  }
}

export default new Http();
