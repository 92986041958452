import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import { GetUserPointHistory } from 'graphql/point.query';
import { setPointHistory } from 'modules/pointHistory/pointHistory.actions';
import { RootState } from 'modules/rootState';
import { UserPointHistory } from 'odoc-interface-module/odoc-manager-front/userPointHistory';

export default function usePoint() {
  const dispatch = useDispatch();
  const { lists, pagination } = useSelector(
    (state: RootState) => state.pointHistory.responsePointHistory
  );

  const [getUserPointHistory, { loading }] = useLazyQuery<
    UserPointHistory.Response,
    UserPointHistory.Request
  >(GetUserPointHistory, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => dispatch(setPointHistory(res.userPointHistory)),
  });

  return { getUserPointHistory, loading, lists, pagination };
}
