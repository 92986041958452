import React from 'react';
import { PageHeader, Select, Input, Button, Radio, Checkbox, Switch } from 'antd';

import LabelRow from 'components/CreateEvent/LabelRow';
import useApplyEvent from 'hooks/useApplyEvent';
import useEventList from 'hooks/useEventList';
import MultiApply from './MultiApply';
const { Option } = Select;

export default function Apply() {
  const { events, state, dispatch, applyEvent } = useApplyEvent();
  const { events: eventList } = useEventList();

  const onSubmit = () => {
    applyEvent();
  };

  return (
    <>
      <div
        style={{ minWidth: '1200px', padding: '1.6rem', margin: '0 1.6rem', background: '#fff' }}
      >
        <PageHeader title="이벤트 신청" subTitle="이벤트를 신청합니다." />
        <LabelRow label="이벤트 아이디" isRequired>
          <Select<string>
            showSearch
            optionFilterProp="children"
            filterOption
            placeholder="이벤트명으로 검색 가능합니다"
            onChange={(value) =>
              dispatch({ type: 'UPDATE_PROPS', payload: { key: 'eventId', value } })
            }
            style={{ width: '40rem' }}
          >
            {eventList.map(({ id, name }) => (
              <Option value={id} key={id}>
                {`(${id}) - ${name.replaceAll('$n', '')}`}
              </Option>
            ))}
          </Select>
        </LabelRow>
        <LabelRow label="이름" isRequired>
          <Input
            placeholder="유저 이름"
            value={state.username}
            onChange={({ target: { value } }) =>
              dispatch({ type: 'UPDATE_PROPS', payload: { key: 'username', value } })
            }
            style={{ width: '40rem' }}
          />
        </LabelRow>
        <LabelRow label="연락처" isRequired>
          <Input
            placeholder="공백없이 숫자만 입력하세요"
            value={state.userPhonenum}
            onChange={({ target: { value } }) =>
              dispatch({ type: 'UPDATE_PROPS', payload: { key: 'userPhonenum', value } })
            }
            style={{ width: '40rem' }}
          />
        </LabelRow>
        <LabelRow label="성별" isRequired>
          <Radio.Group
            onChange={(e) =>
              dispatch({
                type: 'UPDATE_PROPS',
                payload: { key: 'userGender', value: e.target.value },
              })
            }
            value={state.userGender}
          >
            <Radio value="male">남자</Radio>
            <Radio value="female">여자</Radio>
          </Radio.Group>
        </LabelRow>
        <LabelRow label="연령" isRequired>
          <Select<string>
            optionFilterProp="children"
            placeholder="연령을 선택"
            onChange={(value) =>
              dispatch({ type: 'UPDATE_PROPS', payload: { key: 'userAgeGroup', value } })
            }
            style={{ width: '40rem' }}
          >
            <Option value={'teenager'}>10대</Option>
            <Option value={'twenties'}>20대</Option>
            <Option value={'thirties'}>30대</Option>
            <Option value={'forties'}>40대</Option>
            <Option value={'fifties'}>50대</Option>
            <Option value={'overSixties'}>60대이상</Option>
          </Select>
        </LabelRow>
        <LabelRow label="메모">
          <Input
            placeholder="(예: 키:150cm/몸무게:90kg)"
            value={state.memo}
            onChange={({ target: { value } }) =>
              dispatch({ type: 'UPDATE_PROPS', payload: { key: 'memo', value } })
            }
            style={{ width: '40rem' }}
          />
        </LabelRow>
        <LabelRow label="나이">
          <Input
            placeholder="(예: 키:150cm/몸무게:90kg)"
            value={state.age}
            onChange={({ target: { value } }) =>
              dispatch({ type: 'UPDATE_PROPS', payload: { key: 'age', value } })
            }
            style={{ width: '40rem' }}
          />
        </LabelRow>
        <LabelRow label="답변1">
          <Input
            placeholder="(예: 키:150cm/몸무게:90kg)"
            value={state.answer1}
            onChange={({ target: { value } }) =>
              dispatch({ type: 'UPDATE_PROPS', payload: { key: 'answer1', value } })
            }
            style={{ width: '40rem' }}
          />
        </LabelRow>
        <LabelRow label="답변2">
          <Input
            placeholder="(예: 키:150cm/몸무게:90kg)"
            value={state.answer2}
            onChange={({ target: { value } }) =>
              dispatch({ type: 'UPDATE_PROPS', payload: { key: 'answer2', value } })
            }
            style={{ width: '40rem' }}
          />
        </LabelRow>
        <LabelRow label="답변3">
          <Input
            placeholder="(예: 키:150cm/몸무게:90kg)"
            value={state.answer3}
            onChange={({ target: { value } }) =>
              dispatch({ type: 'UPDATE_PROPS', payload: { key: 'answer3', value } })
            }
            style={{ width: '40rem' }}
          />
        </LabelRow>

        <LabelRow label="카카오알림톡 사용">
          <Switch
            checkedChildren="사용"
            unCheckedChildren="미사용"
            defaultChecked
            checked={!state.isDisableKakaoForAdmin}
            onChange={(checked) =>
              dispatch({
                type: 'UPDATE_PROPS',
                payload: { key: 'isDisableKakaoForAdmin', value: !checked },
              })
            }
          />
        </LabelRow>
        <p>마케팅 동의는 자동으로 됩니다</p>
        <Button type="primary" onClick={onSubmit} style={{ width: '40rem' }}>
          확인
        </Button>
      </div>
      <div
        style={{ minWidth: '1200px', padding: '1.6rem', margin: '0 1.6rem', background: '#fff' }}
      >
        <PageHeader title="이벤트 대량 신청" subTitle="엑셀 파일로 이벤트를 신청합니다." />
        <MultiApply />
      </div>
    </>
  );
}
