import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  PageHeader,
  Table,
  Tag,
  Button,
  Drawer,
  Input,
  Switch,
  InputNumber,
  Alert,
  Popover,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import useEventList, { IEvent } from 'hooks/useEventList';
import LabelRow from 'components/CreateEvent/LabelRow';
import InputFileMultiple from 'components/Input/InputFileMultiple';
import InputFileSingle from 'components/Input/InputFileSingle';
import { IEventPoster } from 'modules/event/event.models';
import { actionRequestUpdateEvent } from 'modules/event/event.actions';
import { select } from 'redux-saga/effects';

const { Search, TextArea } = Input;

export default function List() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [eventPosters, setEventPosters] = useState<IEventPoster[]>([]);
  const [eventThumbnail, setEventThumbnail] = useState<any>();
  const [event, setEvent] = useState<IEvent>({
    id: '',
    name: '',
    therapyName: '',
    visible: false,
    priceDescription: '',
    originalPrice: 0,
    discountRate: 0,
    discountedPrice: 0,
    chargePrice: 0,
    contentImgUrls: [],
    thumbnailUrl: '',
    hospitalBranchName: '',
    hospital: {
      name: '',
    },
    useBlock: false,
    isDelete: false,
    buttonType: 1,
    description: '',
    description2: '',
    isEnableKakao: true,
    crmViewMode: 1,
    isEnableCommunity: false,
    isEnableKakaoHuser: false,
    eventTags: '',
  });
  const { events, updateEvent } = useEventList();

  const createMarkup = (value: string) => {
    if (search === '') {
      return { __html: value };
    }

    return { __html: value.replaceAll(search, (match) => `<mark>${match}</mark>`) };
  };

  const columns = [
    {
      title: '이벤트 아이디',
      dataIndex: 'id',
      key: 'id',
      width: '12rem',
      render: (id) => <div dangerouslySetInnerHTML={createMarkup(id)} />,
    },
    {
      title: '이벤트명',
      dataIndex: 'name',
      key: 'name',
      render: (name) => <div dangerouslySetInnerHTML={createMarkup(name.replaceAll('$n', ''))} />,
    },
    {
      title: '병원명',
      dataIndex: 'hospital',
      key: 'hospitalName',
      render: (_, data) => <div dangerouslySetInnerHTML={createMarkup(data.hospital.name)} />,
    },
    {
      title: '페이지 노출',
      dataIndex: 'visible',
      key: 'visible',
      render: (visible) => (
        <div>{visible ? <Tag color="green">노출</Tag> : <Tag color="red">비노출</Tag>}</div>
      ),
    },
    {
      title: '페이지 상태',
      dataIndex: 'isDelete',
      key: 'isDelete',
      render: (isDelete) => (
        <div>{isDelete ? <Tag color="red">삭제됨</Tag> : <Tag color="green">-</Tag>}</div>
      ),
    },
    {
      title: '알림톡 발송',
      dataIndex: 'isEnableKakao',
      key: 'isEnableKakao',
      render: (isEnableKakao) => (
        <div>{isEnableKakao ? <Tag color="green">발송</Tag> : <Tag color="red">미발송</Tag>}</div>
      ),
    },
    {
      title: '',
      key: 'detail',
      width: '10rem',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            const [selectEvent] = events.filter((event) => event.id === record.id);

            setEvent({
              ...selectEvent,
              eventTags: selectEvent.eventTags.map(({ name }) => name).join(','),
            });

            console.info(selectEvent);

            setEventPosters(
              selectEvent.contentImgUrls.map((url) => ({
                file: null,
                preview: url,
              }))
            );
            setEventThumbnail({
              file: null,
              preview: selectEvent.thumbnailUrl,
            });
            setShowDetail(true);
          }}
        >
          상세
        </Button>
      ),
    },
  ];

  const onChangeEvent = (key: keyof typeof event) => (value) => {
    if (typeof value === 'number' || typeof value === 'boolean') {
      setEvent({ ...event, [key]: value });
    } else {
      setEvent({ ...event, [key]: value.target.value });
      // if (key === 'priceDescription' || key === 'hospitalBranchName') {
      //   setEvent({ ...event, [key]: value.target.value });
      // }
    }
  };

  const onClickSubmit = () => {
    dispatch(
      actionRequestUpdateEvent({
        callback: updateEvent,
        eventPosters,
        eventThumbnail,
        event,
        setShowDetail,
      })
    );
  };

  const filterEvents = events.filter(
    (event) =>
      event.name.includes(search) ||
      event.id.includes(search) ||
      event.hospital.name.includes(search)
  );

  const handleDisplay = () => {
    const { originalPrice, discountRate, discountedPrice } = event;
    const prices = [discountRate, discountedPrice, originalPrice];
    // 모든정보
    const isCorrectPrice = prices.every((price) => !!price);
    // 할인율만 존재
    const isOnlyDiscountRate = !!discountRate && !discountedPrice;
    // 할인가만 존재
    const isOnlyDiscountedPrice = !!discountedPrice && !discountRate;

    if (isCorrectPrice) {
      return (
        <div>
          <DiscountRate>{discountRate}%</DiscountRate>
          <Price>{String(discountedPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</Price>
          <Origin>{String(originalPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</Origin>
        </div>
      );
    } else if (isOnlyDiscountRate) {
      return (
        <div>
          <DiscountRate>{discountRate}% 특별할인</DiscountRate>
        </div>
      );
    } else if (isOnlyDiscountedPrice) {
      return (
        <div>
          <Price single>
            특가 {String(discountedPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
          </Price>
        </div>
      );
    } else {
      return '아무것도 안보여줌';
    }
  };

  return (
    <Container>
      <PageHeader title="이벤트 목록" subTitle="이벤트 목록" />
      <SearchRow>
        <Search
          placeholder="이벤트명, 이벤트아이디, 병원명 검색"
          allowClear
          size="large"
          onChange={(e) => setSearch(e.target.value)}
        />
      </SearchRow>
      <Table columns={columns} dataSource={filterEvents} rowKey={(record) => record.id} />
      <Drawer
        title="이벤트 상세"
        placement="right"
        width="70rem"
        onClose={() => setShowDetail(false)}
        visible={showDetail}
      >
        <Alert
          message={
            <div>
              <p style={{ margin: 0 }}>다른거 고치고 싶으시면 @dev로 문의해주세여</p>
              <p style={{ margin: 0 }}>할인가,할인률,원가 표시안할거면 숫자0 입력</p>
            </div>
          }
          type="warning"
          style={{ marginBottom: '2rem' }}
        />
        <LabelRow label="이벤트 아이디">
          <Input disabled value={event.id} style={{ width: '100%' }} />
        </LabelRow>
        <LabelRow
          label="이벤트명"
          icon={
            <Popover
              content={
                <div>
                  <div
                    style={{
                      display: 'grid',
                      gridGap: '0.5rem',
                      gridTemplateColumns: '1fr 1fr',
                      fontSize: '1.2rem',
                    }}
                  >
                    <div>
                      입력 예)
                      <div style={{ fontSize: '1.2rem', border: '1px solid #eee' }}>
                        <p>
                          청포도 맛<br />
                          빅토리아
                        </p>
                      </div>
                    </div>
                    <div>
                      출력 예)
                      <div style={{ fontSize: '1.2rem', border: '1px solid #eee' }}>
                        <p>
                          빅토리아
                          <br />
                          청포도 맛
                        </p>
                      </div>
                    </div>
                    <p>* 띄어쓰기: Enter</p>
                  </div>
                </div>
              }
              title="윗줄과 아랫줄의 순서가 바껴서 출력 됩니다!!"
            >
              <QuestionCircleOutlined style={{ marginLeft: '0.3rem' }} />
            </Popover>
          }
        >
          <TextArea value={event.name} rows={2} onChange={onChangeEvent('name')} />
          {/* <Input value={event.name.replaceAll('$n', '')} style={{ width: '100%' }} /> */}
        </LabelRow>

        <LabelRow label="이벤트설명">
          <TextArea value={event.description} rows={2} onChange={onChangeEvent('description')} />
          {/* <Input value={event.name.replaceAll('$n', '')} style={{ width: '100%' }} /> */}
        </LabelRow>

        <LabelRow
          label="이벤트설명2"
          icon={
            <Popover title="픽 상세 시안 위쪽에 노출되는 단락(없어도 무방)">
              <QuestionCircleOutlined style={{ marginLeft: '0.3rem' }} />
            </Popover>
          }
        >
          <TextArea value={event.description2} rows={5} onChange={onChangeEvent('description2')} />
          {/* <Input value={event.name.replaceAll('$n', '')} style={{ width: '100%' }} /> */}
        </LabelRow>

        <LabelRow label="치료이름">
          <Input
            value={event.therapyName}
            style={{ width: '100%' }}
            onChange={onChangeEvent('therapyName')}
          />
        </LabelRow>
        <LabelRow label="병원명">
          <Input disabled value={event.hospital.name} style={{ width: '100%' }} />
        </LabelRow>
        <LabelRow label="지점명">
          <Input
            value={event.hospitalBranchName}
            onChange={onChangeEvent('hospitalBranchName')}
            style={{ width: '100%' }}
            placeholder="오닥터 역삼점"
          />
        </LabelRow>
        <LabelRow label="노출/비노출">
          <Switch
            checked={event.visible}
            checkedChildren="노출"
            unCheckedChildren="비노출"
            onChange={onChangeEvent('visible')}
          />
        </LabelRow>
        <LabelRow label="커뮤니티 노출/비노출">
          <Switch
            checked={event.isEnableCommunity}
            checkedChildren="노출"
            unCheckedChildren="비노출"
            onChange={onChangeEvent('isEnableCommunity')}
          />
        </LabelRow>
        <LabelRow label="태그">
          <Input
            value={event.eventTags}
            onChange={onChangeEvent('eventTags')}
            style={{ width: '100%' }}
            placeholder="태그1,태그2"
          />
        </LabelRow>
        <LabelRow label="카카오 발송(유저)">
          <Switch
            checked={event.isEnableKakao}
            checkedChildren="발송"
            unCheckedChildren="미발송"
            onChange={onChangeEvent('isEnableKakao')}
          />
        </LabelRow>
        <LabelRow label="카카오 발송(파트너))">
          <Switch
            checked={event.isEnableKakaoHuser}
            checkedChildren="발송"
            unCheckedChildren="미발송"
            onChange={onChangeEvent('isEnableKakaoHuser')}
          />
        </LabelRow>
        <LabelRow
          label="블랙리스트"
          icon={
            <Popover title="블랙리스트에 등록된 전화번호 이벤트 신청 차단/과금X">
              <QuestionCircleOutlined style={{ marginLeft: '0.3rem' }} />
            </Popover>
          }
        >
          <Switch
            checkedChildren="차단"
            unCheckedChildren="허용"
            defaultChecked
            checked={event.useBlock}
            onChange={onChangeEvent('useBlock')}
          />
        </LabelRow>
        <LabelRow
          label="CRM 에만 표시 여부1"
          icon={
            <Popover title="사용시 파트너페이지에선 신청자 목록이 표시 되지 않고, crm에만 표시됨">
              <QuestionCircleOutlined style={{ marginLeft: '0.3rem' }} />
            </Popover>
          }
        >
          <Switch
            checkedChildren="사용"
            unCheckedChildren="미사용"
            defaultChecked
            checked={event.crmViewMode === 2}
            onChange={(checked) => onChangeEvent('crmViewMode')(checked ? 2 : 1)} //checked ? 2 : 1
            // onChange={() => (key: keyof typeof event) => (value) => {
            //   if (typeof value === 'number' || typeof value === 'boolean') {
            //     setEvent({ ...event, [key]: value });
            //   } else {
            //     setEvent({ ...event, [key]: value.target.value });
            //     // if (key === 'priceDescription' || key === 'hospitalBranchName') {
            //     //   setEvent({ ...event, [key]: value.target.value });
            //     // }
            //   }
            // }}
          />
        </LabelRow>
        <LabelRow label="삭제여부">
          <Switch
            checked={event.isDelete}
            checkedChildren="삭제"
            unCheckedChildren="-"
            onChange={onChangeEvent('isDelete')}
          />
        </LabelRow>
        <LabelRow label="할인가">
          <InputNumber
            value={event.discountedPrice}
            min={0}
            formatter={(value) => `${value} 원`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            onChange={onChangeEvent('discountedPrice')}
            style={{ width: '100%' }}
          />
        </LabelRow>
        <LabelRow label="할인률">
          <InputNumber
            value={event.discountRate}
            min={0}
            max={100}
            formatter={(value) => `${value} %`}
            onChange={onChangeEvent('discountRate')}
            style={{ width: '100%' }}
          />
        </LabelRow>
        <LabelRow label="원가">
          <InputNumber
            value={event.originalPrice}
            min={0}
            formatter={(value) => `${value} 원`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            onChange={onChangeEvent('originalPrice')}
            style={{ width: '100%' }}
          />
        </LabelRow>
        <LabelRow label="DB단가">
          <InputNumber
            value={event.chargePrice}
            min={0}
            formatter={(value) => `${value} 원`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            onChange={onChangeEvent('chargePrice')}
            style={{ width: '100%' }}
          />
        </LabelRow>
        <LabelRow label={`할인률/할인가/원가\n이렇게 표시됩니다`}>
          <div>{handleDisplay()}</div>
        </LabelRow>
        <LabelRow label="가격 상세설명">
          <Input
            value={event.priceDescription}
            onChange={onChangeEvent('priceDescription')}
            style={{ width: '100%' }}
          />
        </LabelRow>
        <LabelRow
          label="버튼 타입"
          icon={
            <Popover
              content={
                <div
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  기본값 ([이벤트 신청])
                </div>
              }
              title="이벤트 신청 버튼을 선택함 (복수)"
            >
              <QuestionCircleOutlined style={{ marginLeft: '0.3rem' }} />
            </Popover>
          }
        >
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button
              onClick={() => setEvent({ ...event, buttonType: event.buttonType ^ 2 || 1 })}
              type={event.buttonType & 2 ? 'primary' : 'dashed'}
            >
              카카오톡 상담하기
            </Button>
            <Button
              onClick={() => setEvent({ ...event, buttonType: event.buttonType ^ 1 || 1 })}
              type={event.buttonType & 1 ? 'primary' : 'dashed'}
            >
              이벤트 신청
            </Button>
          </div>
        </LabelRow>
        <CustomRow>
          <label>이벤트 썸네일 이미지 변경</label>
          <DnDWrapper>
            <InputFileSingle
              setImage={eventThumbnail?.preview}
              onChange={(file, preview) =>
                setEventThumbnail({
                  preview: preview,
                  file: file,
                })
              }
            />
          </DnDWrapper>
        </CustomRow>
        <CustomRow>
          <label>이벤트 상세 이미지 변경</label>
          <DnDWrapper>
            <InputFileMultiple eventPosters={eventPosters} setEventPosters={setEventPosters} />
          </DnDWrapper>
        </CustomRow>
        <Button onClick={onClickSubmit} style={{ width: '100%' }} type="primary">
          확인
        </Button>
      </Drawer>
    </Container>
  );
}

const Container = styled.div`
  min-width: 1200px;
  padding: 1.6rem;
  margin: 0 1.6rem;
  background: #fff;
`;

const SearchRow = styled.div`
  margin-bottom: 1rem;
`;

export const DiscountRate = styled.span`
  font-size: 2.4rem;
  line-height: 0.83;
  color: #dd4124;
  font-weight: 600;
`;

export const Price = styled.span<{ single?: boolean }>`
  font-size: 2.4rem;
  color: #dd4326;
  font-weight: 600;
  margin-left: ${({ single }) => !single && '0.6rem'};
`;

export const Origin = styled.span`
  font-size: 1.8rem;
  color: #b1b3b2;
  line-height: 1.11;
  margin-left: 0.6rem;
  text-decoration: line-through;
`;

export const CustomRow = styled.div`
  margin-bottom: 1rem;
`;

export const DnDWrapper = styled.div`
  padding: 1rem;
`;
