import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';

import { GetCats, CreateCat, UpdateCat } from 'graphql/post.manage.query';
import { RootState } from 'modules/rootState';
import { actionSetPostCatList } from 'modules/managePostCategory/managePostCat.actions';

import { notification } from 'antd';

export default function usePostCatManage() {
  const dispatch = useDispatch();
  const { list, pagination } = useSelector((state: RootState) => state.postCatList);

  const [getPostCatList] = useLazyQuery<any>(GetCats, {
    onCompleted: (res) => {
      console.log('res', res);
      console.log('res', res);
      console.log('res', res);
      console.log('res', res);
      console.log('res', res);
      console.log('res', res);

      return dispatch(
        actionSetPostCatList({
          list: res.postCategory.data,
          pagination: res.postCategory.pagination,
        })
      );
    },
  });

  // const [updateEvent] = useMutation<IResUpdateEvent, IReqUpdateEvent>(UpdateEvent, {
  //   refetchQueries: [{ query: GetEventsAll, variables: { pagination } }],
  // });

  return { getPostCatList, list, pagination };
}

export function useCreatePostCat(onCompleted: () => void) {
  const [createPostCat, { loading }] = useMutation<any, any>(CreateCat, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      // dispatch(resetEvent());
      onCompleted();
    },
    onError: (error) => {
      // dispatch(resetEvent());
      // resetAfterCreateEvent();

      notification.error({
        message: '망함',
        description: error.message,
        placement: 'bottomRight',
        duration: 5,
      });
    },
  });

  return { createPostCat, loading };
}

export function useUpdatePostCat(onCompleted: () => void) {
  const [updatePostCat, { loading }] = useMutation<any, any>(UpdateCat, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      // dispatch(resetEvent());
      onCompleted();
    },
    onError: (error) => {
      // dispatch(resetEvent());
      // resetAfterCreateEvent();

      notification.error({
        message: '망함',
        description: error.message,
        placement: 'bottomRight',
        duration: 5,
      });
    },
  });

  return { updatePostCat, loading };
}
