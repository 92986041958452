import gql from 'graphql-tag';

// https://api-dev-manager.odoctor.co.kr/graphql
export const GetBlackList = gql`
  query GetBlackList($pagination: PaginationInput) {
    eventBlackList(pagination: $pagination) {
      data {
        id
        phone
        name
        hospitalId
        memo
        kickban
        isDelete
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const CreateBlacklist = gql`
  mutation CreateEventBlacklist($input: [EventBlackListCreateInput]!) {
    createEventBlacklist(input: $input) {
      ok
    }
  }
`;

// export const UpdateEvent = gql`
//   mutation UpdateEvent($input: EventUpdateInput) {
//     updateEvent(input: $input) {
//       ok
//       error {
//         path
//         message
//       }
//     }
//   }
// `;

// export const GetEventCategories = gql`
//   query GetEventCategories {
//     eventCategories {
//       id
//       name
//     }
//   }
// `;
