import gql from 'graphql-tag';

export const GetChargeHistory = gql`
  query GetChargeHistory(
    $filter: HospitalPointTransactionListFilter
    $pagination: PaginationInput
  ) {
    hospitalPointTransactions(filter: $filter, pagination: $pagination) {
      data {
        id
        hospitalPoint {
          id
          balance
        }
        hospital {
          id
          name
        }
        reservation {
          id
          username
          userPhonenum
        }
        action
        description
        transactionType {
          id
          name
        }
        amount
        balance
        timestamp
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const GetHospital = gql`
  query GetHospital($id: ID!) {
    hospital(id: $id) {
      id
      name
      hiraCode
      point {
        balance
      }
    }
  }
`;

export const GetTransactionTypes = gql`
  query GetTransactionTypes {
    hospitalPointTransactionTypes {
      id
      action
      name
      description
    }
  }
`;

export const CreateHospitalPointTransaction = gql`
  mutation CreateHospitalPointTransaction($input: HospitalPointTransactionCreationInput) {
    createHospitalPointTransaction(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;
