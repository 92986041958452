import React, { useState } from 'react';
import { Button, Input, InputNumber, PageHeader } from 'antd';
import styled from 'styled-components';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import LabelRow from 'components/CreateEvent/LabelRow';
import { CreateEventReview, GetReviewCategory } from 'graphql/review.query';

const { TextArea } = Input;

type Ratings = {
  id: string;
  name: string;
  ratingEqualDivision: number;
};

const initReview = { eventId: 0, detail: '', nickname: '', createdAt: new Date() };

export default function ReviewPage() {
  const [urls, setUrls] = useState(['']);
  const [ratings, setRatings] = useState<Ratings[]>([]);
  const [review, setReview] = useState(initReview);
  const [objRating, setObjRating] = useState({});

  useQuery(GetReviewCategory, {
    onCompleted: (res) => setRatings(res.reviewEstimationCategories),
  });

  const [createEventReview] = useMutation(CreateEventReview, {
    onCompleted: () => {
      setReview(initReview);
      setObjRating({});
      alert('성공');
      window.location.reload();
    },
  });

  const onClickSubmit = () => {
    const estimations = Object.keys(objRating).reduce<any[]>((acc, key) => {
      if (objRating[key] !== null) {
        acc.push({ categoryId: Number(key), rating: objRating[key] });
      }
      return acc;
    }, []);

    createEventReview({
      variables: {
        input: {
          ...review,
          imageUrls: [], // TODO:
          estimations,
        },
      },
    });
  };

  return (
    <div style={{ minWidth: '1200px', padding: '1.6rem', margin: '0 1.6rem', background: '#fff' }}>
      <PageHeader title="리뷰 생성" subTitle="alpha version" />
      <LabelRow label="이벤트 아이디 🆔" isRequired>
        <InputNumber
          min={1}
          placeholder="이벤트 아이디"
          onChange={(eventId) => setReview({ ...review, eventId: eventId || 0 })}
        />
      </LabelRow>
      <LabelRow label="리뷰 내용" isRequired>
        <TextArea
          placeholder="리뷰 내용"
          onChange={(e) => setReview({ ...review, detail: e.target.value })}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow label="리뷰 등록 날짜" isRequired>
        <Input
          placeholder="리뷰 등록 날짜 (예:2021-10-12)"
          onChange={(e) => setReview({ ...review, createdAt: new Date(e.target.value) })}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <LabelRow label="닉네임" isRequired>
        <Input
          placeholder="닉네임"
          onChange={(e) => setReview({ ...review, nickname: e.target.value })}
          style={{ width: '40rem' }}
        />
      </LabelRow>
      <div>
        <LabelRow label="이미지 주소 (TODO:)">
          <UrlWrap>
            {urls.map((url, idx) => (
              <Input key={idx} placeholder="이미지 주소" style={{ width: '40rem' }} />
            ))}
          </UrlWrap>
        </LabelRow>
        <Button onClick={() => setUrls([...urls, 'hi'])}>이미지 주소 추가</Button>
      </div>
      <div>
        <LabelRow label="리뷰점수" isRequired>
          {ratings.map((reviewCategory, idx) => (
            <div key={idx}>
              <span>{reviewCategory.name}</span>
              <span> 최대 {reviewCategory.ratingEqualDivision}</span>
              <InputNumber
                min={1}
                max={reviewCategory.ratingEqualDivision}
                onChange={(value) =>
                  setObjRating((state) => ({ ...state, [reviewCategory.id]: value }))
                }
              />
            </div>
          ))}
        </LabelRow>
      </div>
      <Button onClick={onClickSubmit}>생성</Button>
    </div>
  );
}

const UrlWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
